import React from 'react'

const What_will_you_be = () => {
  return (
    <div>
      <div className="py-5 container mx-auto">
    <p className='fs-1 fw-bold mb-4'>What will you be up to?</p>
<div className="row m-0 bg-white shadow p-4" style={{borderRadius:'10px'}}>
<div className="col-md-6 col-lg-4 ps-md-0 mb-4 mb-md-0">
    <div className='d-flex align-items-center flex-column about-us2'>
        <img src={require('../images/page1/Group 99.png')} alt="" />
        <p className='text-center fw-medium fs-5 mt-2 m-0'>Choose Your Focus</p>
        <p className='text-center mt-2'>Pick the subjects that matter to you, and we’ll build a personalized plan that helps you reach your learning goals.</p>
    </div>
</div>

<div className="col-md-6 col-lg-4 mb-4 mb-md-0">
    <div className='d-flex align-items-center flex-column about-us2'>
        <img src={require('../images/page1/Group 96.png')} alt="" />
        <p className='text-center fw-medium fs-5 mt-2 m-0'>Expert Mentorship for Expertise</p>
        <p className='text-center mt-2'>Pair up with top tutors who simplify complex topics and help you become an expert in your field.
        </p>
    </div>
</div>

<div className="col-md-6 col-lg-4 pe-md-0 mt-4 mt-lg-0">
    <div className='d-flex align-items-center flex-column about-us2'>
        <img src={require('../images/page1/Group 98.png')} alt="" />
        <p className='text-center fw-medium fs-5 m-0 mt-2'>Hands-On Learning</p>
        <p className='text-center mt-2'>Dive into interactive sessions that make learning engaging and effective, with instant feedback.</p>
    </div>
</div>

<div className="col-md-6 ps-md-0 mt-4">
    <div className='d-flex align-items-center flex-column about-us2'>
        <img src={require('../images/page1/Group 97.png')} alt="" />
        <p className='text-center fw-medium fs-5 mt-2 m-0'>Track Your Progress</p>
        <p className='text-center mt-2'>Regular assessments keep you informed, showing how far you’ve come and where to focus next.</p>
    </div>
</div>

<div className="col-md-6 pe-md-0 mt-4">
    <div className='d-flex align-items-center flex-column about-us2'>
        <img src={require('../images/page1/Group 95.png')} alt="" />
        <p className='text-center fw-medium fs-5 m-0 mt-2'>Achieve and Grow</p>
        <p className='text-center mt-2'>Set goals, smash them, and continuously build on your achievements with guidance at every step.</p>
    </div>
</div>
</div>
</div>
    </div>
  )
}

export default What_will_you_be
