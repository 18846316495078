import React from 'react'

const Subject_svg5 = () => {
  return (
    <div>
      <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_371_1797)">
<g clip-path="url(#clip1_371_1797)">
<path d="M54.4786 43.4406V38.689H49.4102V35.5213H47.7208V32.8815H49.4102V28.1299H36.777V29.7138H47.8264V31.2976H15.0553V32.8815H46.1368V35.5213H11.6613C10.9334 35.5213 10.3414 34.9292 10.3414 34.2014C10.3414 33.4735 10.9334 32.8815 11.6613 32.8815H13.4714V31.2976H11.6613C10.0602 31.2976 8.75749 32.6002 8.75749 34.2014C8.75749 35.8026 10.0602 37.1051 11.6613 37.1051H47.8263V38.689H20.5309V40.2729H52.8946V41.8568H8.17674C5.70221 41.8568 3.68912 43.8699 3.68912 46.3444C3.68912 48.8189 5.70221 50.832 8.17674 50.832H45.4805V49.2481H8.17685C6.57566 49.2481 5.27309 47.9456 5.27309 46.3444C5.27309 44.7432 6.57566 43.4406 8.17685 43.4406H51.2053V46.0804H16.0962V47.6643H51.2053V49.2481H47.0645V50.832H52.8947V52.4159H8.17685C4.82897 52.4159 2.10535 49.6923 2.10535 46.3444C2.10535 42.9965 4.82897 40.2729 8.17685 40.2729H18.9472V38.689H11.6614C9.18683 38.689 7.17373 36.6759 7.17373 34.2014C7.17373 31.7269 9.18683 29.7138 11.6614 29.7138H35.1931V28.1299H11.6614C8.31348 28.1299 5.58986 30.8535 5.58986 34.2014C5.58986 35.9903 6.36797 37.6006 7.60307 38.7128C3.6491 39.0075 0.521484 42.3164 0.521484 46.3444C0.521484 50.5656 3.95563 53.9998 8.17685 53.9998H54.4786V49.2481H52.7891V43.4406H54.4786Z" fill="currentColor"/>
<path d="M12.9287 46.0811H14.5126V47.6649H12.9287V46.0811Z" fill="currentColor"/>
<path d="M15.093 23.5383L15.4457 23.7734C15.5832 23.8651 18.9116 26.0183 27.5 26.0183C36.0884 26.0183 39.4168 23.8651 39.5543 23.7734L39.907 23.5383V10.2049L44.6586 8.621V16.0978C43.4413 16.4435 42.5467 17.5644 42.5467 18.8909V22.8506H48.3543V18.8909C48.3543 17.5644 47.4597 16.4435 46.2424 16.0978V8.09304L49.0107 7.17028L40.6937 4.39788L40.1928 5.90044L44.0021 7.17017L27.5 12.6709L10.9979 7.17028L27.5 1.66961L38.6902 5.39972L39.1912 3.89716L27.5 0L5.98926 7.17028L15.093 10.2049V23.5383ZM46.7704 18.8909V21.2667H44.1306V18.8909C44.1306 18.1631 44.7227 17.571 45.4505 17.571C46.1783 17.571 46.7704 18.1631 46.7704 18.8909ZM16.6769 10.7328L27.5 14.3406L38.3231 10.7328V16.9375H36.3697V18.5213H38.3231V20.1052H34.8914V21.6891H38.3231V22.6438C37.3025 23.1408 34.0187 24.4344 27.5 24.4344C20.9764 24.4344 17.6925 23.1388 16.6769 22.6443V10.7328Z" fill="currentColor"/>
<path d="M9.76074 46.0811H11.3446V47.6649H9.76074V46.0811Z" fill="currentColor"/>
<path d="M31.7236 20.1055H33.3075V21.6893H31.7236V20.1055Z" fill="currentColor"/>
</g>
</g>
<defs>
<clipPath id="clip0_371_1797">
<rect width="54" height="54" fill="white" transform="translate(0.5)"/>
</clipPath>
<clipPath id="clip1_371_1797">
<rect width="53.9571" height="54" fill="white" transform="translate(0.521484)"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default Subject_svg5
