import React from 'react'

const Language_svg2 = () => {
  return (
    <div>
      <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_132_8114)">
<g clip-path="url(#clip1_132_8114)">
<g clip-path="url(#clip2_132_8114)">
<path d="M69.75 54H6.75V18C6.75 15.5149 8.76487 13.5 11.25 13.5H65.25C67.7351 13.5 69.75 15.5149 69.75 18V54Z" fill="#353744"/>
</g>
<g clip-path="url(#clip3_132_8114)">
<path d="M69.75 54H6.75V56.25C6.75 58.7351 8.76487 60.75 11.25 60.75H65.25C67.7351 60.75 69.75 58.7351 69.75 56.25V54Z" fill="#808080"/>
</g>
<g clip-path="url(#clip4_132_8114)">
<path d="M65.25 54H6.75V18C6.75 15.5149 8.76487 13.5 11.25 13.5H60.75C63.2351 13.5 65.25 15.5149 65.25 18V54Z" fill="#55525B"/>
</g>
<g clip-path="url(#clip5_132_8114)">
<path d="M65.25 54H6.75V56.25C6.75 58.7351 8.76487 60.75 11.25 60.75H60.75C63.2351 60.75 65.25 58.7351 65.25 56.25V54Z" fill="#DBDEE3"/>
</g>
<g clip-path="url(#clip6_132_8114)">
<path d="M59.0625 18H12.9375C12.006 18 11.25 18.756 11.25 19.6875V47.8125C11.25 48.744 12.006 49.5 12.9375 49.5H59.0625C59.994 49.5 60.75 48.744 60.75 47.8125V19.6875C60.75 18.756 59.994 18 59.0625 18Z" fill="#FBBE18"/>
</g>
<g clip-path="url(#clip7_132_8114)">
<path d="M18 27H42.75C43.9931 27 45 25.9931 45 24.75C45 23.5069 43.9931 22.5 42.75 22.5H18C16.7569 22.5 15.75 23.5069 15.75 24.75C15.75 25.9931 16.7569 27 18 27Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip8_132_8114)">
<path d="M18 36H42.75C43.9931 36 45 34.9931 45 33.75C45 32.5069 43.9931 31.5 42.75 31.5H18C16.7569 31.5 15.75 32.5069 15.75 33.75C15.75 34.9931 16.7569 36 18 36Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip9_132_8114)">
<path d="M18 45H42.75C43.9931 45 45 43.9931 45 42.75C45 41.5069 43.9931 40.5 42.75 40.5H18C16.7569 40.5 15.75 41.5069 15.75 42.75C15.75 43.9931 16.7569 45 18 45Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip10_132_8114)">
<path d="M46.6875 65.25H42.1875V60.75H46.6875V65.25Z" fill="#808080"/>
</g>
<g clip-path="url(#clip11_132_8114)">
<path d="M31.5 60.75H42.1875V65.25H31.5V60.75Z" fill="#B3B4B6"/>
</g>
<g clip-path="url(#clip12_132_8114)">
<path d="M51.1875 65.25H23.0625C20.5774 65.25 18.5625 67.2649 18.5625 69.75H55.6875C55.6875 67.2649 53.6726 65.25 51.1875 65.25Z" fill="#DBDEE3"/>
</g>
<g clip-path="url(#clip13_132_8114)">
<path d="M51.1875 65.25H46.6875C44.2024 65.25 42.1875 67.2649 42.1875 69.75H55.6875C55.6875 67.2649 53.6726 65.25 51.1875 65.25Z" fill="#808080"/>
</g>
<g clip-path="url(#clip14_132_8114)">
<path d="M19.125 2.25H15.75C8.29463 2.25 2.25 8.29463 2.25 15.75C2.25 23.2054 8.29463 29.25 15.75 29.25H19.125C26.5804 29.25 32.625 23.2054 32.625 15.75C32.625 8.29463 26.5804 2.25 19.125 2.25Z" fill="#5C76B2"/>
</g>
<g clip-path="url(#clip15_132_8114)">
<path d="M15.75 29.25C23.2058 29.25 29.25 23.2058 29.25 15.75C29.25 8.29416 23.2058 2.25 15.75 2.25C8.29416 2.25 2.25 8.29416 2.25 15.75C2.25 23.2058 8.29416 29.25 15.75 29.25Z" fill="#6FA4D8"/>
</g>
<g clip-path="url(#clip16_132_8114)">
<path d="M15.75 27C21.9632 27 27 21.9632 27 15.75C27 9.5368 21.9632 4.5 15.75 4.5C9.5368 4.5 4.5 9.5368 4.5 15.75C4.5 21.9632 9.5368 27 15.75 27Z" fill="#55525B"/>
</g>
<g clip-path="url(#clip17_132_8114)">
<path d="M15.75 16.3125C15.4395 16.3125 15.1875 16.0605 15.1875 15.75V6.75C15.1875 6.4395 15.4395 6.1875 15.75 6.1875C16.0605 6.1875 16.3125 6.4395 16.3125 6.75V15.75C16.3125 16.0605 16.0605 16.3125 15.75 16.3125Z" fill="#6FA4D8"/>
</g>
<g clip-path="url(#clip18_132_8114)">
<path d="M10.9778 21.086C10.8338 21.086 10.6898 21.0309 10.5796 20.9218C10.3602 20.7024 10.3602 20.3458 10.5796 20.1264L15.3518 15.353C15.5712 15.1336 15.9278 15.1336 16.1472 15.353C16.3666 15.5724 16.3666 15.929 16.1472 16.1484L11.3749 20.9218C11.2658 21.0309 11.1218 21.086 10.9778 21.086Z" fill="#6FA4D8"/>
</g>
<g clip-path="url(#clip19_132_8114)">
<path d="M15.75 18C16.9926 18 18 16.9926 18 15.75C18 14.5074 16.9926 13.5 15.75 13.5C14.5074 13.5 13.5 14.5074 13.5 15.75C13.5 16.9926 14.5074 18 15.75 18Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip20_132_8114)">
<path d="M15.75 16.875C16.3713 16.875 16.875 16.3713 16.875 15.75C16.875 15.1287 16.3713 14.625 15.75 14.625C15.1287 14.625 14.625 15.1287 14.625 15.75C14.625 16.3713 15.1287 16.875 15.75 16.875Z" fill="#DBDEE3"/>
</g>
<g clip-path="url(#clip21_132_8114)">
<path d="M7.875 16.3125H6.75C6.4395 16.3125 6.1875 16.0605 6.1875 15.75C6.1875 15.4395 6.4395 15.1875 6.75 15.1875H7.875C8.1855 15.1875 8.4375 15.4395 8.4375 15.75C8.4375 16.0605 8.1855 16.3125 7.875 16.3125Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip22_132_8114)">
<path d="M15.75 25.3125C15.4395 25.3125 15.1875 25.0605 15.1875 24.75V23.625C15.1875 23.3145 15.4395 23.0625 15.75 23.0625C16.0605 23.0625 16.3125 23.3145 16.3125 23.625V24.75C16.3125 25.0605 16.0605 25.3125 15.75 25.3125Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip23_132_8114)">
<path d="M24.75 16.3125H23.625C23.3145 16.3125 23.0625 16.0605 23.0625 15.75C23.0625 15.4395 23.3145 15.1875 23.625 15.1875H24.75C25.0605 15.1875 25.3125 15.4395 25.3125 15.75C25.3125 16.0605 25.0605 16.3125 24.75 16.3125Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip24_132_8114)">
<path d="M52.875 45C51.6319 45 50.625 43.9931 50.625 42.75C50.625 41.5069 51.6319 40.5 52.875 40.5C54.1181 40.5 55.125 41.5069 55.125 42.75C55.125 43.9931 54.1181 45 52.875 45Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip25_132_8114)">
<path d="M52.031 23.3439L52.8747 24.1877L55.406 21.6564C55.8717 21.1907 56.6277 21.1907 57.0935 21.6564C57.5592 22.1222 57.5592 22.8782 57.0935 23.3439L53.7185 26.7189C53.2527 27.1847 52.4967 27.1847 52.031 26.7189L50.3435 25.0314C49.8777 24.5657 49.8777 23.8097 50.3435 23.3439C50.8092 22.8782 51.5652 22.8782 52.031 23.3439Z" fill="#5C76B2"/>
</g>
<g clip-path="url(#clip26_132_8114)">
<path d="M52.031 32.3439L52.8747 33.1877L55.406 30.6564C55.8717 30.1907 56.6277 30.1907 57.0935 30.6564C57.5592 31.1222 57.5592 31.8782 57.0935 32.3439L53.7185 35.7189C53.2527 36.1847 52.4967 36.1847 52.031 35.7189L50.3435 34.0314C49.8777 33.5657 49.8777 32.8097 50.3435 32.3439C50.8092 31.8782 51.5652 31.8782 52.031 32.3439Z" fill="#5C76B2"/>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_132_8114">
<rect width="72" height="72" fill="white"/>
</clipPath>
<clipPath id="clip1_132_8114">
<rect width="67.5" height="67.5" fill="white" transform="translate(2.25 2.25)"/>
</clipPath>
<clipPath id="clip2_132_8114">
<rect width="63" height="40.5" fill="white" transform="translate(6.75 13.5)"/>
</clipPath>
<clipPath id="clip3_132_8114">
<rect width="63" height="6.75" fill="white" transform="translate(6.75 54)"/>
</clipPath>
<clipPath id="clip4_132_8114">
<rect width="58.5" height="40.5" fill="white" transform="translate(6.75 13.5)"/>
</clipPath>
<clipPath id="clip5_132_8114">
<rect width="58.5" height="6.75" fill="white" transform="translate(6.75 54)"/>
</clipPath>
<clipPath id="clip6_132_8114">
<rect width="49.5" height="31.5" fill="white" transform="translate(11.25 18)"/>
</clipPath>
<clipPath id="clip7_132_8114">
<rect width="29.25" height="4.5" fill="white" transform="translate(15.75 22.5)"/>
</clipPath>
<clipPath id="clip8_132_8114">
<rect width="29.25" height="4.5" fill="white" transform="translate(15.75 31.5)"/>
</clipPath>
<clipPath id="clip9_132_8114">
<rect width="29.25" height="4.5" fill="white" transform="translate(15.75 40.5)"/>
</clipPath>
<clipPath id="clip10_132_8114">
<rect width="4.5" height="4.5" fill="white" transform="translate(42.1875 60.75)"/>
</clipPath>
<clipPath id="clip11_132_8114">
<rect width="10.6875" height="4.5" fill="white" transform="translate(31.5 60.75)"/>
</clipPath>
<clipPath id="clip12_132_8114">
<rect width="37.125" height="4.5" fill="white" transform="translate(18.5625 65.25)"/>
</clipPath>
<clipPath id="clip13_132_8114">
<rect width="13.5" height="4.5" fill="white" transform="translate(42.1875 65.25)"/>
</clipPath>
<clipPath id="clip14_132_8114">
<rect width="30.375" height="27" fill="white" transform="translate(2.25 2.25)"/>
</clipPath>
<clipPath id="clip15_132_8114">
<rect width="27" height="27" fill="white" transform="translate(2.25 2.25)"/>
</clipPath>
<clipPath id="clip16_132_8114">
<rect width="22.5" height="22.5" fill="white" transform="translate(4.5 4.5)"/>
</clipPath>
<clipPath id="clip17_132_8114">
<rect width="1.125" height="10.125" fill="white" transform="translate(15.1875 6.1875)"/>
</clipPath>
<clipPath id="clip18_132_8114">
<rect width="5.89669" height="5.89754" fill="white" transform="translate(10.415 15.1885)"/>
</clipPath>
<clipPath id="clip19_132_8114">
<rect width="4.5" height="4.5" fill="white" transform="translate(13.5 13.5)"/>
</clipPath>
<clipPath id="clip20_132_8114">
<rect width="2.25" height="2.25" fill="white" transform="translate(14.625 14.625)"/>
</clipPath>
<clipPath id="clip21_132_8114">
<rect width="2.25" height="1.125" fill="white" transform="translate(6.1875 15.1875)"/>
</clipPath>
<clipPath id="clip22_132_8114">
<rect width="1.125" height="2.25" fill="white" transform="translate(15.1875 23.0625)"/>
</clipPath>
<clipPath id="clip23_132_8114">
<rect width="2.25" height="1.125" fill="white" transform="translate(23.0625 15.1875)"/>
</clipPath>
<clipPath id="clip24_132_8114">
<rect width="4.5" height="4.5" fill="white" transform="translate(50.625 40.5)"/>
</clipPath>
<clipPath id="clip25_132_8114">
<rect width="7.44863" height="5.76112" fill="white" transform="translate(49.9941 21.3071)"/>
</clipPath>
<clipPath id="clip26_132_8114">
<rect width="7.44863" height="5.76113" fill="white" transform="translate(49.9941 30.3071)"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default Language_svg2
