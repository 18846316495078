import React from 'react'

const Main_svg2 = () => {
  return (
    <div>
      <svg width="28" height="37" viewBox="0 0 28 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.8594 8.2364L20.0754 1.45238C19.7293 1.17548 19.3139 0.96781 18.8986 0.96781H18.4832V9.82857H27.344V9.41322C27.344 8.99787 27.1363 8.58253 26.8594 8.2364ZM16.268 10.3824V0.96781H2.42311C1.45397 0.96781 0.761719 1.72928 0.761719 2.6292V34.7495C0.761719 35.7186 1.45397 36.4108 2.42311 36.4108H25.6826C26.5825 36.4108 27.344 35.7186 27.344 34.7495V12.0438H17.9294C16.9603 12.0438 16.268 11.3515 16.268 10.3824ZM5.1921 5.95199C5.1921 5.67509 5.39977 5.39819 5.7459 5.39819H11.2839C11.5608 5.39819 11.8377 5.67509 11.8377 5.95199V7.05958C11.8377 7.40571 11.5608 7.61338 11.2839 7.61338H5.7459C5.39977 7.61338 5.1921 7.40571 5.1921 7.05958V5.95199ZM5.1921 11.49V10.3824C5.1921 10.1055 5.39977 9.82857 5.7459 9.82857H11.2839C11.5608 9.82857 11.8377 10.1055 11.8377 10.3824V11.49C11.8377 11.8361 11.5608 12.0438 11.2839 12.0438H5.7459C5.39977 12.0438 5.1921 11.8361 5.1921 11.49ZM15.1605 29.7653V31.4267C15.1605 31.7728 14.8836 31.9805 14.6067 31.9805H13.4991C13.1529 31.9805 12.9453 31.7728 12.9453 31.4267V29.7653C12.1146 29.7653 11.3531 29.4884 10.7301 29.0038C10.4532 28.7961 10.4532 28.3808 10.7301 28.1731L11.4915 27.4116C11.6992 27.204 11.9761 27.204 12.1838 27.3424C12.4607 27.4809 12.8068 27.5501 13.0837 27.5501H15.022C15.5066 27.5501 15.8527 27.204 15.8527 26.6502C15.8527 26.2348 15.645 25.8887 15.2297 25.8195L12.1146 24.8503C10.8685 24.435 9.9686 23.2582 9.9686 21.8737C9.9686 20.143 11.2839 18.7586 12.876 18.7586V17.0279C12.876 16.751 13.1529 16.4741 13.4298 16.4741H14.5374C14.8836 16.4741 15.0912 16.751 15.0912 17.0279V18.7586C15.9219 18.7586 16.6834 19.0355 17.3064 19.52C17.5833 19.7277 17.5833 20.143 17.3064 20.3507L16.5449 21.1122C16.3373 21.3199 16.0604 21.3199 15.8527 21.1814C15.5758 21.043 15.2297 20.9045 14.9528 20.9045H13.0145C12.5299 20.9045 12.1838 21.3199 12.1838 21.8737C12.1838 22.289 12.3915 22.6351 12.8068 22.7044L15.9219 23.6735C17.168 24.0196 18.0679 25.2657 18.0679 26.6502C18.0679 28.3808 16.7526 29.7653 15.1605 29.7653Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Main_svg2
