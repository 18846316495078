import React from 'react'

const Language_svg1 = () => {
  return (
    <div>
      <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_132_8172)">
<g clip-path="url(#clip1_132_8172)">
<g clip-path="url(#clip2_132_8172)">
<g clip-path="url(#clip3_132_8172)">
<path d="M57.375 11.25H37.125C34.6399 11.25 32.625 13.2649 32.625 15.75V29.25C32.625 31.7351 34.6399 33.75 37.125 33.75H55.125L57.7046 36.3296C57.9229 36.5479 58.1895 36.6401 58.4539 36.6491V36.6615H60.75C61.371 36.6615 61.875 36.1575 61.875 35.5365V15.75C61.875 13.2649 59.8601 11.25 57.375 11.25Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip4_132_8172)">
<path d="M55.125 11.25H37.125C34.6399 11.25 32.625 13.2649 32.625 15.75V29.25C32.625 31.7351 34.6399 33.75 37.125 33.75H55.125L57.7046 36.3296C58.4134 37.0384 59.625 36.5366 59.625 35.5343V15.75C59.625 13.2649 57.6101 11.25 55.125 11.25Z" fill="#FBBE18"/>
</g>
</g>
<g clip-path="url(#clip5_132_8172)">
<path d="M39.375 24.75C40.6176 24.75 41.625 23.7426 41.625 22.5C41.625 21.2574 40.6176 20.25 39.375 20.25C38.1324 20.25 37.125 21.2574 37.125 22.5C37.125 23.7426 38.1324 24.75 39.375 24.75Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip6_132_8172)">
<path d="M46.125 24.75C47.3676 24.75 48.375 23.7426 48.375 22.5C48.375 21.2574 47.3676 20.25 46.125 20.25C44.8824 20.25 43.875 21.2574 43.875 22.5C43.875 23.7426 44.8824 24.75 46.125 24.75Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip7_132_8172)">
<path d="M52.875 24.75C54.1176 24.75 55.125 23.7426 55.125 22.5C55.125 21.2574 54.1176 20.25 52.875 20.25C51.6324 20.25 50.625 21.2574 50.625 22.5C50.625 23.7426 51.6324 24.75 52.875 24.75Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip8_132_8172)">
<g clip-path="url(#clip9_132_8172)">
<path d="M34.875 2.25H14.625C12.1399 2.25 10.125 4.26487 10.125 6.75V26.5343C10.125 27.5366 11.3366 28.0384 12.0454 27.3296L14.625 24.75H34.875C37.3601 24.75 39.375 22.7351 39.375 20.25V6.75C39.375 4.26487 37.3601 2.25 34.875 2.25Z" fill="#5C76B2"/>
</g>
<g clip-path="url(#clip10_132_8172)">
<path d="M32.625 2.25H14.625C12.1399 2.25 10.125 4.26487 10.125 6.75V26.5343C10.125 27.5366 11.3366 28.0384 12.0454 27.3296L14.625 24.75H32.625C35.1101 24.75 37.125 22.7351 37.125 20.25V6.75C37.125 4.26487 35.1101 2.25 32.625 2.25Z" fill="#6FA4D8"/>
</g>
</g>
<g clip-path="url(#clip11_132_8172)">
<g clip-path="url(#clip12_132_8172)">
<g clip-path="url(#clip13_132_8172)">
<path d="M19.125 42.75C15.3968 42.75 12.375 45.7718 12.375 49.5V63H19.6875V60.75H23.625C24.8681 60.75 25.875 59.742 25.875 58.5V49.5C25.875 45.7718 22.8532 42.75 19.125 42.75Z" fill="#EEC78E"/>
</g>
<g clip-path="url(#clip14_132_8172)">
<path d="M24.4688 56.25C23.6925 56.25 23.0625 55.62 23.0625 54.8438V51.75C24.6161 51.75 25.875 53.0089 25.875 54.5625V54.8438C25.875 55.62 25.245 56.25 24.4688 56.25Z" fill="#DB9E68"/>
</g>
<g clip-path="url(#clip15_132_8172)">
<path d="M21.375 51.75C21.6857 51.75 21.9375 51.4982 21.9375 51.1875C21.9375 50.8768 21.6857 50.625 21.375 50.625C21.0643 50.625 20.8125 50.8768 20.8125 51.1875C20.8125 51.4982 21.0643 51.75 21.375 51.75Z" fill="#55525B"/>
</g>
<g clip-path="url(#clip16_132_8172)">
<path d="M24.75 51.75C25.0607 51.75 25.3125 51.4982 25.3125 51.1875C25.3125 50.8768 25.0607 50.625 24.75 50.625C24.4393 50.625 24.1875 50.8768 24.1875 51.1875C24.1875 51.4982 24.4393 51.75 24.75 51.75Z" fill="#55525B"/>
</g>
<g clip-path="url(#clip17_132_8172)">
<path d="M19.6875 63H12.375C12.375 61.7569 13.3819 60.75 14.625 60.75H19.6875V63Z" fill="#DB9E68"/>
</g>
<g clip-path="url(#clip18_132_8172)">
<path d="M24.75 57.375H21.375C21.375 57.996 21.879 58.5 22.5 58.5H23.625C24.246 58.5 24.75 57.996 24.75 57.375Z" fill="#55525B"/>
</g>
</g>
<g clip-path="url(#clip19_132_8172)">
<path d="M24.75 40.5H16.875C13.1468 40.5 10.125 43.5218 10.125 47.25V56.25C10.125 57.4931 11.1319 58.5 12.375 58.5H14.625C15.8681 58.5 16.875 57.4931 16.875 56.25V52.875H17.4375C18.369 52.875 19.125 52.119 19.125 51.1875V48.9375C19.125 48.006 19.881 47.25 20.8125 47.25H24.75C26.6141 47.25 28.125 45.7391 28.125 43.875C28.125 42.0109 26.6141 40.5 24.75 40.5Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip20_132_8172)">
<path d="M16.875 51.75C15.6319 51.75 14.625 52.7569 14.625 54C14.625 55.2431 15.6319 56.25 16.875 56.25V51.75Z" fill="#DB9E68"/>
</g>
<g clip-path="url(#clip21_132_8172)">
<path d="M22.5 63H12.375C10.5109 63 9 64.5109 9 66.375V69.75H25.875V66.375C25.875 64.5109 24.3641 63 22.5 63Z" fill="#5C76B2"/>
</g>
<g clip-path="url(#clip22_132_8172)">
<path d="M12.375 63C10.5109 63 9 64.5109 9 66.375V69.75H15.75V66.375C15.75 64.5109 14.2391 63 12.375 63Z" fill="#6FA4D8"/>
</g>
</g>
<g clip-path="url(#clip23_132_8172)">
<g clip-path="url(#clip24_132_8172)">
<g clip-path="url(#clip25_132_8172)">
<path d="M52.875 42.75C49.1468 42.75 46.125 45.7718 46.125 49.5V58.5C46.125 59.742 47.1319 60.75 48.375 60.75H52.3125V63H59.625V49.5C59.625 45.7718 56.6032 42.75 52.875 42.75Z" fill="#EEC78E"/>
</g>
<g clip-path="url(#clip26_132_8172)">
<path d="M47.5312 56.25C48.3075 56.25 48.9375 55.62 48.9375 54.8438V51.75C47.3839 51.75 46.125 53.0089 46.125 54.5625V54.8438C46.125 55.62 46.755 56.25 47.5312 56.25Z" fill="#DB9E68"/>
</g>
<g clip-path="url(#clip27_132_8172)">
<path d="M50.625 51.75C50.9357 51.75 51.1875 51.4982 51.1875 51.1875C51.1875 50.8768 50.9357 50.625 50.625 50.625C50.3143 50.625 50.0625 50.8768 50.0625 51.1875C50.0625 51.4982 50.3143 51.75 50.625 51.75Z" fill="#55525B"/>
</g>
<g clip-path="url(#clip28_132_8172)">
<path d="M47.25 51.75C47.5607 51.75 47.8125 51.4982 47.8125 51.1875C47.8125 50.8768 47.5607 50.625 47.25 50.625C46.9393 50.625 46.6875 50.8768 46.6875 51.1875C46.6875 51.4982 46.9393 51.75 47.25 51.75Z" fill="#55525B"/>
</g>
<g clip-path="url(#clip29_132_8172)">
<path d="M52.3125 63H59.625C59.625 61.7569 58.6181 60.75 57.375 60.75H52.3125V63Z" fill="#DB9E68"/>
</g>
<g clip-path="url(#clip30_132_8172)">
<path d="M47.25 57.375H50.625C50.625 57.996 50.121 58.5 49.5 58.5H48.375C47.754 58.5 47.25 57.996 47.25 57.375Z" fill="#55525B"/>
</g>
</g>
<g clip-path="url(#clip31_132_8172)">
<path d="M55.125 40.5H51.75C48.0218 40.5 45 43.5218 45 47.25H51.1875C52.119 47.25 52.875 48.006 52.875 48.9375V50.0625C52.875 50.994 53.631 51.75 54.5625 51.75H55.125V60.75C55.125 61.9931 54.1181 63 52.875 63H59.625C60.8681 63 61.875 61.9931 61.875 60.75V47.25C61.875 43.5218 58.8532 40.5 55.125 40.5Z" fill="#55525B"/>
</g>
<g clip-path="url(#clip32_132_8172)">
<path d="M55.125 51.75C56.3681 51.75 57.375 52.7569 57.375 54C57.375 55.2431 56.3681 56.25 55.125 56.25V51.75Z" fill="#DB9E68"/>
</g>
<g clip-path="url(#clip33_132_8172)">
<path d="M59.625 63H49.5C47.6359 63 46.125 64.5109 46.125 66.375V69.75H63V66.375C63 64.5109 61.4891 63 59.625 63Z" fill="#FBBE18"/>
</g>
<g clip-path="url(#clip34_132_8172)">
<path d="M59.625 63C61.4891 63 63 64.5109 63 66.375V69.75H56.25V66.375C56.25 64.5109 57.7609 63 59.625 63Z" fill="#F68820"/>
</g>
</g>
<g clip-path="url(#clip35_132_8172)">
<path d="M42.3517 47.977L40.3346 45.9598C39.9814 45.6066 39.375 45.8575 39.375 46.3581V47.2502H32.0625C31.752 47.2502 31.5 47.5022 31.5 47.8127V48.9377C31.5 49.2482 31.752 49.5002 32.0625 49.5002H39.375V50.3923C39.375 50.893 39.9814 51.1438 40.3358 50.7906L42.3529 48.7735C42.5722 48.553 42.5723 48.1975 42.3517 47.977Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip36_132_8172)">
<path d="M39.9373 55.1252H32.6248V54.2331C32.6248 53.7325 32.0184 53.4816 31.6641 53.8348L29.647 55.852C29.4276 56.0713 29.4276 56.428 29.647 56.6473L31.6641 58.6645C32.0184 59.0188 32.6248 58.768 32.6248 58.2673V57.3752H39.9373C40.2478 57.3752 40.4998 57.1232 40.4998 56.8127V55.6877C40.4998 55.3772 40.2478 55.1252 39.9373 55.1252Z" fill="#F68820"/>
</g>
<g clip-path="url(#clip37_132_8172)">
<path d="M16.875 15.75C18.1176 15.75 19.125 14.7426 19.125 13.5C19.125 12.2574 18.1176 11.25 16.875 11.25C15.6324 11.25 14.625 12.2574 14.625 13.5C14.625 14.7426 15.6324 15.75 16.875 15.75Z" fill="#5C76B2"/>
</g>
<g clip-path="url(#clip38_132_8172)">
<path d="M23.625 15.75C24.8676 15.75 25.875 14.7426 25.875 13.5C25.875 12.2574 24.8676 11.25 23.625 11.25C22.3824 11.25 21.375 12.2574 21.375 13.5C21.375 14.7426 22.3824 15.75 23.625 15.75Z" fill="#5C76B2"/>
</g>
<g clip-path="url(#clip39_132_8172)">
<path d="M30.375 15.75C31.6176 15.75 32.625 14.7426 32.625 13.5C32.625 12.2574 31.6176 11.25 30.375 11.25C29.1324 11.25 28.125 12.2574 28.125 13.5C28.125 14.7426 29.1324 15.75 30.375 15.75Z" fill="#5C76B2"/>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_132_8172">
<rect width="72" height="72" fill="white"/>
</clipPath>
<clipPath id="clip1_132_8172">
<rect width="54" height="67.5" fill="white" transform="translate(9 2.25)"/>
</clipPath>
<clipPath id="clip2_132_8172">
<rect width="29.25" height="25.4115" fill="white" transform="translate(32.625 11.25)"/>
</clipPath>
<clipPath id="clip3_132_8172">
<rect width="29.25" height="25.4115" fill="white" transform="translate(32.625 11.25)"/>
</clipPath>
<clipPath id="clip4_132_8172">
<rect width="27" height="25.4115" fill="white" transform="translate(32.625 11.25)"/>
</clipPath>
<clipPath id="clip5_132_8172">
<rect width="4.5" height="4.5" fill="white" transform="translate(37.125 20.25)"/>
</clipPath>
<clipPath id="clip6_132_8172">
<rect width="4.5" height="4.5" fill="white" transform="translate(43.875 20.25)"/>
</clipPath>
<clipPath id="clip7_132_8172">
<rect width="4.5" height="4.5" fill="white" transform="translate(50.625 20.25)"/>
</clipPath>
<clipPath id="clip8_132_8172">
<rect width="29.25" height="25.4115" fill="white" transform="translate(10.125 2.25)"/>
</clipPath>
<clipPath id="clip9_132_8172">
<rect width="29.25" height="25.4115" fill="white" transform="translate(10.125 2.25)"/>
</clipPath>
<clipPath id="clip10_132_8172">
<rect width="27" height="25.4115" fill="white" transform="translate(10.125 2.25)"/>
</clipPath>
<clipPath id="clip11_132_8172">
<rect width="19.125" height="29.25" fill="white" transform="translate(9 40.5)"/>
</clipPath>
<clipPath id="clip12_132_8172">
<rect width="13.5" height="20.25" fill="white" transform="translate(12.375 42.75)"/>
</clipPath>
<clipPath id="clip13_132_8172">
<rect width="13.5" height="20.25" fill="white" transform="translate(12.375 42.75)"/>
</clipPath>
<clipPath id="clip14_132_8172">
<rect width="2.8125" height="4.5" fill="white" transform="translate(23.0625 51.75)"/>
</clipPath>
<clipPath id="clip15_132_8172">
<rect width="1.125" height="1.125" fill="white" transform="translate(20.8125 50.625)"/>
</clipPath>
<clipPath id="clip16_132_8172">
<rect width="1.125" height="1.125" fill="white" transform="translate(24.1875 50.625)"/>
</clipPath>
<clipPath id="clip17_132_8172">
<rect width="7.3125" height="2.25" fill="white" transform="translate(12.375 60.75)"/>
</clipPath>
<clipPath id="clip18_132_8172">
<rect width="3.375" height="1.125" fill="white" transform="translate(21.375 57.375)"/>
</clipPath>
<clipPath id="clip19_132_8172">
<rect width="18" height="18" fill="white" transform="translate(10.125 40.5)"/>
</clipPath>
<clipPath id="clip20_132_8172">
<rect width="2.25" height="4.5" fill="white" transform="translate(14.625 51.75)"/>
</clipPath>
<clipPath id="clip21_132_8172">
<rect width="16.875" height="6.75" fill="white" transform="translate(9 63)"/>
</clipPath>
<clipPath id="clip22_132_8172">
<rect width="6.75" height="6.75" fill="white" transform="translate(9 63)"/>
</clipPath>
<clipPath id="clip23_132_8172">
<rect width="18" height="29.25" fill="white" transform="translate(45 40.5)"/>
</clipPath>
<clipPath id="clip24_132_8172">
<rect width="13.5" height="20.25" fill="white" transform="translate(46.125 42.75)"/>
</clipPath>
<clipPath id="clip25_132_8172">
<rect width="13.5" height="20.25" fill="white" transform="translate(46.125 42.75)"/>
</clipPath>
<clipPath id="clip26_132_8172">
<rect width="2.8125" height="4.5" fill="white" transform="translate(46.125 51.75)"/>
</clipPath>
<clipPath id="clip27_132_8172">
<rect width="1.125" height="1.125" fill="white" transform="translate(50.0625 50.625)"/>
</clipPath>
<clipPath id="clip28_132_8172">
<rect width="1.125" height="1.125" fill="white" transform="translate(46.6875 50.625)"/>
</clipPath>
<clipPath id="clip29_132_8172">
<rect width="7.3125" height="2.25" fill="white" transform="translate(52.3125 60.75)"/>
</clipPath>
<clipPath id="clip30_132_8172">
<rect width="3.375" height="1.125" fill="white" transform="translate(47.25 57.375)"/>
</clipPath>
<clipPath id="clip31_132_8172">
<rect width="16.875" height="22.5" fill="white" transform="translate(45 40.5)"/>
</clipPath>
<clipPath id="clip32_132_8172">
<rect width="2.25" height="4.5" fill="white" transform="translate(55.125 51.75)"/>
</clipPath>
<clipPath id="clip33_132_8172">
<rect width="16.875" height="6.75" fill="white" transform="translate(46.125 63)"/>
</clipPath>
<clipPath id="clip34_132_8172">
<rect width="6.75" height="6.75" fill="white" transform="translate(56.25 63)"/>
</clipPath>
<clipPath id="clip35_132_8172">
<rect width="11.0173" height="5.16107" fill="white" transform="translate(31.5 45.7947)"/>
</clipPath>
<clipPath id="clip36_132_8172">
<rect width="11.0174" height="5.16072" fill="white" transform="translate(29.4824 53.6697)"/>
</clipPath>
<clipPath id="clip37_132_8172">
<rect width="4.5" height="4.5" fill="white" transform="translate(14.625 11.25)"/>
</clipPath>
<clipPath id="clip38_132_8172">
<rect width="4.5" height="4.5" fill="white" transform="translate(21.375 11.25)"/>
</clipPath>
<clipPath id="clip39_132_8172">
<rect width="4.5" height="4.5" fill="white" transform="translate(28.125 11.25)"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default Language_svg1
