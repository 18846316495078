import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ipaddress } from '../App';

const FieldSelector = ({ show, setshow, settutors}) => {
  // Array of field names
  const fields = ['name', 'contact_number', 'subjects', 'boards', 'standards', 'languages', 'gender', 'location', 'experience', 'week_availability', 'start_time', 'end_time', 'mode'];

  // State to keep track of selected fields and their input values
  const [selectedFields, setSelectedFields] = useState({});

  // Handle checkbox change
  const handleCheckboxChange = (field) => {
    setSelectedFields((prev) => {
      if (prev.hasOwnProperty(field)) {
        // If the field is already present, remove it
        const { [field]: _, ...rest } = prev;
        return rest;
      } else {
        // Otherwise, add the field with an empty string value
        return { ...prev, [field]: '' };
      }
    });
  };

  const cleanData = (data) => {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, value || ''])
    );
  };

  // Handle input change
  const handleInputChange = (field, value) => {
    setSelectedFields((prev) => ({ ...prev, [field]: value }));
  };

  // Handle form submission
  const handleSubmit = () => {
    const cleanedData = cleanData(selectedFields);

    // Fill in any missing fields with empty strings
    const filledFields = fields.reduce((acc, field) => {
      acc[field] = cleanedData[field] || '';
      return acc;
    }, {});

    // Construct the query string
    const queryParams = new URLSearchParams(filledFields).toString();

    console.log('Sending data to backend:', filledFields);

    axios.get(`${ipaddress}/filter_tutors/?${queryParams}`)
      .then((r) => {
        console.log("Filtered tutors", r.data);
        settutors(r.data)
        setshow(false)
    setSelectedFields({});

      })
      .catch((error) => {
        console.error("Error fetching tutors:", error);
      });
  };

  // Handle field removal
  const handleRemoveField = (field) => {
    setSelectedFields((prev) => {
      const { [field]: _, ...rest } = prev;
      return rest;
    });
  };

  // Select all fields
  const handleSelectAll = () => {
    const allFields = fields.reduce((acc, field) => ({ ...acc, [field]: '' }), {});
    setSelectedFields(allFields);
  };

  // Remove all fields
  const handleRemoveAll = () => {
    setSelectedFields({});
  };

  const submit = () => {
    setSelectedFields({});
  };

  // Fetch values from backend
  const [areas, setareas] = useState([]);
  const [languages, setlanguages] = useState([]);
  const [days, setdays] = useState([]);
  const [experiences, setexperiences] = useState([]);
  const [subjects, setsubjects] = useState([]);
  const [standards, setstandards] = useState([]);
  const [timings, settimings] = useState([]);
  const [boards, setboards] = useState([]);

  useEffect(() => {
    axios.get(`${ipaddress}/AllDataAPIView/`)
      .then((r) => {
        console.log(r.data);
        setsubjects(r.data.subjects);
        setboards(r.data.boards);
        settimings(r.data.timings);
        setdays(r.data.days);
        setstandards(r.data.standards);
        setexperiences(r.data.experiences);
        setlanguages(r.data.languages);
        setareas(r.data.areas);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      <Modal show={show} size='lg' onHide={() => { setshow(false) }}>
        <Modal.Body style={{ backgroundColor: '#F3F3FD', borderRadius: '10px' }}>
          <div>
            <div className="input-group border bg-white d-flex align-items-center py-2" style={{ borderRadius: '10px', cursor: 'pointer' }} data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <span className="input-group-text bg-transparent border-0" id="basic-addon1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                </svg>
              </span>
              <p className='m-0 text-secondary ms-2'>Search...</p>
            </div>
            <div className="collapse mb-3" id="collapseExample">
              <div className="card card-body">
                <div className='d-flex justify-content-end'>
                  <p className='btns' onClick={handleSelectAll}>Select All</p>
                  <p className='btns ms-4' onClick={handleRemoveAll}>Remove All</p>
                </div>

                <div className="row m-0 mb-4">
                  {fields.map((field) => (
                    <div key={field} className='col-md-6 col-lg-4 mb-3'>
                      <label className='d-flex align-items-center'>
                        <input style={{ cursor: 'pointer' }}
                          type="checkbox" className='me-1'
                          checked={field in selectedFields}
                          onChange={() => handleCheckboxChange(field)}
                        />
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {fields.map((field) => (
              <div key={field} className=''>
                {field in selectedFields && (
                  <div className='row m-0 align-items-center mb-4'>
                    <div className="col-md-5">
                      <label htmlFor={field} className='filter-label'>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                    </div>
                    <div className="col-md-7 d-flex align-items-center">
                      {/* Text Inputs */}
                      <input
                        type="text" className={`filter-input form-control shadow-none border-0 border-bottom rounded-0 bg-transparent ${field === 'name' || field === 'contact_number' || field === 'location' || field === 'experience' ? '' : 'd-none'}`}
                        id={field}
                        placeholder={`Enter ${field}`}
                        value={selectedFields[field] || ''}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                      />

                      {/* Timings */}
                      <input
                        type="time" className={`filter-input form-control shadow-none border-0 border-bottom rounded-0 bg-transparent ${field === 'start_time' || field === 'end_time' ? '' : 'd-none'}`}
                        id={field}
                        placeholder={`Enter ${field}`}
                        value={selectedFields[field] || ''}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                      />

                      {/* Gender */}
                      <select
                        className={`filter-input form-select shadow-none border-0 border-bottom rounded-0 bg-transparent ${field === 'gender' ? '' : 'd-none'}`}
                        id={field}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                      >
                        <option value="">Select Gender...</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="transgender">Transgender</option>
                      </select>

                      {/* Mode */}
                      <select
                        className={`filter-input form-select shadow-none border-0 border-bottom rounded-0 bg-transparent ${field === 'mode' ? '' : 'd-none'}`}
                        id={field}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                      >
                        <option value="">Select Mode...</option>
                        <option value="online">Online</option>
                        <option value="offline">Offline</option>
                        <option value="office">Come to Office</option>
                      </select>

                      {/* Week Availability */}
                      <select
                        className={`filter-input form-select shadow-none border-0 border-bottom rounded-0 bg-transparent ${field === 'week_availability' ? '' : 'd-none'}`}
                        id={field}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                      >
                        <option value="">Select Days...</option>
                        {days.map((x) => (
                          <option key={x.name} value={x.id}>{x.name}</option>
                        ))}
                      </select>

                      {/* Subjects */}
                      <select
                        className={`filter-input form-select shadow-none border-0 border-bottom rounded-0 bg-transparent ${field === 'subjects' ? '' : 'd-none'}`}
                        id={field}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                      >
                        <option value="">Select Subject...</option>
                        {subjects.map((x) => (
                          <option key={x.name} value={x.id}>{x.name}</option>
                        ))}
                      </select>

                      {/* Boards */}
                      <select
                        className={`filter-input form-select shadow-none border-0 border-bottom rounded-0 bg-transparent ${field === 'boards' ? '' : 'd-none'}`}
                        id={field}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                      >
                        <option value="">Select Board...</option>
                        {boards.map((x) => (
                          <option key={x.name} value={x.id}>{x.name}</option>
                        ))}
                      </select>

                      {/* Standards */}
                      <select
                        className={`filter-input form-select shadow-none border-0 border-bottom rounded-0 bg-transparent ${field === 'standards' ? '' : 'd-none'}`}
                        id={field}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                      >
                        <option value="">Select Standard...</option>
                        {standards.map((x) => (
                          <option key={x.name} value={x.id}>{x.name}</option>
                        ))}
                      </select>

                      {/* Languages */}
                      <select
                        className={`filter-input form-select shadow-none border-0 border-bottom rounded-0 bg-transparent ${field === 'languages' ? '' : 'd-none'}`}
                        id={field}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                      >
                        <option value="">Select Language...</option>
                        {languages.map((x) => (
                          <option key={x.name} value={x.id}>{x.name}</option>
                        ))}
                      </select>

                      <svg style={{ cursor: 'pointer' }} onClick={() => handleRemoveField(field)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            ))}

            <div className='text-end mt-3'>
              <button className='btn text-white px-4 btn-sm' style={{ backgroundColor: '#3940DB', borderRadius: '10px' }} onClick={handleSubmit}>Apply</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FieldSelector;
