import React from 'react'
import Work_svg from '../How-it-works/works-svgs/Work_svg'
import Work_svg1 from '../How-it-works/works-svgs/Work_svg1'
import Work_svg2 from '../How-it-works/works-svgs/Work_svg2'
import { boards } from '../../Contents/Borads'
import { useNavigate } from 'react-router-dom'
import Navbar from '../Navbar'
import Main_footer from '../Footer'

const All_boards = () => {
    const navigate=useNavigate()
    
  return (
    <div className='animate__animated animate__fadeIn'>
        <Navbar value={"boards"}/>
        <div className="row m-0 container mx-auto py-4">
        <p className='fs-1 fw-bolder text-center'>All Boards</p>
        <p className=' text-center mb-5 pb-4'>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor. Proin eget nibh accumsan, placerat erat sit amet, pellentesque nisi.</p>
        {boards.map((x,index)=>{
            return(
                <div className="col-lg-4 col-md-6 mb-5" onClick={()=>{
                    navigate(`/board/${x.slug}`)
                }} style={{cursor:'pointer'}}>
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>{x.name}</p>
                <p className='mt-4 text-center'>{x.paragraph1 && x.paragraph1.slice(0,100)} ...</p>

            </div>
        </div>
            )
        })}
        

      </div>

      <Main_footer/>
    </div>
  )
}

export default All_boards
