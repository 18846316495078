import React from 'react'

const Main_svg1 = () => {
  return (
    <div>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.447266 28.4285V31.3359C0.447266 33.828 6.40059 35.7663 13.7384 35.7663C21.0762 35.7663 27.0295 33.828 27.0295 31.3359V28.4285C24.1221 30.436 18.9303 31.3359 13.7384 31.3359C8.47733 31.3359 3.28548 30.436 0.447266 28.4285ZM22.5992 9.18404C29.937 9.18404 35.8903 7.24575 35.8903 4.75366C35.8903 2.33079 29.937 0.323278 22.5992 0.323278C15.2613 0.323278 9.30803 2.33079 9.30803 4.75366C9.30803 7.24575 15.2613 9.18404 22.5992 9.18404ZM0.447266 21.1599V24.6904C0.447266 27.1825 6.40059 29.1207 13.7384 29.1207C21.0762 29.1207 27.0295 27.1825 27.0295 24.6904V21.1599C24.1221 23.5135 18.9303 24.6904 13.7384 24.6904C8.47733 24.6904 3.28548 23.5135 0.447266 21.1599ZM29.2447 21.9214C33.1905 21.1599 35.8903 19.7062 35.8903 18.0448V15.1374C34.2289 16.245 31.8753 17.0064 29.2447 17.491V21.9214ZM13.7384 11.3992C6.40059 11.3992 0.447266 13.8913 0.447266 16.9372C0.447266 20.0523 6.40059 22.4752 13.7384 22.4752C21.0762 22.4752 27.0295 20.0523 27.0295 16.9372C27.0295 13.8913 21.0762 11.3992 13.7384 11.3992ZM28.8986 15.345C33.0521 14.5836 35.8903 13.1298 35.8903 11.3992V8.49179C33.3982 10.2224 29.1755 11.1223 24.7451 11.3992C26.7526 12.3684 28.2756 13.6836 28.8986 15.345Z" fill="currentColor"/>
<defs>
<linearGradient id="paint0_linear_467_6581" x1="0.447266" y1="0.335938" x2="0.447266" y2="35.779" gradientUnits="userSpaceOnUse">
<stop stop-color="#2A2AFF"/>
<stop offset="1" stop-color="#550096"/>
</linearGradient>
</defs>
</svg>

    </div>
  )
}

export default Main_svg1
