import React from 'react'

const Admin_dashboard = () => {
  return (
    <div>
      Admin dashboard
    </div>
  )
}

export default Admin_dashboard
