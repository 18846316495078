import React from 'react'

const Main_svg5 = () => {
  return (
    <div>
      <svg width="26" height="36" viewBox="0 0 26 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.51666 31.9846C7.51666 32.3999 7.58589 32.8153 7.86279 33.2306L9.03961 34.9612C9.45495 35.5843 10.1472 35.9996 10.9087 35.9996H15.1314C15.8929 35.9996 16.5851 35.5843 17.0004 34.9612L18.1773 33.2306C18.3849 32.8153 18.5234 32.3999 18.5234 31.9846V29.2848H7.51666V31.9846ZM12.9854 0.487341C5.9245 0.556566 0.871094 6.23299 0.871094 12.6709C0.871094 15.786 1.97869 18.555 3.84776 20.7009C5.02457 22.0162 6.75519 24.7852 7.44744 27.0696C7.44744 27.0696 7.44744 27.0696 7.44744 27.1388H18.5926C18.5926 27.0696 18.5926 27.0696 18.5926 27.0696C19.2849 24.7852 21.0155 22.0162 22.1923 20.7009C24.0614 18.555 25.2382 15.786 25.2382 12.6709C25.2382 5.95609 19.7002 0.487341 12.9854 0.487341ZM14.1622 18.2089V19.3165C14.1622 19.6626 13.8853 19.8703 13.6084 19.8703H12.5008C12.1547 19.8703 11.947 19.6626 11.947 19.3165V18.2089C11.1163 18.2089 10.3549 17.932 9.73185 17.4474C9.45495 17.2397 9.45495 16.8244 9.73185 16.6167L10.4933 15.8552C10.701 15.6475 10.9779 15.6475 11.1856 15.786C11.4625 15.9244 11.8086 15.9937 12.0855 15.9937H14.0238C14.5084 15.9937 14.8545 15.6475 14.8545 15.0937C14.8545 14.6784 14.6468 14.3323 14.2315 14.2631L11.1163 13.2939C9.8703 12.8786 8.97038 11.7017 8.97038 10.3172C8.97038 8.58663 10.2857 7.20214 11.8778 7.20214V6.02532C11.8778 5.74842 12.1547 5.47152 12.4316 5.47152H13.5392C13.8853 5.47152 14.093 5.74842 14.093 6.02532V7.20214C14.9237 7.20214 15.6852 7.47903 16.3082 7.96361C16.5851 8.17128 16.5851 8.58663 16.3082 8.7943L15.5467 9.55577C15.3391 9.76345 15.0622 9.76345 14.8545 9.625C14.5776 9.48655 14.2315 9.3481 13.9546 9.3481H12.0163C11.5317 9.3481 11.1856 9.76345 11.1856 10.3172C11.1856 10.7326 11.3932 11.0787 11.8086 11.1479L14.9237 12.1171C16.1697 12.4632 17.0697 13.7093 17.0697 15.0937C17.0697 16.8244 15.7544 18.2089 14.1622 18.2089Z" fill="currentColor"/>
<defs>
<linearGradient id="paint0_linear_467_6610" x1="-15.106" y1="20.801" x2="21.0547" y2="42.5026" gradientUnits="userSpaceOnUse">
<stop stop-color="#7C00D9"/>
<stop offset="0.565343" stop-color="#4600B1"/>
<stop offset="1" stop-color="#141778"/>
</linearGradient>
</defs>
</svg>

    </div>
  )
}

export default Main_svg5
