import React from 'react'
import Work_svg from '../How-it-works/works-svgs/Work_svg'
import Work_svg1 from '../How-it-works/works-svgs/Work_svg1'
import Work_svg2 from '../How-it-works/works-svgs/Work_svg2'

const All_subjects = () => {
  return (
    <div>
        <div className="row m-0 container mx-auto py-5 my-4">
        <p className='fs-1 fw-bolder text-center'>All Subjects</p>
        <p className=' text-center mb-5 pb-4'>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor. Proin eget nibh accumsan, placerat erat sit amet, pellentesque nisi.</p>


        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>English</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg1/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Tamil</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg2/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>German</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        
      </div>
    </div>
  )
}

export default All_subjects
