import React from 'react'
import Blogs from '../Subjects/Blogs'
import Benefits_to_student from '../Benefits_to_student'
import What_will_you_be from '../What_will_you_be'
import Assessment from '../Assessment'
import Layout8 from '../Subjects/Layout8'
import FAQ from '../Subjects/FAQ'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Navbar from '../Navbar'

const Curricular_activity = () => {
  return (
    <div className='animate__animated animate__fadeIn' style={{backgroundColor:'#E2E8F0'}}>
      <div className='activity-div pb-5' style={{position:'relative'}}>
        <Navbar value={"activity"}/>
        <h1 className='activity-text container fw-bold mt-5'>Unlock New Skills Learn, Play, and Grow</h1>

        <p className='text-center text-white fs-5 container mt-4'>Sit elit feugiat turpis sed integer integer accumsan turpis. Sed suspendisse nec lorem mauris.
        Pharetra, eu imperdiet ipsum ultrices amet, dui sit suspendisse.</p>
        <button className='btn text-white px-3 py-2 d-block mx-auto' style={{backgroundColor:'#347EE8'}}>Connect Now</button>

        <img src={require('../../images/Left.png')} style={{width:'70%',position:'absolute',left: '50%', transform: 'translateX(-50%)',bottom:'-16%'}} alt="" />
      </div>

      {/* Layout2 */}
      <div className="row m-0 container mx-auto align-items-center pb-5 mb-4" style={{paddingTop:'200px'}}>
        <div className="col-lg-6">
            <img src={require('../../images/Group 1171276131.png')} style={{width:'90%'}} alt="" />
        </div>

        <div className="col-lg-6">
            <p className='online-text fw-bold' style={{lineHeight:'54px'}}>Meet international students & teachers</p>
            <p className='fw-medium'>Caption
Meet international students & teachers
It’s easier than ever before
Morbi sit egestas dignissim pharetra, sed amet. Tempus justo senectus risus ac vel, velit, nunc. Eget commodo eget in aliquam facilisi facilisi nec magna hendrerit. Placerat ipsum sit tellus urna, faucibus aenean lorem faucibus integer.
Explore teachers and students</p>

<p className='d-flex align-items-center fw-bold mt-5' style={{color:'#2563EB',cursor:'pointer'}}>Explore teachers and students<span className='ms-3'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></p>
        </div>
      </div>

      <Benefits_to_student/>

      {/* Layout 4 */}
      <div className="row container px-md-5 align-items-center mx-auto activity-layout4">
        <div className="col-lg-6 ms-auto">
            <p className='fw-bold text-white' style={{fontSize:'60px',lineHeight:'50px'}}>From Beginner to Pro</p>
            <p className='text-white mt-4 pt-3' style={{fontSize:'17px'}}>Egestas fringilla aliquam leo, habitasse arcu varius lorem elit. Neque pellentesque donec et tellus ac varius tortor, bibendum. Nulla felis ac turpis at amet. Purus malesuada placerat arcu at enim elit in accumsan.</p>
            
            <p className='d-flex align-items-center fw-bold mt-5' style={{color:'#ffff',cursor:'pointer'}}>Learn more<span className='ms-2'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></p>
        </div>
      </div>

      {/* Layout 5 */}
      <div className="row m-0 container mx-auto align-items-center mb-4" style={{paddingTop:'100px',paddingBottom:'100px'}}>
        <div className="col-lg-6 pe-md-5">
            <p className='online-text fw-bold' style={{lineHeight:'54px'}}>Meet international students & teachers</p>
            <p className='fw-medium'>Caption
Meet international students & teachers
It’s easier than ever before
Morbi sit egestas dignissim pharetra, sed amet. Tempus justo senectus risus ac vel, velit, nunc. Eget commodo eget in aliquam facilisi facilisi nec magna hendrerit. Placerat ipsum sit tellus urna, faucibus aenean lorem faucibus integer.
Explore teachers and students</p>

<p className='d-flex align-items-center fw-bold mt-5' style={{color:'#2563EB',cursor:'pointer'}}>Explore teachers and students<span className='ms-3'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></p>
        </div>
        <div className="col-lg-6 text-center">
            <img src={require('../../images/Left2.png')} style={{width:'90%'}} alt="" />
        </div>
      </div>

      {/* Layout 6 */}
      <div className="activity-layout6 container mx-auto px-md-5 mb-5">
        <p className="activity-text2 m-0">
        Join Our Classes Today – Discover a New You!
        </p>
        <p className="text-white text-center">
        Egestas fringilla aliquam leo, habitasse arcu varius lorem elit. Neque pellentesque donec et tellus ac varius tortor, bibendum. Nulla felis ac turpis at amet. Purus malesuada placerat arcu at enim elit in accumsan.
        </p>

        <button className='btn btn-primary px-4 mt-4 py-2 d-block mx-auto d-flex align-items-center'>Contact Us<span className='ms-2'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></button>
      </div>

      <div className='bg-white'>
      <What_will_you_be/>
      <Assessment/>
      </div>
      <Blogs/>  
      <Layout8/>
      <div className="bg-white">
      <FAQ/>
      </div>
      <Main_footer/>
      <Backtotop/>
    </div>
  )
}

export default Curricular_activity
