import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const Benefits_to_student = () => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" className='custom-tooltip border rounded'>
          {props}
        </Tooltip>
      );
  return (
    <div>
      <div className='col-lg-9 mx-auto'>
        <p className='fs-1 fw-bold m-0'>Benefit to</p>
        <p className='fs-1 fw-bold m-0' style={{lineHeight:'24px'}}>Join as a Student</p>

        <div className='row m-0 mt-5'>
            <div className="col-md-5 mb-3 mb-md-0">
            <div className='p-2 px-3 about-us shadow' style={{position:'relative'}}>
                <img src={require('../images/page1/Group 1171276112.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Tailored Learning Plans</p>

                <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("Our lessons are designed to meet your unique learning style, ensuring that you grasp concepts at your own pace and excel in your studies.")}
    >
                <svg style={{position:'absolute',right:'8px',top:'8px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="lightgray" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
</svg>
</OverlayTrigger>
            </div>
            </div>
            
            <div className="col-md-5">
            <div className='p-2 px-3 about-us shadow' style={{height:'64px'}}>
                <img src={require('../images/page1/Group 1171276113.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Convenient Session Times</p>
                <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("Flexible scheduling lets you choose the best time for learning, whether it’s morning or evening, making it easy to fit into your day.")}
    >
                <svg style={{position:'absolute',right:'8px',top:'8px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="lightgray" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
</svg>
</OverlayTrigger>
            </div>
            </div>
        </div>

        <div className='row m-0 mt-4 pt-2'>
            <div className="col-md-5 mb-3 mb-md-0">
            <div className='p-2 px-3 about-us shadow'>
                <img src={require('../images/page1/Group 1171276114.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Connect with Subject Experts</p>
                <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("Learn from the best. Our qualified tutors are committed to helping you succeed, explaining things clearly and supporting you all the way.")}
    >
                <svg style={{position:'absolute',right:'8px',top:'8px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="lightgray" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
</svg>
</OverlayTrigger>
            </div>
            </div>
            <div className="col-md-5">
            <div className='p-2 px-3 about-us shadow' style={{height:'64px'}}>
                <img src={require('../images/page1/Group 1171276115.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Focused Tutoring Sessions</p>
                <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("Whether you prefer one-on-one sessions or small group learning, our approach ensures you get the attention you need to thrive academically.")}
    >
                <svg style={{position:'absolute',right:'8px',top:'8px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="lightgray" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
</svg>
</OverlayTrigger>
            </div>
            </div>
        </div>

        <div className='row m-0 mt-4 pt-2'>
            <div className="col-md-4 mb-3 mb-md-0">
            <div className='p-2 px-3 about-us shadow'>
                <img src={require('../images/page1/Group 1171276116.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Beyond Academics</p>
                <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("We don’t just focus on schoolwork. We help you develop skills and offer support that helps you grow personally and academically.")}
    >
                <svg style={{position:'absolute',right:'8px',top:'8px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="lightgray" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
</svg>
</OverlayTrigger>
            </div>
            </div>
            <div className="col-md-7">
            <div className='p-2 px-3 about-us shadow' style={{height:'64px'}}>
                <img src={require('../images/page1/Group 1171276117.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Online, Offline, Hybrid modes</p>
                <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("You get to decide your own learning path. Whether it’s online, in-person, or a combination of both, pick the mode that suits your lifestyle and learning preferences.")}
    >
                <svg style={{position:'absolute',right:'8px',top:'8px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="lightgray" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
</svg>
</OverlayTrigger>
            </div>
            </div>
        </div>

        <div className='row m-0 mt-4 pt-2'>
            <div className="col-md-6 mb-3 mb-md-0">
            <div className='p-2 px-3 about-us shadow'>
                <img src={require('../images/page1/Group 1171276118.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Engaging & Interactive Study Tools</p>
                <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("We use innovative tools and techniques to make learning enjoyable and effective, keeping you engaged and motivated.")}
    >
                <svg style={{position:'absolute',right:'8px',top:'8px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="lightgray" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
</svg>
</OverlayTrigger>
            </div>
            </div>
            <div className="col-md-6">
            <div className='p-2 px-3 about-us shadow' style={{height:'64px'}}>
                <img src={require('../images/page1/Group 1171276119.png')} alt="" />
                <p className='fs-6 ms-3 fw-medium m-0'>Affordable and Accessible</p>
                <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("We offer quality education that’s within reach for everyone with affordable pricing and flexible payment options.")}
    >
                <svg style={{position:'absolute',right:'8px',top:'8px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="lightgray" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
</svg>
</OverlayTrigger>
            </div>
            </div>
        </div>

      </div>
    </div>
  )
}

export default Benefits_to_student
