import React from 'react'

const Work_svg5 = () => {
  return (
    <div>
      <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="72" height="72" rx="36" fill="currentColor"/>
<path d="M35.9997 19.332L52.6663 30.1654M35.9997 19.332L19.333 30.1654M35.9997 19.332V30.1654M52.6663 30.1654V41.832M52.6663 30.1654L35.9997 41.832M52.6663 41.832L35.9997 52.6654M52.6663 41.832L35.9997 30.1654M35.9997 52.6654L19.333 41.832M35.9997 52.6654V41.832M19.333 41.832V30.1654M19.333 41.832L35.9997 30.1654M19.333 30.1654L35.9997 41.832" stroke="#F9F9F9" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_537_2014" x1="15.8327" y1="6.1887" x2="-5.1006" y2="101.791" gradientUnits="userSpaceOnUse">
<stop stop-color="#7C00D9"/>
<stop offset="0.565343" stop-color="#4600B1"/>
<stop offset="1" stop-color="#141778"/>
</linearGradient>
</defs>
</svg>

    </div>
  )
}

export default Work_svg5
