import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import FieldSelector from '../Pages/Filter'
import axios from 'axios'
import { ipaddress } from '../App'
import Tutor_details from './Tutor_details'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'

const View_tutors = () => {
    const[show,setshow]=useState(false)
    const[show1,setshow1]=useState(false)

    const[tutors,settutors]=useState([])
    const[count,setcount]=useState(0)
    const[value,setvalue]=useState(null)
    const[original_data,setoriginal_data]=useState({})

    useEffect(()=>{
        axios.get(`${ipaddress}/tutor/create/`)
        .then(((r)=>{
            console.log(r.data)
            setoriginal_data(r.data)
            settutors(r.data.results)
        }))
    },[count])

    const filter_value=(id)=>{
      const data=tutors.filter(x=>x.id===id)
      // console.log(data);
      
      setvalue(data[0])
    }

    useEffect(()=>{
      if(value!==null){
        setshow1(true)
      }
    },[value])

    const navigate=useNavigate()

    const[api_url,setapi_url]=useState('')

    useEffect(()=>{
      if(api_url!==''){
        axios.get(`${api_url}`)
        .then((r)=>{
          console.log(r.data)
          setoriginal_data(r.data)
          settutors(r.data.results)
        })
        .catch((err)=>{
          console.log("API URL FETCHING ERROR");
          
        })
      }
    },[api_url])

    // Delete Tutor
    const delete_tutor=(id)=>{
      axios.put(`${ipaddress}/tutor/create/`,{
        id:id
      })
      .then(((r)=>{
          setcount(count+1)
          toast.success('Data deleted!',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
          })
      }))
    }

    // Assign tutor for interview
    const[show2,setshow2]=useState(false)
    const[tutor_id,settutor_id]=useState()
    const[emp_email,setemp_email]=useState([''])

    const addEmail=()=>{
      setemp_email([...emp_email,''])
    }

    const updateEmail=(ind,value)=>{
      const data=[...emp_email]
      data[ind]=value
      setemp_email(data)
    }

    const[load,setload]=useState(false)

    const assign=()=>{
      setload(true)
      axios.post(`${ipaddress}/SendTutorViaEmail/`,{
        tutor_id:tutor_id,
        email:emp_email
      })
      .then((r)=>{
        toast.success('Assigned Successfully!',{
          autoClose:1000,
          theme:'colored',
          position:'top-center'
        })
        setload(false)
        setemp_email([''])
        setshow2(false)
      })
    }


  return (
    <div className='d-flex'>
      <Sidebar value={"tutors"}/>
      <div className="w-100">
        <Navbar/>
        <div className="main-container">
            <div className="container">
                <div className='bg-white row m-0 py-3' style={{borderRadius:'10px'}}>
                    <div className="col-md-6">
                      <h5 className='m-0'>Tutors Details</h5>
                    </div>
                    <div className="col-md-6 text-end">
                    <button onClick={()=>{
                        setcount(count+1)
                    }} className='btn text-white btn-sm px-4 fw-medium' style={{backgroundColor:'#3940DB',borderRadius:'10px'}}>All</button>
                    <button onClick={()=>{
                        setshow(true)
                    }} className='btn text-white btn-sm px-4 ms-3' style={{backgroundColor:'#3940DB',borderRadius:'10px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"/>
                  </svg><span className='ms-1 fw-medium'>Filter</span></button>
                    </div>
                </div>

<h6 className={`text-secondary text-center py-5 ${tutors.length>0 ?'d-none':''}`}>No Data Available...🧐</h6>

              <div className={tutors.length>0 ? '':'d-none'}>
              <div className="table-responsive mt-4">
                <table class="shadow-sm table">
  <thead>
    <tr>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2 fw-bold'>SI.No</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2 fw-bold'>Name</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2 fw-bold'>Contact Number</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2 fw-bold'>Whatsapp</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2 fw-bold'>Gender</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2 fw-bold'>Mode</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2 fw-bold'>Location</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 py-2 fw-bold'>Experience</p></th>
      <th className='border-0'></th>
      <th className='border-0'></th>

    </tr>
  </thead>
  <tbody>
   {tutors.map((x,index)=>{
    return(
        <tr className={index%2==0 ? 'table-light':''}>
        <td className='border-0'><p className='text-center m-0 py-2 fw-medium'>{index+1}</p></td>
        <td className='border-0'><p className='text-center m-0 py-2 fw-medium text-primary' style={{cursor:'pointer'}} onClick={()=>{
          filter_value(x.id)
        }}>{x.name}</p></td>
        <td className='border-0'><p className='text-center m-0 py-2 fw-medium'>{x.contact_number}</p></td>
        <td className='border-0'><p className='text-center m-0 py-2 fw-medium'>{x.whatsapp_number}</p></td>
        <td className='border-0'><p className='text-center m-0 py-2 fw-medium'>{x.gender}</p></td>
        <td className='border-0'><p className='text-center m-0 py-2 fw-medium'>{x.mode}</p></td>
        <td className='border-0'><p className='text-center m-0 py-2 fw-medium'>{x.location}</p></td>
        <td className='border-0'><p className='text-center m-0 py-2 fw-medium'>{x.experience}</p></td>
        <td className='border-0'>
          <div className='d-flex py-2 align-items-center justify-content-center'>
            <button className={`btn btn-primary btn-sm ${x.interview && x.interview.length>0 ? 'd-none':''}`} onClick={()=>{
              settutor_id(x.id)
              setshow2(true)
            }}>Assign for Interview</button>

            <p className={`m-0 ${x.interview && x.interview.length>0 ? '':'d-none'}`}><span className='fw-medium text-success'>Verified</span></p>
          </div>
        </td>
        <td className='border-0'>
          <div className='d-flex align-items-center py-2'>
            <img src={require('../images/edit (1) 2 (1).png')} onClick={()=>{
              navigate(`/edit/${x.id}`)
            }} style={{cursor:'pointer'}} width={20} alt="" />
            <img src={require('../images/trash-2 2 (1).png')} style={{cursor:'pointer'}} onClick={()=>{
              delete_tutor(x.id)
            }} className='ms-3' width={20} alt="" />
          </div>
        </td>
      </tr>
    )
   })}
  </tbody>
</table>
                </div>

                <div className='d-flex align-items-center justify-content-between'>
                  <button onClick={()=>{
                    setapi_url(original_data && original_data.previous)
                  }} disabled={original_data.previous==null ? true:false} className='btn btn-sm btn-primary px-3'>Previous</button>
                  <button onClick={()=>{
                    setapi_url(original_data && original_data.next)
                  }} disabled={original_data.next==null ? true:false} className='btn btn-sm btn-primary px-3'>Next</button>
                </div>
              </div>
            </div>
        </div>
      </div>

      {/* Assign tutor */}
      <Modal show={show2} onHide={()=>{
        setshow2(false)
      }}>
        <Modal.Header className='bg-secondary-subtle border-secondary-subtle' closeButton>
          <Modal.Title className='fs-5'>Assign Interview</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{borderRadius:'0px 0px 10px 10px'}} className='bg-secondary-subtle'>
          <div>
            <label htmlFor="" className='fw-medium'>Enter Employee Email Id</label>
            {emp_email.map((x,index)=>{
              return(
                <>
                <input type="email" value={emp_email[index]} onChange={(e)=>{
              updateEmail(index,e.target.value)
            }} className='form-control inputs py-3 mt-2 shadow-none mb-3' name="" id="" />
                </>
              )
            })}

<div className='text-end'>
              <button className='btn btn-primary btn-sm px-4' onClick={addEmail}>
                Add More
              </button>
            </div>

            <div className='text-end'>
              <button className='btn btn-primary px-4 mt-4' onClick={assign}>
                {load ? 'Assigning...':'Assign'}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <FieldSelector show={show} setshow={setshow} settutors={settutors}/>
      <Tutor_details show={show1} setshow={setshow1} value={value}/>
    </div>
  )
}

export default View_tutors
