import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar_component = ({ color, Icon, text, path }) => {
    return (
        <div>
            <Link to={path} class={`nav-link d-flex align-items-center ${color}`} href="#">{<Icon />}
                <span className={`ms-3`}>{text}</span> </Link>
        </div>
    )
}

export default Sidebar_component
