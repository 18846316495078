export const boards=[
    {
        slug:"state-board",
        name:'State Boards',
        para:[
            "Each student's learning journey is unique, and the State Board curriculum is designed to reflect this diversity across India. Our passionate team of tutors is here to offer expert guidance and enthusiastic support throughout this process.",
            "We provide a personalized approach to State Board exams, ensuring that every student gets the tailored assistance they need to succeed. Our tutors are well-versed in the specific requirements of different State Board curriculum, focusing on essential concepts, critical thinking, and problem-solving skills.",
            "Our goal is to make learning both engaging and effective, building students' confidence and helping them master their subjects. With our detailed understanding and customized strategies, we are dedicated to helping students perform at their best and be fully prepared for their State Board exams."
        ],
        head1:"Discover the State Board Advantage",
        paragraph1:"Each state operates its own education system, and State Boards are in charge of conducting exams for classes 10 and 12. This regional approach means that students benefit from a curriculum that is closely aligned with local needs and cultural contexts. For those preparing for state-specific entrance exams, such as engineering and medical tests, the focused curriculum ensures that students are well-prepared in topics that matter most to their future goals.",
        head2:"How We Support State Board Students",
        paragraph2:"We understand that every student learns differently. Our State Board tuition program is customized to address each student's unique needs. Through one-on-one sessions, small group classes, and customized study plans, we cater to various learning styles and paces. This personalized approach helps students grasp complex concepts more easily and perform better in their exams.",
        
        para2:[
            {
                head:"Expert Guidance with Insights",
                para:"Our team of experienced tutors is not only skilled in their respective subjects but also brings valuable insights into the State Board exam patterns and expectations. They bring local insights and practical knowledge to their teaching, helping students navigate the specific requirements and expectations of their respective State Boards. This expertise guarantees that students benefit from a structured curriculum and achieve top results."
            },
            {
                head:"Focused Exam Preparation",
                para:"State Board exams are crucial milestones in a student's academic career. To ensure students are fully prepared, we conduct regular mock tests, provide practice papers, and hold revision sessions. Our goal is to build students’ confidence and provide them with effective exam strategies, so they can give their exams with a positive mindset and achieve excellent results. Ready to excel in your State Board exams? Our dedicated team at Merida Tuition Tutor is here to make your learning experience both exciting and effective. With personalized support, tailored strategies, and a passion for helping you succeed, we’re committed to turning your academic goals into achievements. Let’s make every study session count and set you up for excellence in your State Board exams! "
            }
        ]
    },

    {
        slug:"icse",
        name:'ICSE Board',
        para:[
            "The ICSE board is known for its balanced mix of academics with practical learning.and we at Merida Tuition Tutor are here to guide students through it. We know that the ICSE syllabus can be tough, but with the right help, students can not only pass but excel. Our tutors are here to make learning easy and fun, breaking down hard topics into simple ideas so students can understand and apply them in everyday life.",
            "We focus on helping students grasp concepts, not just memorize them. By encouraging critical thinking and practical application, we prepare them to succeed in exams and in life. We’re committed to building confidence and a solid academic foundation for every student.",
            "Let us guide you through your entire learning journey through the ICSE board with engaging lessons and expert guidance!"
        ],
        head1:"A Personalized Learning Experience",
        paragraph1:"Every student learns at their own pace. That’s why our ICSE tutoring is customized for each child and breaks down complex topics into easy-to-understand concepts. Our tutors provide individual attention, focusing on areas where students need the most help. With small group sessions and personal study plans, we make sure no one is left behind.",
        head2:"Focused Exam Preparation",
        paragraph2:"ICSE exams can be challenging, but we’re here to make sure your child is ready. Our tutors offer regular practice tests, mock exams, and focused revision sessions to help students feel confident when they walk into the exam room. We focus on what’s important and help students tackle even the toughest subjects.",
        
        para2:[
            {
                head:"Experienced Tutors with ICSE Expertise",
                para:"Our team of tutors has years of experience with the ICSE syllabus, offering deep knowledge and insights into both theoretical and practical aspects of the curriculum. They know the exam patterns, the important topics, and how to help students get top marks. They provide valuable guidance through projects, exam techniques, and subject understanding, ensuring students are well-prepared."
            },
            {
                head:"Building Critical Thinking Skills",
                para:"The ICSE curriculum focuses on hands-on learning and problem-solving. We help students develop these skills, encouraging them to ask questions, think critically, and understand concepts deeply. This not only helps them in their exams but also prepares them for future challenges. At Merida Tuition Tutor, we’re committed to making learning enjoyable and effective. Our  team of passionate, experienced tutors, we focus on bringing out the best in every student. We’re here to help students excel in their ICSE exams, and prepare for a bright future. Let’s make every study session count!"
            }
        ]
    },

    {
        slug:"international-baccalaureate",
        name:'IB Board',
        para:[
            "The International Baccalaureate (IB) program is known worldwide for building critical thinkers, creative minds, and well-rounded learners. At Merida Tuition, we get that the IB can be tough, and we’re here to make it easier. Our expert tutors are all about helping students thrive academically while actually enjoying the process. Whether it's mastering tricky subjects or getting ahead with project work, we’ve got students covered.",
            "Our approach? Tailored learning and real-world strategies. We help students not only excel in their IB exams but also build confidence and independent thinking that lasts a lifetime.",
            "Let us support your IB journey with engaging lessons and expert guidance, ensuring a rewarding and effective learning experience."
        ],
        head1:"Personalized Tutoring for Every IB Student",
        paragraph1:"At Merida, we know every student is different. That’s why our IB tutoring is personalized, breaking down complex topics into easy-to-understand concepts. Our tutors provide one-on-one attention, focusing on each student’s specific needs, ensuring that they gain a deep understanding of their subjects.",
        head2:"Effective IB Exam Preparation",
        paragraph2:"IB exams can be intense, but we make sure students are ready. With regular mock tests, targeted practice sessions, and structured revision plans, we make sure students are well-prepared to face the challenges of IB exams. Our focus is on building exam strategies that lead to success in all aspects of the program and help you ace the exams.",
        
        para2:[
            {
                head:"Experienced Tutors with In-Depth Insights",
                para:"Our tutors bring in-depth knowledge of the IB curriculum, guiding students through every aspect of their learning. From essay writing to lab work, we cover both theoretical and practical aspects, giving students the insights they need to excel. Our tutors are familiar with the IB's unique assessment methods, ensuring comprehensive preparation for exams and projects."
            },
            { 
                head:"Building Critical Thinking Skills",
                para:"The IB curriculum is designed to develop independent thinkers and problem-solvers. Our tutors encourage students to explore topics deeply, think critically, and ask the right questions. This approach not only helps in exams but also equips students with skills that will benefit them throughout their academic and professional lives. Join us at Merida Tuition Tutor, where we are committed to helping students excel.With our personalized teaching methods, experienced tutors, and a strong focus on academic excellence, we ensure that every student is prepared to excel in their IB exams and beyond. Let’s turn every study session into an opportunity for growth and achievement!"
            }
        ]
    },

    {
        slug:"cambridge-international-examinations",
        name:'Cambridge International Examinations Board',
        para:[
            "Known for its global recognition and demanding standards, the Cambridge International Examinations (CIE) curriculum prepares students for success at top universities around the world. Our mission is to make this challenging journey engaging and effective, ensuring that every student not only meets but exceeds their academic goals.",
            "With tailored learning plans and a focus on real-world application, we help students master the CIE syllabus and build a solid foundation for their future studies. Our expert tutors are here to guide every step of the way, making each lesson count towards achieving excellence.",
            "Let us guide your CIE journey with engaging lessons and expert support, ensuring a successful and effective learning experience."
        ],
        head1:"Tailored Learning for CIE Success",
        paragraph1:"Each student learns in their own way and has individual needs. Our personalized tutoring approach ensures that complex CIE topics are broken down into manageable concepts .We focus on deep comprehension rather than memorization, ensuring every student gets the support they need to excel.",
        head2:"Focused Exam Preparation",
        paragraph2:"Preparing for CIE exams can be tough, but our tutors are here to make it easier. We provide regular practice tests, focused revision sessions, and mock exams to help students feel confident and prepared. Our strategies are designed to help them tackle difficult subjects and perform well in their exams.",
        
        para2:[
            {
                head:"Experienced CIE Tutors",
                para:"Our tutors bring deep experience with the CIE curriculum, providing in-depth guidance on both theoretical and practical aspects. From crafting essays to conducting lab experiments, they offer essential insights that cover all aspects of the CIE exams. With a strong grasp of CIE exam patterns and requirements, our tutors ensure thorough preparation for both IGCSE and A-levels."
            },
            {
                head:"Encouraging Creative and Critical Thinking",
                para:"The CIE curriculum emphasizes creativity and critical thinking. Our tutors encourage students to dive deeply into subjects, ask questions, and solve problems creatively. This not only enhances their exam performance but also prepares them for future academic and professional challenges. At Merida Tuition Tutor, we’re committed to making your child’s learning journey enjoyable and productive. Our goal is to make each study session count, guiding you to excel in their CIE exams and build a strong foundation for the future. Join us, and let’s make every step of your learning journey rewarding and successful."
            }
        ]
    },
    {
        slug:"cbse",
        name:'CBSE Board',
        para:[
            "The Central Board of Secondary Education (CBSE) is one of India’s most prestigious education boards, managed by the Indian government. As a government-managed board, CBSE provides a structured curriculum with a wide range of subjects, from Science and Math to English and beyond. CBSE ensures a consistent and high-quality education across the country.",
            "At Merida Tuition Tutor, we are dedicated to supporting CBSE students through customized tutoring programs that cater to their unique needs. Our expert tutors focus on building a solid foundation in key subjects, enhancing understanding and confidence. With our personalized approach, we address individual strengths and challenges to help students excel in their studies and prepare effectively for competitive exams.",
            "We are committed to making learning both engaging and impactful. By providing strategic preparation and tailored support, we aim to help every student achieve their academic goals with clarity and confidence."
        ],
        head1:"How We Support CBSE Students",
        paragraph1:"By tailoring our tuition programs to meet the specific needs of CBSE students, our expert tutors offer personalized attention to address each student's unique learning goals. We create a supportive and engaging environment that ensures students grasp key concepts while developing critical thinking and problem-solving skills.",
        head2:"Personalized Support to Achieve Your Best",
        paragraph2:"We believe in personalized tutoring that adapts to each student's learning style. By understanding their strengths and areas to improve, we create customized plans that build confidence and ensure they excel in both classwork and exams. With our guidance, students progress at their own speed, building the skills needed for academic success.",
        
        para2:[
            {
                head:"Expert Tips and Strategies",
                para:"Our experienced tutors provide practical advice on study habits, time management, and exam preparation. We teach students how to approach challenging subjects, optimize their revision sessions, and manage exams effectively. By incorporating these strategies, students can enhance their study routines, reduce stress, and improve overall performance. These tips help students reduce stress, improve performance, and make the most of their study time."
            },
            {
                head:"Strategic Preparation for Exams",
                para:"Merida Tuition Tutor prioritizes thorough exam preparation. We offer practice tests, detailed feedback, and focused revision sessions to ensure students are fully prepared. By familiarizing them with the exam format and teaching effective techniques, we build confidence and improve performance. Our strategic approach addresses specific areas of difficulty, helping students excel in their CBSE exams with a clear understanding and a winning strategy. Join us and experience the difference personalized support can make. With our expert tutors by your side, every study session becomes an opportunity for growth and success as we work together to help your child achieve their best."
            }
        ]
    },
]