import React from 'react'

const Main_svg4 = () => {
  return (
    <div>
      <svg width="41" height="37" viewBox="0 0 41 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2391 13.0264H15.6695V17.4567C15.6695 18.0798 16.2233 18.5643 16.7771 18.5643H21.2075C21.8305 18.5643 22.3151 18.0798 22.3151 17.4567V13.0264H26.7455C27.3685 13.0264 27.8531 12.5418 27.8531 11.9188V7.48838C27.8531 6.86536 27.3685 6.38078 26.7455 6.38078H22.3151V1.9504C22.3151 1.32738 21.8305 0.84281 21.2075 0.84281H16.7771C16.2233 0.84281 15.6695 1.32738 15.6695 1.9504V6.38078H11.2391C10.6853 6.38078 10.1316 6.86536 10.1316 7.48838V11.9188C10.1316 12.5418 10.6853 13.0264 11.2391 13.0264ZM39.552 24.1715C38.9982 23.4793 38.2368 22.9947 37.2676 22.9947C36.6446 22.9947 36.0908 23.2024 35.6755 23.5485L27.3685 29.6403H18.9923C18.4385 29.6403 17.8847 29.1557 17.8847 28.5327C17.8847 27.9789 18.4385 27.4251 18.9923 27.4251H24.4611C25.5687 27.4251 26.5378 26.6636 26.7455 25.6252C26.7455 25.4868 26.7455 25.3483 26.7455 25.2099C26.7455 24.0331 25.7763 22.9947 24.5303 22.9947H13.4543C11.516 22.9947 9.7162 23.687 8.33171 24.8638L5.14737 27.4251H1.27079C0.716995 27.4251 0.163197 27.9789 0.163197 28.5327V35.1783C0.163197 35.8013 0.716995 36.2858 1.27079 36.2858H25.2918C26.8839 36.2858 28.4069 35.8013 29.5837 34.9014L38.929 27.9789C39.6213 27.4943 40.0366 26.6636 40.0366 25.7637C40.0366 25.1407 39.8289 24.5869 39.552 24.1715Z" fill="currentColor"/>
<defs>
<linearGradient id="paint0_linear_467_6603" x1="0.232422" y1="0.855469" x2="0.232422" y2="36.2985" gradientUnits="userSpaceOnUse">
<stop stop-color="#2A2AFF"/>
<stop offset="1" stop-color="#550096"/>
</linearGradient>
</defs>
</svg>

    </div>
  )
}

export default Main_svg4
