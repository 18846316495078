import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ipaddress } from '../App'

const Admin_Loginpage = () => {
    const navigate=useNavigate()

    const[email,setemail]=useState('')
    const[password,setpassword]=useState('')

    const[load,setload]=useState(false)
    const[type,settype]=useState(false)

    const login=()=>{
      setload(true)
      setTimeout(() => {
        send()
      }, 1000);
    }

    const send=()=>{
        axios.post(`${ipaddress}/AdminLogin/`,{
          username:email,
          password:password
        })
        .then((r)=>{
          console.log("Successs",r.data)
          sessionStorage.setItem('user',JSON.stringify(r.data))
          navigate('/tutors')
          setload(false)
          window.location.reload()
        })
        .catch((err)=>{
          console.log("Err",err)
          setload(false)
          toast.error('Invalid Credentials',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
          })
        })
      
    }


  return (
    <div className='animate__animated animate__fadeIn'>
        <div className="m-0 row" style={{height:'100vh'}}>
            <div className="login-div col-md-6 d-flex align-items-center justify-content-center">
                <div>
                    <p className='text-white fw-bolder m-0 text-center' style={{fontSize:'40px'}}>Merida Tech</p>
                    <p className='text-white' style={{fontSize:'17px'}}>The most popular peer to peer leading at SEA</p>
                   
                </div>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className='login'>
                <p className='fw-bolder m-0' style={{fontSize:'28px'}}>Hello Again!</p>
                <p className='' style={{fontSize:'20px'}}>Welcome Back</p>
                </div>
                

                <div className='login'>
                    <div className='w-100'>
                    <div class="input-group mb-3 px-3 rounded-pill py-3 border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="gray" class="bi bi-person-fill" viewBox="0 0 16 16">
  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg></span>
  <input type="text" value={email} onChange={(e)=>{
    setemail(e.target.value)
  }} class="form-control shadow-none bg-transparent border-0" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
                    </div>

                    <div className='w-100 mt-4'>
                    <div class="input-group mb-3 px-3 rounded-pill py-3 border border-secondary-subtle">
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" fill="gray" class="bi bi-lock-fill" viewBox="0 0 16 16">
  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2"/>
</svg></span>
  <input type={type ? 'text':'password'} value={password} onChange={(e)=>{
    setpassword(e.target.value)
  }} class="form-control shadow-none bg-transparent border-0" placeholder="Password" aria-label="Username" aria-describedby="basic-addon1"/>
  <span onClick={()=>{
    settype(!type)
  }} style={{cursor:'pointer'}} class="input-group-text bg-transparent border-0" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="gray" class="bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
</svg></span>
</div>
                    </div>

                    <div>
                        <button className='btn rounded-pill text-white py-3 mt-2 w-100' onClick={()=>{
                            // navigate('/mainpage')
                            login()
                        }} style={{backgroundColor:'#0575E6'}}>
                          <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
  <span className={`${load ? 'ms-2':'d-none'}`} role="status">Loading...</span>
  <span className={`${load ? 'd-none':''}`}>Login</span>

                        </button>
                    </div>
                    <p className='text-center text-secondary mt-3'>Forgot Password</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Admin_Loginpage