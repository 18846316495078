import React from 'react'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import { useNavigate } from 'react-router-dom'
import Navbar from '../Navbar'

const All_blogs = () => {
    const navigate=useNavigate()

  return (
    <div>
      <Navbar/>
      <div className="row m-0 container mx-auto pb-5 pt-4 mt-2">
        <p style={{color:'#666666'}} className='fw-medium text-center'>OUR BLOGS</p>
        <p className='fs-1 fw-bolder text-center text-dark'>Find our all blogs from here</p>
        <p className='text-dark text-center mb-5 pb-5' style={{fontSize:'16px'}}>our blogs are written from very research and well known writers writers so that  we can provide you the best blogs and articles articles for you to read them all along</p>

        {/* Blogs */}
        <div className="col-lg-4 col-md-6 mb-4 pb-3">
        <div class="card border-0">
  <img src={require('../../images/blogs/Container.png')} class="card-img-top blog-img" alt="..."/>
  <div class="card-body px-0 pt-4">
    <p><span style={{fontSize:'13px'}} className='fw-medium'>Travel</span><span style={{fontSize:'13px'}} className='text-secondary ms-3'>24 August 2024</span></p>
    <h5 class="card-title fw-bold" style={{height:'50px'}}>Train Or Bus Journey?Which one suits?</h5>
    <p class="card-text" style={{fontSize:'14px',height:'63px'}}>The choice between a train or bus journey depends on various factors such as the distance of the journey, the time available, the cost, and person </p>
    <a href="" class="fw-bold" onClick={()=>{
        navigate('/view_blog')
    }} style={{color:'#7C4EE4'}}>Read more...</a>
  </div>
</div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4 pb-3">
        <div class="card border-0">
  <img src={require('../../images/blogs/Container (1).png')} class="card-img-top blog-img" alt="..."/>
  <div class="card-body px-0 pt-4">
    <p><span style={{fontSize:'13px'}} className='fw-medium'>Travel</span><span style={{fontSize:'13px'}} className='text-secondary ms-3'>24 August 2024</span></p>
    <h5 class="card-title fw-bold" style={{height:'50px'}}>Train Or Bus Journey?Which one suits?</h5>
    <p class="card-text" style={{fontSize:'14px',height:'63px'}}>The choice between a train or bus journey depends on various factors such as the distance of the journey, the time available, the cost, and person </p>
    <a href="" class="fw-bold" style={{color:'#7C4EE4'}} onClick={()=>{
        navigate('/view_blog')
    }}>Read more...</a>
  </div>
</div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4 pb-3">
        <div class="card border-0">
  <img src={require('../../images/blogs/Container (2).png')} class="card-img-top blog-img" alt="..."/>
  <div class="card-body px-0 pt-4">
    <p><span style={{fontSize:'13px'}} className='fw-medium'>Travel</span><span style={{fontSize:'13px'}} className='text-secondary ms-3'>24 August 2024</span></p>
    <h5 class="card-title fw-bold" style={{height:'50px'}}>Train Or Bus Journey?Which one suits?</h5>
    <p class="card-text" style={{fontSize:'14px',height:'63px'}}>The choice between a train or bus journey depends on various factors such as the distance of the journey, the time available, the cost, and person </p>
    <a href="" class="fw-bold" style={{color:'#7C4EE4'}} onClick={()=>{
        navigate('/view_blog')
    }}>Read more...</a>
  </div>
</div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4 pb-3">
        <div class="card border-0">
  <img src={require('../../images/blogs/Container (3).png')} class="card-img-top blog-img" alt="..."/>
  <div class="card-body px-0 pt-4">
    <p><span style={{fontSize:'13px'}} className='fw-medium'>Travel</span><span style={{fontSize:'13px'}} className='text-secondary ms-3'>24 August 2024</span></p>
    <h5 class="card-title fw-bold" style={{height:'50px'}}>Train Or Bus Journey?Which one suits?</h5>
    <p class="card-text" style={{fontSize:'14px',height:'63px'}}>The choice between a train or bus journey depends on various factors such as the distance of the journey, the time available, the cost, and person </p>
    <a href="" class="fw-bold" style={{color:'#7C4EE4'}} onClick={()=>{
        navigate('/view_blog')
    }}>Read more...</a>
  </div>
</div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4 pb-3">
        <div class="card border-0">
  <img src={require('../../images/blogs/Container (4).png')} class="card-img-top blog-img" alt="..."/>
  <div class="card-body px-0 pt-4">
    <p><span style={{fontSize:'13px'}} className='fw-medium'>Travel</span><span style={{fontSize:'13px'}} className='text-secondary ms-3'>24 August 2024</span></p>
    <h5 class="card-title fw-bold" style={{height:'50px'}}>Train Or Bus Journey?Which one suits?</h5>
    <p class="card-text" style={{fontSize:'14px',height:'63px'}}>The choice between a train or bus journey depends on various factors such as the distance of the journey, the time available, the cost, and person </p>
    <a href="" class="fw-bold" style={{color:'#7C4EE4'}} onClick={()=>{
        navigate('/view_blog')
    }}>Read more...</a>
  </div>
</div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4 pb-3">
        <div class="card border-0">
  <img src={require('../../images/blogs/Container (5).png')} class="card-img-top blog-img" alt="..."/>
  <div class="card-body px-0 pt-4">
    <p><span style={{fontSize:'13px'}} className='fw-medium'>Travel</span><span style={{fontSize:'13px'}} className='text-secondary ms-3'>24 August 2024</span></p>
    <h5 class="card-title fw-bold" style={{height:'50px'}}>Train Or Bus Journey?Which one suits?</h5>
    <p class="card-text" style={{fontSize:'14px',height:'63px'}}>The choice between a train or bus journey depends on various factors such as the distance of the journey, the time available, the cost, and person </p>
    <a href="" class="fw-bold" style={{color:'#7C4EE4'}} onClick={()=>{
        navigate('/view_blog')
    }}>Read more...</a>
  </div>
</div>
        </div>
      </div>
      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
  )
}

export default All_blogs
