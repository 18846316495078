import React from 'react'
import { useNavigate } from 'react-router-dom'
import Blogs from '../Subjects/Blogs'
import Main_footer from '../Footer'
import FAQ from '../Subjects/FAQ'
import Layout8 from '../Subjects/Layout8'
import Navbar from '../Navbar'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import Work_svg from '../How-it-works/works-svgs/Work_svg'
import Work_svg1 from '../How-it-works/works-svgs/Work_svg1'
import Work_svg2 from '../How-it-works/works-svgs/Work_svg2'
import Benefits_to_student from '../Benefits_to_student'
import What_will_you_be from '../What_will_you_be'
import Assessment from '../Assessment'
import { offline } from '../../Contents/Offline'

const Offline = () => {
    const navigate=useNavigate()

  return (
    <div>
      <div className="offline-div">
        <Navbar value={"offline"} color={'#7200D2'}/>
        <div className="row container m-0 mx-auto h-100 mt-4 mt-md-5">
            <div className="col-lg-6 d-flex flex-column justify-content-start pt-4">
            <div className="">
                <p className='offline-text text-white'>Manage your</p>
                <p className='offline-text text-white'>remote team work</p>
                <p className='text-white mt-5' style={{lineHeight:'30px'}}>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor. Proin eget nibh accumsan, placerat erat sit amet, pellentesque nisi.</p>

                <div className='mt-5'>
                    <button className='btn text-white fw-bold px-4 py-2 offline-btn' style={{backgroundColor:'#F74D4D',border:'1px solid #F74D4D'}} onClick={()=>{
                        navigate('/add_tutor')
                    }}>Explore more</button>
                </div>
            </div>

            </div>
        </div>
      </div>

{/* Layout2 */}
      <div className="row m-0 container mx-auto py-5 my-4">
        <p className='fs-1 fw-bolder text-center'>Digital currency is our expertise</p>
        <p className=' text-center mb-5 pb-4'>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor. Proin eget nibh accumsan, placerat erat sit amet, pellentesque nisi.</p>


        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Converts well, receive more</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg1/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Transactions made easy with app use</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg2/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>The most secured currency ever</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        
      </div>
    
{/* Layout3 */}
<div className="board-subdiv2">
      <div className="row m-0 container mx-auto py-5">
        <p className='fs-1 fw-bold text-center'>Discover the Joy of Offline Education</p>

        <div className='mt-4'>
          {offline.para && offline.para.map((x)=>{
            return(
              <>
              <p className='text-center mb-4 fw-bold fs-4'>{x.head}</p>
              <p className='text-center mb-4'>{x.para}</p>
              </>
            )
          })}
          </div>
      </div>
      </div>

      <div className="row m-0 container mx-auto py-5 my-4">
        <Benefits_to_student/>
      </div>

{/* Layout4 */}
<div className='py-5' style={{backgroundColor:'#f7f9ff'}}>
      <div className="row m-0 container mx-auto align-items-center">
<div className="col-md-7 text-center">
<img src={require('../../images/Rectangle 233.png')} style={{width:'70%'}} alt="" />
</div>
<div className="col-lg-5 ms-auto">
            <p className='fs-3 fw-bolder m-0'>Best offline classes in Bangalore</p>
            <p className='text-secondary mt-2' style={{fontSize:'14px'}}>Maecenas sed elementum ligula, non posuere metus. Aliquam bibendum mi egestas mi tempus hendrerit vehicula pretium sem, porta suscipit erat maximum faucibus vel</p>

            <div className="row m-0">
                <div className="col-md-4 ps-md-0">
                    <div>
                        <p style={{color:'#8236C8'}} className='fw-bolder fs-3 m-0'>200K+</p>
                        <p style={{fontSize:'14px'}}>Token launches</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div>
                        <p style={{color:'#8236C8'}} className='fw-bolder fs-3 m-0'>100%</p>
                        <p style={{fontSize:'14px'}}>Instant Buy-In</p>
                    </div>
                </div>
                <div className="col-md-4 pe-md-0">
                    <div>
                        <p style={{color:'#8236C8'}} className='fw-bolder fs-3 m-0'>24/7</p>
                        <p style={{fontSize:'14px'}}>Market Places</p>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <div className='d-flex align-items-start'>
                <p>  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
</svg></p>

<div className='ms-3'>
    <p className='fw-bold mb-2 m-0'>Solid variety of altcoin choices:</p>
    <p className='text-secondary' style={{fontSize:'13px'}}>Aliquam bibendum mi egestas mi tempus hendrerit lorem ipsum dolor </p>
</div>
                </div>
                <div className='d-flex align-items-start'>
              <p>  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
</svg></p>

<div className='ms-3'>
    <p className='fw-bold mb-2 m-0'>Extremely simple user interface:</p>
    <p className='text-secondary' style={{fontSize:'13px'}}>Elementum ligula, non posuere metus. Aliquam bibendum mi egestas mi tempus hendrerit Elementum ligula, non posuere metus. Aliquam bibendum mi egestas mi tempus hendreritElementum ligula, non posuere metus. Aliquam bibendum mi egestas mi tempus hendrerit</p>
</div>
                </div>
                <div className='d-flex align-items-start'>
                <p>  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
</svg></p>

<div className='ms-3'>
    <p className='fw-bold mb-2 m-0'>Extremely simple user interface:</p>
    <p className='text-secondary' style={{fontSize:'13px'}}>Elementum ligula, non posuere metus. Aliquam bibendum mi egestas mi tempus hendrerit Elementum ligula, non posuere metus. Aliquam bibendum mi egestas mi tempus hendreritElementum ligula, non posuere metus. Aliquam bibendum mi egestas mi tempus hendrerit</p>
</div>
                </div>
                
            </div>
        </div>
      </div>
      </div>

{/* Layout5 */}
<div className="row m-0 container mx-auto py-5 align-items-center">
<p className='fs-1 fw-bold text-center' style={{lineHeight:'48px'}}>Why you should choose us?</p>
  <p className='text-secondary mb-5 text-center'>With lots of unique blocks, you can easily build a page without coding. Build your next landing page.</p>
<div className="col-md-6 pe-md-5">
  <img src={require('../../images/page1/Group 114.png')} className='d-block mx-auto' style={{width:'80%'}} alt="" />
</div>

<div className="col-md-6 ps-md-5">
  <div className='d-flex align-items-start'>
    <p className='rounded-circle fw-bold d-flex align-items-center justify-content-center' style={{color:'#473BF0',backgroundColor:'#ecebfd',height:'40px',width:'46px'}}>1</p>
    <div className='ms-4'>
      <p className='m-0 fw-bold fs-5'>Easy Booking</p>
      <p className='mt-3'>With lots of unique blocks, you can easily build a page without coding. </p>
    </div>
  </div>
  <div className='d-flex align-items-start mt-4'>
  <p className='rounded-circle fw-bold d-flex align-items-center justify-content-center' style={{color:'#473BF0',backgroundColor:'#ecebfd',height:'40px',width:'46px'}}>2</p>
    
    <div className='ms-4'>
      <p className='m-0 fw-bold fs-5'>Free Expert Opinion</p>
      <p className='mt-3'>With lots of unique blocks, you can easily build a page without coding. </p>
    </div>
  </div>
  <div className='d-flex align-items-start mt-4'>
  <p className='rounded-circle fw-bold d-flex align-items-center justify-content-center' style={{color:'#473BF0',backgroundColor:'#ecebfd',height:'40px',width:'46px'}}>3</p>

    <div className='ms-4'>
      <p className='m-0 fw-bold fs-5'>Get Your Results</p>
      <p className='mt-3'>With lots of unique blocks, you can easily build a page without coding. </p>
    </div>
  </div>
</div>
      </div>

<What_will_you_be/>
<Assessment/>
<Blogs/>
<Layout8/>
<FAQ/>
<Main_footer/>
<Backtotop/>
<Scrolltotop/>
    </div>
  )
}

export default Offline
