import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Work_svg from './works-svgs/Work_svg'
import Work_svg1 from './works-svgs/Work_svg1'
import Work_svg2 from './works-svgs/Work_svg2'
import Work_svg3 from './works-svgs/Work_svg3'
import Work_svg4 from './works-svgs/Work_svg4'
import Work_svg5 from './works-svgs/Work_svg5'
import Blogs from '../Subjects/Blogs'
import Main_footer from '../Footer'
import FAQ from '../Subjects/FAQ'
import Layout8 from '../Subjects/Layout8'
import Navbar from '../Navbar'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import { Modal } from 'react-bootstrap'

const How_it_works = () => {
    const navigate=useNavigate()

    const[show,setshow]=useState(false)

  return (
    <div className='animate__animated animate__fadeIn'>
      <div className="work-div">
        <Navbar value={"work"} color={'#7200D2'}/>
        <div className="row container m-0 mx-auto h-100 mt-4">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
            <div className="">
                <p className='work-text text-white'>Reshaping Careers</p>
                <p className='work-text text-white'>With Hassle-Free</p>
                <p className='work-text text-white'>Tution Services</p>
                <p className='text-white mt-5'>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor. Proin eget nibh accumsan, placerat erat sit amet, pellentesque nisi.</p>

                <div className='mt-5'>
                    <button className='btn btn-light rounded-pill fw-bold px-4 py-2'>Become a Student</button>
                    <button className='btn text-white border rounded-pill fw-bold px-4 py-2 ms-3' onClick={()=>{
                        navigate('/add_tutor')
                    }}>Become a Teacher</button>
                </div>
            </div>

            <div className="row m-0 mt-5">
                <div className="col-md-4">
                    <div>
                        <p className='fs-2 fw-bolder text-white m-0'>523K+</p>
                        <p className='text-white'>Total Customer</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div>
                        <p className='fs-2 fw-bolder text-white m-0'>200K+</p>
                        <p className='text-white'>Real time Customer</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div>
                        <p className='fs-2 fw-bolder text-white m-0'>100%</p>
                        <p className='text-white'>Customer Satisfaction</p>
                    </div>
                </div>
            </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-end">
                <img src={require('../../images/WORK/Image Placeholder.png')} style={{width:'78%'}} alt="" />
            </div>
        </div>
      </div>
      <div className="work-div2">
        <img src={require('../../images/WORK/Vector 32 (1).png')} style={{width:'100%',height:'90%'}} alt="" />
      </div>

      <div className='container'>
      <p className='fs-1 fw-bolder text-center'>About Us</p>
      <p className='text-center'>At Merida Tuition Tutor, we believe in making learning a joyous and rewarding experience. Our team of top-rated tutors is dedicated to helping students excel in all areas of study. From school subjects like math, physics to advanced programming and languages, we are here to support your academic and personal growth.</p>
      <p className='text-center'>We understand that every student has unique learning needs, that’s why we offer flexible tutoring options. Whether you prefer in-person sessions at our office, at a tutor’s location, or even at your home, we make learning accessible and convenient. In addition to core subjects, we also offer a range of co-curricular activities, including language courses, all designed to enrich your learning experience.</p>
      <p className='text-center'>To ensure that our students grasp and retain knowledge effectively, we follow a carefully structured syllabus aligned with various educational boards. Whether it’s State board, ICSE, CBSE, IB, or any other board, our curriculum is designed to meet diverse learning needs. We also provide online and hybrid learning options, with regular assessments to track progress and strengthen your understanding.</p>
      <p className='text-center'>With a commitment to creating a lively and supportive learning environment, we aim to help every student grow and achieve their best.</p>
      </div>

      <div className="row m-0 container mx-auto py-5">
        <div className="col-lg-6">
            <img src={require('../../images/student/Group 1171276064.png')} style={{width:'70%'}} alt="" />
        </div>
        <div className="col-lg-6">
        <p className='fw-bold fs-5 mt-5'>Our Vision</p>
      <p>Our vision is to inspire every student to embrace learning with enthusiasm, guiding them to excel and build a promising future.</p>
      <p className='fw-bold fs-5 mt-4'>Our Mission</p>
      <p>Our mission is to deliver tailored, high-quality tutoring that meets each student’s individual needs, enabling them to excel both academically and personally with genuine enthusiasm and support.</p>
      <p className='fw-bold fs-5 mt-4'>Our Values</p>
      <p>At Merida Tuition Tutor, we are committed to excellence, flexibility, integrity, and dedication. We build a welcoming and uplifting environment where each student feels appreciated, supported, and inspired to achieve their academic goals.</p>
        </div>
      </div>


{/* Layout2 */}
      <div className="row m-0 container mx-auto py-5 my-4">
        <p className='fs-1 fw-bolder text-center'>Why Perfect Tutor?</p>
        <p className=' text-center mb-5 pb-4'>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor. Proin eget nibh accumsan, placerat erat sit amet, pellentesque nisi.</p>


        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>English</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg1/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Tamil</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg2/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>German</p>
                <p className='mt-4 text-center'>Aenean varius ultricies dapibus. Vivamus et tortor et libero cursus facilisis et a tortor. Etiam eleifend eleifend</p>

            </div>
        </div>
        
      </div>
    
{/* Layout3 */}
<div className="work-div3 py-5" style={{backgroundColor:'#EDF1FD'}}>
    <div className="row m-0 py-4 container mx-auto justify-content-center">
        <div className="col-lg-5">
            <p className='fs-2 fw-bolder'>How We work ?</p>
            <p className='m-0' style={{color:'#4F4F4F'}}>For Student,</p>
            <p className='m-0 mt-3' style={{color:'#4F4F4F'}}>We make learning enjoyable and effective by tailoring our approach to fit each student's unique needs. Starting with a detailed assessment to understand your strengths and areas for improvement, we create a personalized learning plan.</p>
            <p className='m-0 mt-3' style={{color:'#4F4F4F'}}>Our process includes continuous feedback, progress tracking, and regular updates to ensure that students and parents are always informed. By concentrating on clear objectives and broadening skills through engaging workshops, we aim to make every learning journey both impactful and rewarding.</p>

            <div className='mt-5 mb-4 pb-1'>
                <p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Personalized learning plans for each student</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Real-time progress tracking and updates</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Weekly reviews and goal-setting sessions</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Regular parent-tutor conferences</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Supplementary workshops for broader skills</span></p>
            </div>

            {/* <button className='mt-4 btn text-white px-4 py-2 rounded-pill d-flex align-items-center justify-content-center' style={{backgroundColor:'#184AC9',fontSize:'14px'}}>Learn More <span className='ms-1'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></button> */}

            
        </div>
        <div className="col-lg-6 text-end">
            <img src={require('../../images/WORK/Image Placeholder (1).png')} style={{width:'80%'}} alt="" />
        </div>
    </div>
</div>

{/* Layout4 */}
<div className="work-div3 py-5" style={{backgroundColor:'#F9F9F9'}}>
    <div className="row m-0 py-4 container mx-auto justify-content-center">
        <div className="col-lg-6 text-start">
            <img src={require('../../images/WORK/Image Placeholder (2).png')} style={{width:'80%'}} alt="" />
        </div>
        <div className="col-lg-5">
            <p className='fs-2 fw-bolder'>How We work ?</p>
            <p className='m-0' style={{color:'#4F4F4F'}}>For Schools or Colleges,</p>
            <p className='m-0 mt-3' style={{color:'#4F4F4F'}}>Partnering with schools and colleges, MeridaTuitionTutor enhances educational outcomes with customized support. Our approach begins with a thorough evaluation of institutional needs, followed by creating custom programs that align with your educational goals. We use advanced tools to track and report progress, ensure ongoing communication, and adapt strategies based on feedback. By prioritizing academic success, the goal is to enhance the learning experience through thoughtfully designed workshops and activities.</p>

            <div className='mt-5 mb-4 pb-1'>
                <p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Custom programs to meet institutional needs</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Advanced tools for progress tracking</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Ongoing communication and feedback</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Tailored strategies for academic success</span></p>
<p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#184AC9" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='ms-3'>Engaging workshops to enhance learning</span></p>
            </div>

            {/* <button className='mt-4 btn text-white px-4 py-2 rounded-pill d-flex align-items-center justify-content-center' style={{backgroundColor:'#184AC9',fontSize:'14px'}}>Learn More <span className='ms-1'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></button> */}

            
        </div>
    </div>
</div>

{/* Layout4 */}
<div className='work-layout4' style={{paddingTop:'60px',paddingBottom:'70px'}}>
            
            <div className="row m-0 mt-4 container mx-auto">
                <div className="col-md-6 p-4 ps-md-0 d-flex align-items-center">
                    <div className=''>
                       <p className='fw-bolder fs-1 m-0'>What Are</p>
                       <p className='fw-bolder fs-1 m-0'>You</p>
                       <p className='fw-bolder fs-1 m-0'>Looking </p>
                       <p className='fw-bolder fs-1 m-0'>For?</p>

                       <p className='text-secondary mt-3' style={{fontSize:'14px'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                       </div>
                </div>

                <div className="col-md-6">
                    <div className="row m-0">
                        <div className="col-md-6 mt-5">
                        <div className='bg-white p-4 shadow' style={{height:'310px',borderRadius:'10px'}}>
                        <div className=''>
                        <p className='icons'><Work_svg1/></p>

                            <p className='fw-medium m-0 mt-3' style={{fontSize:'20px'}}>I'm a Tutor</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>

                        <button onClick={()=>{
                            setshow(true)
                        }} style={{backgroundColor:'#4600B1'}} className='btn btn-sm text-white px-3 rounded-pill'>Regsiter</button>
                    </div>
                        </div>

                        <div className="col-md-6">
                        <div className='bg-white p-4 shadow' style={{height:'310px',borderRadius:'10px'}}>
                        <div className=''>
                        <p className="icons"><Work_svg/></p>

                            <p className='fw-medium m-0' style={{fontSize:'20px'}}>I'm a Student</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>

                        <button onClick={()=>{
                            setshow(true)
                        }} style={{backgroundColor:'#4600B1'}} className='btn btn-sm text-white px-3 rounded-pill'>Regsiter</button>
                    </div>
                        </div>

                        <div className="col-md-6 mt-5">
                        <div className='bg-white p-4 shadow' style={{height:'310px',borderRadius:'10px'}}>
                        <div className=''>
                        <p className="icons"><Work_svg2/></p>
                            <p className='fw-medium m-0 mt-3' style={{fontSize:'20px'}}>I'm a School or College</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>

                        <button onClick={()=>{
                            setshow(true)
                        }} style={{backgroundColor:'#4600B1'}} className='btn btn-sm text-white px-3 rounded-pill'>Regsiter</button>
                    </div>
                        </div>

                        <div className="col-md-6">
                        <div className='bg-white p-4 shadow' style={{height:'310px',borderRadius:'10px'}}>
                        <div className=''>
                        <p className="icons"><Work_svg5/></p>

                            <p className='fw-medium m-0 mt-3' style={{fontSize:'20px'}}>I'm a Private Institute</p>
                        </div>
                        <p style={{color:'#9497A1',fontSize:'14px'}} className='mt-3'>At vero eos et accusamus et iusto odio dignissimos duciu quili blandit praesentium voluptatum ipsa quae ab illo.</p>

                        <button onClick={()=>{
                            setshow(true)
                        }} style={{backgroundColor:'#4600B1'}} className='btn btn-sm text-white px-3 rounded-pill'>Regsiter</button>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<Blogs/>
<Layout8/>
<FAQ/>
<Main_footer/>
<Backtotop/>
<Scrolltotop/>

{/* Form Modal */}
<Modal centered show={show} onHide={()=>{
    setshow(false)
}}>
        <Modal.Body className='how-it-works-form rounded py-5'>
            <div>
                <div className=''>
                <p className='fs-2 mb-4 fw-bold text-center' style={{color:'#FFCB24'}}>Join to the best Classes</p>
                </div>
                <div className='pt-2'>
                    <input type="text" placeholder='Name' className='form-control shadow-none border-white py-3 mt-2 mb-4' name="" id="" />

                    <input type="text" placeholder='Email' className='form-control shadow-none border-white py-3 mt-2 mb-4' name="" id="" />

                    <input type="text" placeholder='Contact Number' className='form-control shadow-none border-white py-3 mt-2 mb-4' name="" id="" />

                    <textarea type="text" placeholder='Message...' className='form-control shadow-none border-white py-3 mt-2 mb-4' name="" id="" />

                    <div>
                    <button className='btn text-dark fw-bold w-100 py-3 d-flex align-items-center justify-content-center' style={{backgroundColor:'#FCC106'}}>Get Started 
                        <span className='ms-1'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></span>
                    </button>
                    </div>
                </div>
            </div>
        </Modal.Body>
       
      </Modal>
    </div>
  )
}

export default How_it_works
