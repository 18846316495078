import React from "react"
import Main from "./Main"
import './main.css'
import './subject.css'
import './online.css'
import './language.css'
import './works.css'
import './board.css'
import './event.css'
import './activity.css'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Add_tutor from "./Pages/Add_tutor"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FieldSelector from "./Pages/Filter"
import Admin_dashboard from "./Admin_panel/Admin_dashboard"
import Homepage from "./Pages/Homepage"
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import View_tutors from "./Admin_panel/View_tutors"
import Edit_tutor from "./Pages/Edit_tutor"
import Main_page from "./Landing_pages/Main_page"
import Subject_page from "./Landing_pages/Subjects/Subject_page"
import Lnguage_page from "./Landing_pages/Languages/Lnguage_page"
import Online_page from "./Landing_pages/Online/Online_page"
import Boards_page from "./Landing_pages/Boards/Boards_page"
import All_blogs from "./Landing_pages/Subjects/All_blogs"
import View_blog from "./Landing_pages/Subjects/View_blog"
import Contact from "./Landing_pages/Subjects/Contact"
import Events from "./Landing_pages/Events/Events"
import How_it_works from "./Landing_pages/How-it-works/How_it_works"
import Offline from "./Landing_pages/Online/Offline"
import Admin_Loginpage from "./Admin_panel/Loginpage"
import Protect from "./Admin_panel/Protect"
import All_subjects from "./Landing_pages/Subjects/All_subjects"
import Interview_form from "./Pages/Interview_form"
import All_boards from "./Landing_pages/Boards/All_boards"
import All_languages from "./Landing_pages/Languages/All_languages"
import Curricular_activity from "./Landing_pages/Activities/Curricular_activity"

export const ipaddress='https://backendapi.meridatuitiontutor.com/api'
// export const ipaddress='http://192.168.18.11:9000/api'

const App=()=>{
    return(
        <div>
        <BrowserRouter>
        <ToastContainer/>
        <Routes>
        <Route path="/" element={<Main_page/>}/>
            <Route path="/add_tutor" element={<Add_tutor/>}/>
            <Route path="/filter" element={<FieldSelector/>}/>

            {/* Admin */}
            <Route path="/admin_login" element={<Admin_Loginpage/>}/>
            <Route path="/admin_dashboard" element={<Admin_dashboard/>}/>
            <Route path="/tutors" element={<Protect Child={View_tutors}/>}/>
            <Route path="/edit/:id" element={<Edit_tutor/>}/>
            <Route path="/interview_form/:id" element={<Interview_form/>}/>

            {/* Subjects Page */}
            <Route path="/subjects" element={<All_subjects/>}/>
            <Route path="/subject" element={<Subject_page/>}/>
            <Route path="/languages" element={<All_languages/>}/>
            <Route path="/language/:slug" element={<Lnguage_page/>}/>
            <Route path="/online" element={<Online_page/>}/>
            <Route path="/offline" element={<Offline/>}/>
            <Route path="/boards" element={<All_boards/>}/>
            <Route path="/board/:slug" element={<Boards_page/>}/>

            <Route path="/curricular_activity" element={<Curricular_activity/>}/>

            <Route path="/blogs" element={<All_blogs/>}/>
            <Route path="/view_blog" element={<View_blog/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/events" element={<Events/>}/>
            <Route path="/how-it-works" element={<How_it_works/>}/>

        </Routes>
        </BrowserRouter>
        </div>
    )
}
export default App