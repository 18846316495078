import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Navbar = ({value,color}) => {
  const navigate=useNavigate()

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 82) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='pb-3'>
      <div className="d-lg-inline d-none">
      <div className='row m-0 container mx-auto pt-4 align-items-center justify-content-between'>
        <div className="col-4 d-flex align-items-center">
            <img src={require('../images/Logo_Blue 1.png')} className={value==='home' || value==="board" || value==="language" || value==="offline" || value==='events' || value==="work" || value==="activity" ? '':'d-none'} style={{cursor:'pointer'}} onClick={()=>{
              navigate('/')
            }} width={140} alt="" />
             <img src={require('../images/Logo_Blue.png')} className={value==='home' || value=="board" || value==="language" || value==="offline" || value==="work" || value==='events' || value==="activity" ? 'd-none':''} style={{cursor:'pointer'}} onClick={()=>{
              navigate('/')
            }} width={140} alt="" />
        </div>
        <div className='col-8 ms-auto d-flex justify-content-between align-items-center'>
            <span className={`nav1 ${value==='home' || value==='board' || value==='events' || value==="work" || value==="language" || value==="offline" || value==="activity" ? 'text-white':'text-dark'} ${value==="subject" ? 'border-bottom border-dark border-3':''}`} onClick={()=>{
              navigate('/subject')
            }}>Subjects</span>
             <span className={`nav1 ${value==='home' || value==='board' || value==='events' || value==="work" || value==="language" || value==="offline" || value==="activity" ? 'text-white':'text-dark'} ${value==="language" ? 'border-bottom border-white border-3':''} ${value==="languages" ? 'border-bottom border-dark border-3':''}`} onClick={()=>{
              navigate('/languages')
            }}>Languages</span>
            <span className={`nav1 ${value==='home' || value==='board' || value==='events' || value==="work" || value==="language" || value==="offline" || value==="activity" ? 'text-white':'text-dark'} ${value==="board" ? 'border-bottom border-white border-3':''} ${value==="boards" ? 'border-bottom border-dark border-3':''}`}  onClick={()=>{
              navigate('/boards')
            }}>Boards</span>
            <span className={`nav1 ${value==='home' || value==='board' || value==='events' || value==="work" || value==="language" || value==="offline" || value==="activity" ? 'text-white':'text-dark'} ${value==="activity" ? 'border-bottom border-white border-3':''}`} onClick={()=>{
              navigate('/curricular_activity')
            }}>Activities</span>

<div class="dropdown">
  <button class={`btn border-0 nav1 ${value==='home' || value==='board' || value==='events' || value==="language" || value==="work" || value==="offline" || value==="activity" ? 'text-white':'text-dark'}`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Mode of Training
  </button>
  <ul class="dropdown-menu navbar-drop">
    <li><p class="dropdown-item nav-li bg-transparent m-0 d-flex align-items-center justify-content-between" onClick={()=>{
      navigate('/online')
    }}><span>Online Mode</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
  </svg></p></li>
    <li><p class="dropdown-item nav-li bg-transparent m-0 d-flex align-items-center justify-content-between" onClick={()=>{
      navigate('/offline')
    }}><span>Offline Mode</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
  </svg></p></li>
  </ul>
</div>

<span onClick={()=>{
              navigate('/how-it-works')
            }} className={`nav1 ${value==='home' || value==='board' || value==='events' || value==="work" || value==="language" || value==="offline" || value==='activity' ? 'text-white':'text-dark'} ${value==="work" ? 'border-bottom border-white border-3':''}`}>How It Works</span>
            
           </div>
      </div>
      </div>
    </div>
  )
}

export default Navbar
