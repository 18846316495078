import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ipaddress } from '../App'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'

const Interview_form = () => {

    const {id}=useParams()

    useEffect(()=>{
        axios.get(`${ipaddress}/tutor/create/?tutor_id=${id}`)
        .then((r)=>{
            console.log("Data",r.data)
            setfields(r.data)
            const subjects=r.data.subjects.map((x)=>x.id)
            setSelectedSubjects(subjects)

            const languages=r.data.languages.map((x)=>x.id)
            setSelectedlanguages(languages)

            const boards=r.data.boards.map((x)=>x.id)
            setSelectedboards(boards)

            const standards=r.data.standards.map((x)=>x.id)
            setSelectedstandards(standards)

            setSelecteddays(r.data.avaliable_days)

            setstate(r.data.come_to_office)
            setstate2(r.data.train_in_customer_location)
            seterror_state(false)
        })
        .catch((err)=>{
            if(err.response && err.response.status===401){
                seterror_state(true)
            }
        })
    },[])

    const[fields,setfields]=useState({
        name:'',
        contact_number:'',
        whatsapp_number:'',
        gender:'',
        address:'',
        location:'',
        mode:'',
        experience:'',
        address_proof:null,
        id_proof:null,
        resume:null,
        charges:'',
        fees:'',
        areas:'',
        come_to_office:0,
        train_in_customer_location:0
    })
    const [openState, setOpenState] = useState({
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      open6: false,
      open7: false,

    });
  
    const toggleDiv = (divName) => {
      setOpenState((prevState) => ({
        open1: divName === 'open1' ? !prevState.open1 : false,
        open2: divName === 'open2' ? !prevState.open2 : false,
        open3: divName === 'open3' ? !prevState.open3 : false,
        open4: divName === 'open4' ? !prevState.open4 : false,
        open5: divName === 'open5' ? !prevState.open5 : false,
        open6: divName === 'open6' ? !prevState.open6 : false,
        open7: divName === 'open7' ? !prevState.open7 : false,
      }));
    };

    const[areas,setareas]=useState([])
    const[languages,setlanguages]=useState([])
    const[days,setdays]=useState([])
    const[experiences,setexperiences]=useState([])
    const[subjects,setsubjects]=useState([])
    const[standards,setstandards]=useState([])
    const[timings,settimings]=useState([])
    const[boards,setboards]=useState([])


    useEffect(()=>{
      axios.get(`${ipaddress}/AllDataAPIView/`)
      .then((r)=>{
        console.log(r.data);
        setsubjects(r.data.subjects)
        setboards(r.data.boards)
        settimings(r.data.timings)
        setdays(r.data.days)
        setstandards(r.data.standards)
        setexperiences(r.data.experiences)
        setlanguages(r.data.languages)
        setareas(r.data.areas)

      })
    },[])

    const handleinputchange=(e)=>{
        const {name,value}=e.target

        setfields((prev)=>({
            ...prev,
            [name]:value
        }))
    }

    const handlefileinputchange = (e) => {
      const { name, value, files } = e.target;
      setfields((prev) => ({
        ...prev,
        [name]: files ? files[0] : value,
      }));
    };

    // Subject
    const [selectedSubjects, setSelectedSubjects] = useState([]);

    const subject_value = (id) => {
      setSelectedSubjects((prevSubjects) => {
        if (prevSubjects.includes(id)) {
          return prevSubjects.filter(subjectId => subjectId !== id);
        } else {
          return [...prevSubjects, id];
        }
      });
    };

    // Boards
    const [selectedboards, setSelectedboards] = useState([]);

    const board_value = (id) => {
      setSelectedboards((prevSubjects) => {
        if (prevSubjects.includes(id)) {
          return prevSubjects.filter(subjectId => subjectId !== id);
        } else {
          return [...prevSubjects, id];
        }
      });
    };

    // Standards
    const [selectedstandards, setSelectedstandards] = useState([]);

    const standard_value = (id) => {
      setSelectedstandards((prevSubjects) => {
        if (prevSubjects.includes(id)) {
          return prevSubjects.filter(subjectId => subjectId !== id);
        } else {
          return [...prevSubjects, id];
        }
      });
    };

    // Areas
    const [selectedareas, setSelectedareas] = useState([])
    const area_value = (id) => {
      setSelectedareas((prevSubjects) => {
        if (prevSubjects.includes(id)) {
          return prevSubjects.filter(subjectId => subjectId !== id);
        } else {
          return [...prevSubjects, id];
        }
      });
    };

    // Days
    const [selecteddays, setSelecteddays] = useState([]);

    const day_value = (id) => {
      setSelecteddays((prevSubjects) => {
        if (prevSubjects.includes(id)) {
          return prevSubjects.filter(subjectId => subjectId !== id);
        } else {
          return [...prevSubjects, id];
        }
      });
    };

    // Timings
    const [selectedtimings, setSelectedtimings] = useState([]);

    const timing_value = (id) => {
      setSelectedtimings((prevSubjects) => {
        if (prevSubjects.includes(id)) {
          return prevSubjects.filter(subjectId => subjectId !== id);
        } else {
          return [...prevSubjects, id];
        }
      });
    };

    // Languages
    const [selectedlanguages, setSelectedlanguages] = useState([]);

    const language_value = (id) => {
      setSelectedlanguages((prevSubjects) => {
        if (prevSubjects.includes(id)) {
          return prevSubjects.filter(subjectId => subjectId !== id);
        } else {
          return [...prevSubjects, id];
        }
      });
    };

    // Locations
    const [selectedlocations, setSelectedlocations] = useState([]);

    const location_value = (id) => {
      setSelectedlocations((prevSubjects) => {
        if (prevSubjects.includes(id)) {
          return prevSubjects.filter(subjectId => subjectId !== id);
        } else {
          return [...prevSubjects, id];
        }
      });
    };

    const navigate=useNavigate()
    const[load,setload]=useState(false)

    const submit=()=>{
      setload(true)
      const transformedData = transformTimeFormat(codingQuestions);

      console.log(transformedData)

      const data=new Date()
      const today_date=data.toISOString().slice(0,10)
      console.log(today_date)
      
      
      const form=new FormData()
      form.append('name',fields.name)
      form.append('contact_number',fields.contact_number)
      form.append('whatsapp_number',fields.whatsapp_number)
      form.append('address',fields.address)
      form.append('charges',fields.charges)
      form.append('fees',fields.fees)
      form.append('experience',fields.experience)
      form.append('mode',fields.mode)
      form.append('location',fields.location)
      form.append('gender',fields.gender)
      form.append('feedback',remarks)
      form.append('selected_status',status)
      form.append('today_date',today_date)

      if(verified_status==true){
        form.append('verified',1)
      }
      if(verified_status==false){
        form.append('verified',0)
      }

      if(state==true){
        form.append('come_to_office',1)
      }
      if(state==false){
        form.append('come_to_office',0)
      }

      if(state2==true){
        form.append('train_in_customer_location',1)
      }
      if(state2==false){
        form.append('train_in_customer_location',0)
      }
    //   form.append('train_in_customer_location',fields.train_in_customer_location)

      selectedSubjects.map((x)=>{
        form.append('subjects',x)
      })

      selectedboards.map((x)=>{
        form.append('boards',x)
      })

      selecteddays.map((x)=>{
        form.append('avaliable_days',x)
      })

      selectedlanguages.map((x)=>{
        form.append('languages',x)
      })

      selectedstandards.map((x)=>{
        form.append('standards',x)
      })

      // transformedData.map((x)=>{
        // form.append('timings',JSON.stringify(transformedData))
      // })

      form.forEach((x,y)=>{
        console.log(`${x} : ${y}`);
        
      })

      // console.log("Subjects",selectedSubjects);
      

     axios.patch(`${ipaddress}/tutor/create/?pk=${id}`,form)
     .then((r)=>{
      setCodingQuestions(['-'])
      setload(false)
      toast.success('Data updated successfully',{
        autoClose:1000,
        theme:'colored',
        position:'top-center'
      })
      setvisible_state(true)
     })
     .catch((err)=>{
      setload(false)
     })
    }


const [codingQuestions, setCodingQuestions] = useState([{
  day:'',
  time:[{
    start_time:'',
  end_time:''
  }]
}]);

const addTime = () => {
  setCodingQuestions([...codingQuestions, {
    day:'',
    time:[{
      start_time:'',
    end_time:''
    }]
  }]);
};

const add_more_time=(qindex)=>{
  const data=codingQuestions.map((x,index)=>{
    if(index===qindex){
      return({...x,time:[...x.time,{start_time:'',end_time:''}]})
    }
    return x
  })
  setCodingQuestions(data)
}

const handleCodingQuestionChange = (index1,event) => {
  const value = event.target.value;

  const data=codingQuestions.map((x,index)=>
    index===index1 ? {...x, day:value} : x
  )

  setCodingQuestions(data)

//   // Split the existing entry to update either start_time or end_time
//   const [startTime, endTime] = codingQuestions[index]?.split('-') || ['', ''];

//   // Update based on the field being changed
//   const updatedTime =
//     field === 'start_time' ? `${value}-${endTime}` : `${startTime}-${value}`;

//   // Replace the value in the array
//   const newQuestions = codingQuestions.map((q, qIndex) =>
//     qIndex === index ? updatedTime : q
//   );

  // setCodingQuestions(newQuestions);
};

const handleOptionChange = (qIndex, optIndex, field, event) => {
  const newQuestions = codingQuestions.map((q, index) => {
    if (index === qIndex) {
      const newOptions = q.time.map((opt, oIndex) => {
        if (oIndex === optIndex) {
          return { ...opt, [field]: event.target.value };
        }
        return opt;
      });
      return { ...q, time: newOptions };
    }
    return q;
  });
  setCodingQuestions(newQuestions);
};

const transformTimeFormat = (questions) => {
  return questions.map((q) => ({
    ...q,
    time: q.time.map((t) => `${t.start_time}-${t.end_time}`) // Transform time array into "start_time-end_time" strings
  }));
};

// const handleSubmit = () => {
//   const transformedData = transformTimeFormat(codingQuestions);
//   console.log('Data to be sent to the backend:', transformedData);
//   // Send `transformedData` to your backend
// };

const [customSubject, setCustomSubject] = useState("")
const[custom_subjects,setcustom_subjects]=useState([])

const handleAddCustomSubject = () => {
  if (customSubject.trim()) {
    const newSubject = { id: subjects.length + 1, name: customSubject };
    console.log("Custom subject",customSubject);
    setcustom_subjects([...custom_subjects,customSubject])
    setSelectedSubjects([...selectedSubjects, customSubject]);
    setCustomSubject(""); // Clear the input field
  }
};

const remove_subject=(value)=>{
  if(custom_subjects.includes(value)){
    const data=custom_subjects.filter(x=>x!==value)
    setcustom_subjects(data)
  }
}

const [customboard, setCustomboard] = useState("")
const[custom_boards,setcustom_boards]=useState([])

const handleAddCustomBoard = () => {
  if (customboard.trim()) {
    const newSubject = { id: subjects.length + 1, name: customboard };
    console.log("Custom board",customboard);
    setcustom_boards([...custom_boards,customboard])
    setSelectedboards([...selectedboards, customboard]);
    setCustomboard(""); // Clear the input field
  }
};

const remove_board=(value)=>{
  if(custom_boards.includes(value)){
    const data=custom_boards.filter(x=>x!==value)
    setcustom_boards(data)
  }
}

const [customlanguages, setCustomlanguages] = useState("")
const[custom_languages,setcustom_languages]=useState([])

const handleAddCustomLanguages = () => {
  if (customlanguages.trim()) {
    const newSubject = { id: subjects.length + 1, name: customlanguages };
    console.log("Custom language",customlanguages);
    setcustom_languages([...custom_languages,customlanguages])
    setSelectedlanguages([...selectedlanguages, customlanguages]);
    setCustomlanguages(""); // Clear the input field
  }
};

const remove_language=(value)=>{
  if(custom_languages.includes(value)){
    const data=custom_languages.filter(x=>x!==value)
    setcustom_languages(data)
  }
}

const [customstandard, setCustomstandard] = useState("")
const[custom_standards,setcustom_standards]=useState([])
const handleAddCustomStandard = () => {
  if (customstandard.trim()) {
    const newSubject = { id: subjects.length + 1, name: customstandard };
    console.log("Custom standard",customstandard);
    setcustom_standards([...custom_standards,customstandard])
    setSelectedstandards([...selectedlanguages, customstandard]);
    setCustomstandard(""); // Clear the input field
  }
};

const remove_standard=(value)=>{
  if(custom_standards.includes(value)){
    const data=custom_standards.filter(x=>x!==value)
    setcustom_standards(data)
  }
}


  const[others,setothers]=useState({
    subjects:false,
    subjects:false,
    subjects:false,
    subjects:false,
    subjects:false,
    subjects:false,
    subjects:false,

  })

  // Phone number verification
  const[load2,setload2]=useState()
  const[message2,setmessage2]=useState()

  const verify_phone=(phone)=>{
    if(phone.length>0){
      setload2(true)
      setTimeout(() => {
        verify2(phone)
      }, 1000);
    }
  }

  const verify2=(phone)=>{
    axios.post(`${ipaddress}/mobilenumbervalidation/`,{
      mobile_number:phone
    })
    .then((r)=>{
      console.log("Phone number verified",r.data);
      setload2(false)
        setmessage2(true)
        fields.contact_number=phone
    })
    .catch((err)=>{
      setmessage2(false)
      setload2(false)
    })
  }

  const[load3,setload3]=useState()
  const[message,setmessage]=useState()

  const verify_phone2=(phone)=>{
    if(phone.length>0){
      setload3(true)
      setTimeout(() => {
        verify(phone)
      }, 1000);
    }
  }

  const verify=(phone)=>{
    axios.post(`${ipaddress}/mobilenumbervalidation/`,{
      mobile_number:phone
    })
    .then((r)=>{
      console.log("Phone number verified",r.data);
      setload3(false)
        setmessage(true)
        fields.whatsapp_number=phone
    })
    .catch((err)=>{
      setmessage(false)
      setload3(false)
    })
  }

  const[state,setstate]=useState(false)
  const[state2,setstate2]=useState(false)

  const[verified_status,setverified_status]=useState(false)
  const[status,setstatus]=useState('')
  const[remarks,setremarks]=useState('')

  const[visible_state,setvisible_state]=useState(false)
  const[error_state,seterror_state]=useState(false)

  return (
    <div className='animate__animated animate__fadeIn'>
      <div className={`container ${visible_state || error_state ? 'd-none':''}`}>
        <h4 style={{color:'#3D2354'}} className='fw-bold py-4 text-center'>Interview Form</h4>
        <div className="m-0 row main p-4" style={{borderRadius:'15px'}}>
            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Name</label>
                <input type="text" value={fields.name} onChange={handleinputchange} className='form-control py-3 shadow-none inputs mt-2' name="name" id="" />
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Contact Number</label>
                {/* <input type="tel" maxLength={10} className='form-control py-3 shadow-none inputs mt-2' value={fields.contact} onChange={handleinputchange} name="contact" id="" /> */}
            
                <div class="input-group rounded inputs py-2 mt-2">
    <input type="tel" maxLength={10} value={fields.contact_number} className="form-control bg-transparent border-0 shadow-none py-2" id="inputPassword4" name='contact' onChange={(e)=>{
      verify_phone(e.target.value)
    }}/>
  <span class="input-group-text border-0 bg-transparent" id="basic-addon2">
  <div class={`spinner-border spinner-border-sm text-success ${load2 ? '':'d-none'}`} role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198754" class={`bi bi-check2-circle ${!load2 && message2 ==true ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg>
  </span>
</div>
<p className={`text-danger mt-1 ${message2==false ? '':'d-none'}`} style={{fontSize:'13px'}}>*Mobile Number already exist</p>
            </div>

            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Whatsapp Number</label>
                
                <div class="input-group rounded inputs py-2 mt-2">
    <input type="tel" maxLength={10} value={fields.whatsapp_number} className="form-control bg-transparent border-0 shadow-none py-2" id="inputPassword4" name='whatsapp' onChange={(e)=>{
      verify_phone2(e.target.value)
    }}/>
  <span class="input-group-text border-0 bg-transparent" id="basic-addon2">
  <div class={`spinner-border spinner-border-sm text-success ${load3 ? '':'d-none'}`} role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198754" class={`bi bi-check2-circle ${!load3 && message ==true ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg>
  </span>
</div>
<p className={`text-danger mt-1 ${message==false ? '':'d-none'}`} style={{fontSize:'13px'}}>*Mobile Number already exist</p>
            </div>

         
            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Gender</label>
                <select type="text" className='form-control py-3 shadow-none inputs mt-2' value={fields.gender} onChange={handleinputchange} name="gender" id="" >
                    <option value="">Choose...</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="transgender">Transgender</option>
                </select>
            </div>
            <div className="col-md-6 col-lg-8 mb-4">
                <label htmlFor="" className='labels'>Address</label>
                <textarea type="text" rows={1} className='form-control py-3 shadow-none inputs mt-2' value={fields.address} onChange={handleinputchange} name="address" id="" />
            </div>
        </div>


        <div className="m-0 row main p-4 mt-5" style={{borderRadius:'15px'}}>
        <div className="col-md-6 col-lg-4 mb-4">
    <label htmlFor="" className="labels">Subjects</label>
    <p style={{ cursor: 'pointer' }} onClick={() => toggleDiv('open1')}
      className='m-0 inputs rounded py-3 mt-2 d-flex justify-content-between px-3 align-items-center'>
      <span>Select the Subjects</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        className={`bi bi-caret-down-fill ${openState.open1 ? 'd-none' : ''}`} viewBox="0 0 16 16">
        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        className={`bi bi-caret-up-fill ${openState.open1 ? '' : 'd-none'}`} viewBox="0 0 16 16">
        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
      </svg>
    </p>

    <div className={`collapse-content inputs py-2 ${openState.open1 ? 'show' : ''}`}
      style={{ maxHeight: '160px', overflowY: 'scroll' }}>
      {subjects.map((x) => (
        <p key={x.id} style={{ cursor: 'pointer' }} onClick={() => subject_value(x.id)} className='px-3'>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className={`bi bi-check-square ${selectedSubjects.includes(x.id) ? 'd-none' : ''}`} viewBox="0 0 16 16">
            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
            <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD"
            className={`bi bi-check-square-fill ${selectedSubjects.includes(x.id) ? '' : 'd-none'}`} viewBox="0 0 16 16">
            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
          </svg>
          <span className='ms-2'>{x.name}</span>
        </p>
      ))}

      <div className="px-3 mt-2">
        <input
          type="text"
          value={customSubject}
          onChange={(e) => setCustomSubject(e.target.value)}
          placeholder="Enter the subject"
          className="form-control shadow-none border-secondary-subtle"
        />
        <button className="btn btn-primary mt-2 btn-sm" onClick={handleAddCustomSubject}>Add Subject</button>
      </div>
    </div>
  </div>

  {/* Selected custom subjects */}
  {custom_subjects.length>0 && (<div className="col-md-6 col-lg-4 mb-4">
    <label htmlFor="" className='labels'>Other Subjects</label>
    <div className='inputs rounded p-3 mt-2' style={{maxHeight:'220px',overflowY:'scroll'}}>
    {custom_subjects.map((x,index)=>{
      return(
        <>
        <p className={`d-flex align-items-center justify-content-between ${index%2==0 ? 'm-0' : 'mt-3'}`}><span>{index+1}] {x}</span> 
        <svg style={{cursor:'pointer'}} onClick={()=>{
          remove_subject(x)
        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
        </p>
        </>
      )
    })}
    </div>
  </div>)}

{/* Boards */}
<div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Boards</label>
            
              <p style={{cursor:'pointer'}} onClick={()=>{
                toggleDiv('open2')
              }} className='m-0 inputs rounded py-3 mt-2 d-flex justify-content-between px-3 align-items-center'><span>Select the Boards</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-caret-down-fill ${openState.open2 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-caret-up-fill  ${openState.open2 ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
</svg>
</p>

<div className={`collapse-content inputs py-2 ${openState.open2 ? 'show' : ''}`} style={{maxHeight: '160px',overflowY:'scroll'}}>
{boards.map((x)=>{
  return(
    <>
     <p style={{cursor:'pointer'}} onClick={()=>{
      board_value(x.id)
     }} className='px-3'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-square ${selectedboards.includes(x.id) ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-square-fill ${selectedboards.includes(x.id) ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
<span className='ms-2'>{x.name}</span></p>
    </>
  )
 })}

<div className="px-3 mt-2">
        <input
          type="text"
          value={customboard}
          onChange={(e) => setCustomboard(e.target.value)}
          placeholder="Enter the board"
          className="form-control shadow-none border-secondary-subtle"
        />
        <button className="btn btn-primary mt-2 btn-sm" onClick={handleAddCustomBoard}>Add Board</button>
      </div>
</div>

            </div>

  {/* Selected custom boards */}
  {custom_boards.length>0 && (<div className="col-md-6 col-lg-4 mb-4">
    <label htmlFor="" className='labels'>Other Boards</label>
    <div className='inputs rounded p-3 mt-2' style={{maxHeight:'220px',overflowY:'scroll'}}>
    {custom_boards.map((x,index)=>{
      return(
        <>
         <p className={`d-flex align-items-center justify-content-between ${index%2==0 ? 'm-0' : 'mt-3'}`}><span>{index+1}] {x}</span> 
        <svg style={{cursor:'pointer'}} onClick={()=>{
          remove_board(x)
        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
        </p>
        </>
      )
    })}
    </div>
  </div>)}



{/* Standards */}
<div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Standards</label>
            
              <p style={{cursor:'pointer'}} onClick={()=>{
                toggleDiv('open3')
              }} className='m-0 inputs rounded py-3 mt-2 d-flex justify-content-between px-3 align-items-center'><span>Select the Standards</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-caret-down-fill ${openState.open3 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-caret-up-fill  ${openState.open3 ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
</svg>
</p>

<div className={`collapse-content inputs py-2 ${openState.open3 ? 'show' : ''}`} style={{maxHeight: '160px',overflowY:'scroll'}}>
{standards.map((x)=>{
  return(
    <>
     <p style={{cursor:'pointer'}} onClick={()=>{
      standard_value(x.id)
     }} className='px-3'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-square ${selectedstandards.includes(x.id) ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-square-fill ${selectedstandards.includes(x.id) ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
<span className='ms-2'>{x.name}</span></p>
    </>
  )
 })}

<div className="px-3 mt-2">
        <input
          type=""
          value={customstandard}
          onChange={(e) => setCustomstandard(e.target.value)}
          placeholder="Enter the standard"
          className="form-control shadow-none border-secondary-subtle"
        />
        <button className="btn btn-primary mt-2 btm-sm" onClick={handleAddCustomStandard}>Add Standard</button>
      </div>
</div>

            </div>

  {/* Selected custom standards */}
  {custom_standards.length>0 && (<div className="col-md-6 col-lg-4 mb-4">
    <label htmlFor="" className='labels'>Other Standards</label>
    <div className='inputs rounded p-3 mt-2' style={{maxHeight:'220px',overflowY:'scroll'}}>
    {custom_standards.map((x,index)=>{
      return(
        <>
         <p className={`d-flex align-items-center justify-content-between ${index%2==0 ? 'm-0' : 'mt-3'}`}><span>{index+1}] {x}</span> 
        <svg style={{cursor:'pointer'}} onClick={()=>{
          remove_standard(x)
        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
        </p>
        </>
      )
    })}
    </div>
  </div>)}



            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Languages</label>
            
              <p style={{cursor:'pointer'}} onClick={()=>{
                toggleDiv('open6')
              }} className='m-0 inputs rounded py-3 mt-2 d-flex justify-content-between px-3 align-items-center'><span>Select the Languages</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-caret-down-fill ${openState.open6 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-caret-up-fill  ${openState.open6 ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
</svg>
</p>

<div className={`collapse-content inputs py-2 ${openState.open6 ? 'show' : ''}`} style={{maxHeight: '160px',overflowY:'scroll'}}>
{languages.map((x)=>{
  return(
    <>
     <p style={{cursor:'pointer'}} onClick={()=>{
      language_value(x.id)
     }} className='px-3'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-square ${selectedlanguages.includes(x.id) ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-square-fill ${selectedlanguages.includes(x.id) ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
<span className='ms-2'>{x.name}</span></p>
    </>
  )
 })}

<div className="px-3 mt-2">
        <input
          type="text"
          value={customlanguages}
          onChange={(e) => setCustomlanguages(e.target.value)}
          placeholder="Enter the language"
          className="form-control shadow-none boredr-secondary-subtle"
        />
        <button className="btn btn-primary mt-2 btn-sm" onClick={handleAddCustomLanguages}>Add Language</button>
      </div>
</div>

            </div>

  {/* Selected custom languages */}
  {custom_languages.length>0 && (<div className="col-md-6 col-lg-4 mb-4">
    <label htmlFor="" className='labels'>Other Subjects</label>
    <div className='inputs rounded p-3 mt-2' style={{maxHeight:'220px',overflowY:'scroll'}}>
    {custom_languages.map((x,index)=>{
      return(
        <>
         <p className={`d-flex align-items-center justify-content-between ${index%2==0 ? 'm-0' : 'mt-3'}`}><span>{index+1}] {x}</span> 
        <svg style={{cursor:'pointer'}} onClick={()=>{
          remove_language(x)
        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
        </p>
        </>
      )
    })}
    </div>
  </div>)}

  {/* Days */}
<div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Days Available For Training</label>
            
              <p style={{cursor:'pointer'}} onClick={()=>{
                toggleDiv('open5')
              }} className='m-0 inputs rounded py-3 mt-2 d-flex justify-content-between px-3 align-items-center'><span>Select the Days</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-caret-down-fill ${openState.open5 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-caret-up-fill  ${openState.open5 ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
</svg>
</p>

<div className={`collapse-content inputs py-2 ${openState.open5 ? 'show' : ''}`} style={{maxHeight: '160px',overflowY:'scroll'}}>
{days.map((x)=>{
  return(
    <>
     <p style={{cursor:'pointer'}} onClick={()=>{
      day_value(x.id)
     }} className='px-3'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-square ${selecteddays.includes(x.id) ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-square-fill ${selecteddays.includes(x.id) ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
<span className='ms-2'>{x.name}</span></p>
    </>
  )
 })}
</div>

            </div>

            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Preferred Location For Training</label>
                <input type="text" className='form-control py-3 shadow-none inputs mt-2' value={fields.areas} onChange={handleinputchange} name="areas" id="" />
            </div>

            
{/* Timings */}
{/* <div className="col-12 mb-4">
      <label htmlFor="" className="labels">Timings</label>
      {codingQuestions.map((q, qIndex) => (
        <div
          className="row m-0 mb-4"
          key={qIndex}
          style={{ marginBottom: '20px', borderRadius: '10px' }}
        >
          <div className="col-md-6 col-lg-4">
            <select
              name="day"
              onChange={(e) => handleCodingQuestionChange(qIndex,e)}
              className="form-select shadow-none border-secondary-subtle py-3 inputs mt-2"
            >
              <option value="">Select Day</option>
              {days.map((x) => (
                <option key={x.id} value={x.id}>{x.name}</option>
              ))}
            </select>
          </div>

          {q.time &&
            q.time.map((x, tIndex) => (
              <div className="row m-0" key={tIndex}>
                <div className="col-md-6 col-lg-4 ps-md-0">
                  <input
                    className="add-option inputs form-control shadow-none py-3 mt-2"
                    type="time"
                    value={x.start_time}
                    onChange={(e) =>
                      handleOptionChange(qIndex, tIndex, 'start_time', e)
                    }
                    required
                  />
                </div>
                <div className="col-md-6 col-lg-4 pe-md-0">
                  <input
                    type="time"
                    className="form-control inputs shadow-none py-3 mt-2"
                    value={x.end_time}
                    onChange={(e) =>
                      handleOptionChange(qIndex, tIndex, 'end_time', e)
                    }
                    required
                  />
                </div>

                <div className="col-md-6 col-lg-4 pe-md-0 d-flex align-items-end">
                  <button
                    className="btn btn-primary px-4 btn-sm"
                    onClick={() => add_more_time(qIndex)}
                  >
                    Add More
                  </button>
                </div>
              </div>
            ))}
        </div>
      ))}

      <button
        className="btn btn-primary px-4 btn-sm"
        onClick={() => addTime()}
      >
        Add More
      </button>

    </div> */}
          
        </div>


        <div className="m-0 row main p-4 mt-5" style={{borderRadius:'15px'}}>
            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Mode</label>
                <select type="text" className='form-select py-3 shadow-none inputs mt-2' value={fields.mode} onChange={handleinputchange} name="mode" id="" >
                    <option value="">Choose...</option>
                    <option value="online">Online</option>
                    <option value="offline">Offline</option>
                </select>
            </div>
            <div className={`col-md-6 col-lg-4 mb-4 d-flex flex-column justify-content-center ${fields.mode==='offline' ? '':'d-none'}`}>
                <div className='mt-4'>
                <p className='m-0 d-flex align-items-center' style={{cursor:'pointer'}} onClick={()=>{
                  setstate(!state)
                }}>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-square ${state ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-square-fill ${state ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='labels ms-2'>Available to train in our office</span></p>
                </div>

                <div className='mt-2'>
                <p className='m-0 d-flex align-items-center' style={{cursor:'pointer'}} onClick={()=>{
                  setstate2(!state2)
                }}>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-square ${state2 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-square-fill ${state2 ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg> <span className='labels ms-2'>Available to train in our Customer location</span></p>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Experience</label>
                <input type="number" className='form-control py-3 shadow-none inputs mt-2' value={fields.experience} onChange={handleinputchange} name="experience" id="" />
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Charges</label>
                <select type="text" className='form-control py-3 shadow-none inputs mt-2' value={fields.charges} onChange={handleinputchange} name="charges" id="" >
                    <option value="">Choose...</option>
                    <option value="per_hour">Per Hour</option>
                    <option value="per_month">Per Month</option>
                </select>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Fees</label>
               <input type="number" className='form-control mt-2 inputs py-3' value={fields.fees} onChange={handleinputchange} name="fees" id="" />
            </div>

           <div className="col-12">
            <div className="row m-0">
            <div className="col-md-6 col-lg-2 mb-4 d-flex align-items-end">
            <p style={{cursor:'pointer'}} onClick={()=>{
                setverified_status(!verified_status)
     }} className='px-3 m-0 d-flex align-items-center'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-square ${verified_status ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-square-fill ${verified_status ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
<span className='ms-2 fw-medium'>Verified</span></p>
            </div>

            <div className="col-md-6 col-lg-4 mb-4">
                <label htmlFor="" className='labels'>Status</label>
                <select type="text" className='form-select py-3 shadow-none inputs mt-2' value={status} onChange={(e)=>{
                    setstatus(e.target.value)
                }} name="charges" id="" >
                    <option value="">Choose...</option>
                    <option value="Excellent">Excellent</option>
                    <option value="Good">Good</option>
                    <option value="AVerage">Average</option>
                    <option value="Rejected">Rejected</option>
                </select>
               </div>

               <div className="col-md-6 mb-4">
                <label htmlFor="" className='labels'>Remarks</label>
                <textarea rows={1} className='form-control py-3 shadow-none inputs mt-2' value={remarks} onChange={(e)=>{
                    setremarks(e.target.value)
                }} name="experience" id="" />
            </div>
            </div>
           </div>
        </div>


        <div className='mt-4 text-end mb-5'>
            <button className='btn px-5 text-white py-2' onClick={submit} style={{backgroundColor:'#624DE3'}}>
            <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} role="status" aria-hidden="true"></span>
            <span class={load ? 'ms-2':'d-none'}>Submitting...</span>
            <span className={load ? 'd-none':''}>Submit</span>
            </button>
        </div>
      </div>

      <div style={{height:'100vh'}} className={visible_state ? 'py-5 animate__animated animate__jello':'d-none'}>
        <img src={require('../images/2960988.jpg')} className='d-block mx-auto' style={{width:'50%'}} alt="" />
      </div>

      <div style={{height:'100vh',backgroundColor:'#EDEEF2'}} className={error_state ? 'py-5 animate__animated animate__jello':'d-none'}>
        <img src={require('../images/404.jpg')} className='d-block mx-auto' style={{width:'50%'}} alt="" />
      </div>
    </div>
  )
}

export default Interview_form
