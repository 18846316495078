import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar_component from './Sidebar_component'
import Svg1 from '../svg/Svg1'
import Svg2 from '../svg/Svg2'
import Svg3 from '../svg/Svg3'
import Svg4 from '../svg/Svg4'
import Svg5 from '../svg/Svg5'
import Logout from '../svg/Logout'

const Sidebar = ({value}) => {
    const[open,setopen]=useState(true)

    const navigate=useNavigate()

    const logout=()=>{
      sessionStorage.removeItem('user')
      navigate('/admin_login')
    }

  return (
    <div style={{width:'220px',backgroundColor:'#3940DB',height:'100vh'}} className='shadow-sm sidebar d-none d-lg-block'>
    <div className='d-flex align-items-center justify-content-center' style={{position:'relative',height:'70px'}}>
        <img className={open ? 'd-inline' : 'd-none'} src={require('../images/Logo_Blue 1.png')} style={{width:'70%'}} alt="" />
    </div>


<ul class={`nav flex-column ${open ? 'w-100' : ''} mx-auto px-2 mt-2`}>




{/* <li class={`nav-item py-2 w-100  ${open ? '' : 'd-flex justify-content-center'} ${value==="activities" ? 'sidebar-valid2':'sidebar-valid'}`} >
<Link to='/admin_dashboard' class={`nav-link d-flex align-items-center ${value==="activities" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart" viewBox="0 0 16 16">
<path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>Dashboard</span> </Link>
</li> */}

<li class={`nav-item py-2 mb-3 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="dashboard" ? 'sidebar-valid2':'sidebar-valid'}`} >
<Sidebar_component text={"Dashboard"} path={''} color={value==="dashboard" ? 'text-dark':'text-white'} Icon={Svg5}/>
</li>

<li class={`nav-item py-2 mb-3 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="tutors" ? 'sidebar-valid2':'sidebar-valid'}`} >
<Sidebar_component text={"View Tutors"} color={value==="tutors" ? 'text-dark':'text-white'} path={'/tutors'} Icon={Svg2}/>
</li>

<li class={`nav-item py-2 mb-3 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="students" ? 'sidebar-valid2':'sidebar-valid'}`} >
<Sidebar_component text={"View Students"} color={value==="students" ? 'text-dark':'text-white'} path={''} Icon={Svg2}/>
</li>

<li class={`nav-item py-2 mb-3 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="analytics" ? 'sidebar-valid2':'sidebar-valid'}`} >
<Sidebar_component text={"Analytics"} color={value==="analytics" ? 'text-dark':'text-white'} path={''} Icon={Svg3}/>
</li>

<li class={`nav-item py-2 mb-3 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="settings" ? 'sidebar-valid2':'sidebar-valid'}`} >
<Sidebar_component text={"Settings"} color={value==="settings" ? 'text-dark':'text-white'} path={'/tutors'} Icon={Svg4}/>
</li>

<li class={`nav-item py-2 mb-3 w-100 ${open ? '' : 'd-flex justify-content-center'} sidebar-valid`} onClick={()=>{
  logout()
}} >
<Sidebar_component text={"Logout"} color={'text-white'} Icon={Logout}/>
</li>



</ul>


</div>
  )
}

export default Sidebar
