import React from 'react'

const Work_svg2 = () => {
  return (
    <div>
      <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="72" height="72" rx="36" fill="currentColor"/>
<path d="M37.6667 19.332L21 39.332H36L34.3333 52.6654L51 32.6654H36L37.6667 19.332Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_537_2162" x1="27.597" y1="22.1972" x2="16.9431" y2="65.9874" gradientUnits="userSpaceOnUse">
<stop stop-color="#7C00D9"/>
<stop offset="0.565343" stop-color="#4600B1"/>
<stop offset="1" stop-color="#141778"/>
</linearGradient>
</defs>
</svg>

    </div>
  )
}

export default Work_svg2
