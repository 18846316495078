import React from 'react'

const Subject_svg1 = () => {
  return (
    <div>
    <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_371_1714)">
<g clip-path="url(#clip1_371_1714)">
<g clip-path="url(#clip2_371_1714)">
<g clip-path="url(#clip3_371_1714)">
<path d="M53.6 21.6H38.3V18.9C38.3 18.612 38.1622 18.3413 37.9292 18.1719L28.4 11.2419V8.1H38.3C38.797 8.1 39.2 7.69702 39.2 7.2V1.8C39.2 1.30297 38.797 0.9 38.3 0.9H28.4C28.4 0.402975 27.997 0 27.5 0C27.003 0 26.6 0.402975 26.6 0.9V11.2419L17.0708 18.1719C16.8378 18.3413 16.7 18.612 16.7 18.9V21.6H1.4C0.902975 21.6 0.5 22.003 0.5 22.5V53.1C0.5 53.597 0.902975 54 1.4 54H53.6C54.097 54 54.5 53.597 54.5 53.1V22.5C54.5 22.003 54.097 21.6 53.6 21.6ZM28.4 2.7H37.4V6.3H28.4V2.7ZM16.7 52.2H2.3V23.4H16.7V52.2ZM31.1 52.2H23.9V43.2H31.1V52.2ZM36.5 52.2H32.9V42.3C32.9 41.803 32.497 41.4 32 41.4H23C22.503 41.4 22.1 41.803 22.1 42.3V52.2H18.5V19.3581L27.5 12.8124L36.5 19.3581V52.2ZM52.7 52.2H38.3V23.4H52.7V52.2Z" fill="currentColor"/>
<path d="M8.6 26.0996H5V27.8996H8.6V26.0996Z" fill="currentColor"/>
<path d="M14.0004 26.0996H10.4004V27.8996H14.0004V26.0996Z" fill="currentColor"/>
<path d="M8.6 29.7002H5V31.5002H8.6V29.7002Z" fill="currentColor"/>
<path d="M14.0004 29.7002H10.4004V31.5002H14.0004V29.7002Z" fill="currentColor"/>
<path d="M8.6 33.2998H5V35.0998H8.6V33.2998Z" fill="currentColor"/>
<path d="M14.0004 33.2998H10.4004V35.0998H14.0004V33.2998Z" fill="currentColor"/>
<path d="M8.6 36.9004H5V38.7004H8.6V36.9004Z" fill="currentColor"/>
<path d="M14.0004 36.9004H10.4004V38.7004H14.0004V36.9004Z" fill="currentColor"/>
<path d="M8.6 40.5H5V42.3H8.6V40.5Z" fill="currentColor"/>
<path d="M14.0004 40.5H10.4004V42.3H14.0004V40.5Z" fill="currentColor"/>
<path d="M44.6 26.0996H41V27.8996H44.6V26.0996Z" fill="currentColor"/>
<path d="M50.0004 26.0996H46.4004V27.8996H50.0004V26.0996Z" fill="currentColor"/>
<path d="M44.6 29.7002H41V31.5002H44.6V29.7002Z" fill="currentColor"/>
<path d="M50.0004 29.7002H46.4004V31.5002H50.0004V29.7002Z" fill="currentColor"/>
<path d="M44.6 33.2998H41V35.0998H44.6V33.2998Z" fill="currentColor"/>
<path d="M50.0004 33.2998H46.4004V35.0998H50.0004V33.2998Z" fill="currentColor"/>
<path d="M44.6 36.9004H41V38.7004H44.6V36.9004Z" fill="currentColor"/>
<path d="M50.0004 36.9004H46.4004V38.7004H50.0004V36.9004Z" fill="currentColor"/>
<path d="M44.6 40.5H41V42.3H44.6V40.5Z" fill="currentColor"/>
<path d="M50.0004 40.5H46.4004V42.3H50.0004V40.5Z" fill="currentColor"/>
<path d="M21.2002 27.0002C21.2041 30.4779 24.0225 33.2963 27.5002 33.3002C30.9796 33.3002 33.8002 30.4796 33.8002 27.0002C33.8002 23.5208 30.9796 20.7002 27.5002 20.7002C24.0208 20.7002 21.2002 23.5208 21.2002 27.0002ZM32.0002 27.0002C32.0002 29.4854 29.9854 31.5002 27.5002 31.5002C25.015 31.5002 23.0002 29.4854 23.0002 27.0002C23.0031 24.5162 25.0162 22.5031 27.5002 22.5002C29.9854 22.5002 32.0002 24.515 32.0002 27.0002Z" fill="currentColor"/>
<path d="M26.5998 26.1004H24.7998V27.9004H28.3998V23.4004H26.5998V26.1004Z" fill="currentColor"/>
</g>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_371_1714">
<rect width="54" height="54" fill="white" transform="translate(0.5)"/>
</clipPath>
<clipPath id="clip1_371_1714">
<rect width="54" height="54" fill="white" transform="translate(0.5)"/>
</clipPath>
<clipPath id="clip2_371_1714">
<rect width="54" height="54" fill="white" transform="translate(0.5)"/>
</clipPath>
<clipPath id="clip3_371_1714">
<rect width="54" height="54" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>


    </div>
  )
}

export default Subject_svg1
