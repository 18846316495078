import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Layout8 from '../Subjects/Layout8'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import FAQ from '../Subjects/FAQ'
import Blogs from '../Subjects/Blogs'
import Benefits_to_student from '../Benefits_to_student'
import What_will_you_be from '../What_will_you_be'
import Assessment from '../Assessment'
import { useFetcher, useNavigate, useParams } from 'react-router-dom'
import { boards } from '../../Contents/Borads'

const Boards_page = () => {
  const navigate = useNavigate()

  const {slug}=useParams()

  const[board_data,setboard_data]=useState({})

  useEffect(()=>{
    const data=boards.find(x=>x.slug===slug)
    console.log("Board details",data)
    setboard_data(data)
  },[])

  return (
    <div className='animate__animated animate__fadeIn'>
      <div className="board-div pb-5">
        <Navbar value={"board"} color={"#8236C8"}/>
        <div className='d-flex align-items-center container1'>
        <div className="row m-0 container mx-auto mt-md-4">
            <div className="col-lg-6">
            <div className="h-100 d-flex flex-column justify-content-between">
                <div>
                <p className='online-text fw-bolder text-white m-0' style={{lineHeight:'65px'}}>Expert Tutoring for Success</p>
                <p className='online-text fw-bolder text-white m-0' style={{lineHeight:'65px'}}>in Every Board</p>

                <div className='mt-5'>
                    <p className='text-white'>Get personalized tutoring for all education boards, ensuring top results with expert guidance, tailored strategies, and focused exam preparation.</p>
                    <button onClick={()=>{
                      navigate('/add_tutor')
                    }} className='text-white border-0 px-5 py-2 shadow' style={{backgroundColor:'#DB2A6B',fontSize:'14px',borderRadius:'10px 10px 10px 0px'}}>Get Started</button>
                </div>
                </div>

                <div className='row m-0'>
                   <div className="col-md-4">
                   <div className='d-flex align-items-center'>
                        <img src={require('../../images/icon.png')} alt="" />
                        <div className='ms-2'>
                        <p className='text-white fw-meidum m-0'>Build</p>
                        <p className='text-white fw-meidum m-0'>Community</p>
                        </div>
                    </div>
                   </div>

                   <div className="col-md-4">
                   <div className='d-flex align-items-center'>
                        <img src={require('../../images/icon (1).png')} alt="" />
                        <div className='ms-2'>
                        <p className='text-white fw-meidum m-0'>Build</p>
                        <p className='text-white fw-meidum m-0'>Community</p>
                        </div>
                    </div>
                   </div>

                   <div className="col-md-4">
                   <div className='d-flex align-items-center'>
                        <img src={require('../../images/icon (2).png')} alt="" />
                        <div className='ms-2'>
                        <p className='text-white fw-meidum m-0'>Build</p>
                        <p className='text-white fw-meidum m-0'>Community</p>
                        </div>
                    </div>
                   </div>
                </div>
            </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <img src={require('../../images/content.png')} style={{width:'90%'}} alt="" />
            </div>
        </div>
        </div>
      </div>

      <div className="board-subdiv2">
      <div className="row m-0 container mx-auto py-5">
        <p className='fs-1 fw-bold text-center'>Teach students worldwide</p>

        <div className='mt-4'>
         {board_data.para && board_data.para.map((x)=>{
          return(
            <>
             <p className='text-center mb-4'>{x}</p>
            </>
          )
         })}
                </div>
      </div>
      </div>

      {/* Layout2 */}
      <div className="row m-0 container mx-auto py-5 mt-4">
        {/* <div className="col-lg-5 text-end" style={{position:'relative'}}>
            <img src={require('../../images/Rectangle 48.png')} style={{width:'70%'}} alt="" />

            <div className='bg-white d-flex align-items-center justify-content-between shadow p-3 board-small-div1' style={{borderRadius:'10px',position:'absolute'}}>
                <div className='d-flex align-items-center'>
                <img src={require('../../images/Ellipse 33.png')} width={50} alt="" />
                <div className='ms-2'>
                    <p className='fw-medium m-0 text-start'>Madhavan</p>
                    <p className='m-0 text-secondary mt-1' style={{fontSize:'13px'}}>Expert Saving Money</p>
                </div>
                </div>
                <img src={require('../../images/Group 109.png')} alt="" />
            </div>

            <div className='bg-white shadow p-3 board-small-div2' style={{borderRadius:'10px',position:'absolute'}}>
                <p className='text-secondary m-0 mb-1 text-start' style={{fontSize:'13px'}}>Total Income</p>
                <p className='d-flex align-items-center m-0 justify-content-between'><span className='fw-bold'>$2000</span>
                <img src={require('../../images/bar-chart-2 1.png')} alt="" /></p>
            </div>

            <div className='bg-white shadow p-3 board-small-div3' style={{borderRadius:'10px',position:'absolute'}}>
                <p className='d-flex align-items-center m-0'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg> <span className='ms-3'>Money Transfer Succesfull</span></p>
            </div>

        </div>  
        <div className="col-lg-5 ms-auto">
            <p className='fs-1 fw-bolder'>What Benifit Will You Get</p>

            <div className='mt-5'>
                <p className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <span className='ms-3 fw-medium'>Free Consulting With Experet Saving Money</span></p>
<p className='d-flex align-items-center mt-4'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <span className='ms-3 fw-medium'>Online Banking</span></p>
<p className='d-flex align-items-center mt-4'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <span className='ms-3 fw-medium'>Investment Report Every Month</span></p>
<p className='d-flex align-items-center mt-4'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <span className='ms-3 fw-medium'>Saving Money For The Future</span></p>
<p className='d-flex align-items-center mt-4'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#8236C8" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <span className='ms-3 fw-medium'>Online Transection</span></p>
            </div>
        </div> */}

        <Benefits_to_student/>
      </div>

      {/* Layout3 */}
      <div className='board-layout3'>
        <div className="row m-0 container mx-auto">
            <div className="col-lg-5 text-end">
                <img src={require('../../images/Rectangle 236.png')} alt="" />
            </div>
            <div className="col-lg-4 pt-5">
                <p className='fs-1 fw-bolder text-white'>{board_data.head1}</p>
                <p className='text-white' style={{fontSize:'14px'}}>{board_data.paragraph1}</p>
            
               </div>
        </div>

        <div className="row m-0 container mx-auto justify-content-center">
            <div className="col-lg-7 pt-5">
               <div className='board-subdiv'>
                 <p className='fs-1 fw-bolder text-white'>{board_data.head2}</p>
                <p className='text-white' style={{fontSize:'14px'}}>{board_data.paragraph2}</p>
            
                </div>
            </div>
            <div className="col-lg-5 text-start">
                <img src={require('../../images/Rectangle 233.png')} alt="" />
            </div>
        </div>
      </div>

      {/* Layout 4 */}
      <div className="work-div3 py-5" style={{backgroundColor:'#EDF1FD'}}>
    <div className="row m-0 py-4 container mx-auto justify-content-center">
        <div className="col-lg-5">
          {board_data.para2 && board_data.para2.map((x,index)=>{
            return(
              <>
              <p className='fs-2 fw-bolder'>{x.head}</p>
              <p className='m-0 mt-3 mb-4' style={{color:'#4F4F4F'}}>{x.para}</p>
              </>
            )
          })}
        </div>
        <div className="col-lg-6 text-end">
            <img src={require('../../images/WORK/Image Placeholder (1).png')} style={{width:'80%'}} alt="" />
        </div>
    </div>
</div>

<What_will_you_be/>

<Assessment/>

      <Blogs/>
      <Layout8/>
      {/* FAQ */}
      <FAQ/>
      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
  )
}

export default Boards_page
