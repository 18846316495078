import React from 'react'
import { useNavigate } from 'react-router-dom'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'

const View_blog = () => {
    const navigate=useNavigate()

  return (
    <div className=''>
      <div className="container pt-5">
    <p className='d-flex align-items-center'><svg style={{cursor:'pointer'}} onClick={()=>{
      navigate('/blogs')
    }} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg><span style={{fontSize:'15px'}} className='fw-medium ms-2'>Travel</span><span style={{fontSize:'15px'}} className='text-secondary ms-4'>24 August 2024</span></p>
    <p className='fs-1 fw-bolder mt-4' style={{lineHeight:'50px'}}>How to make a Game look more attractive with New VR & AI Technology</p>
        
        <div className='py-4'>
            <img src={require('../../images/blogs/Image Placeholder.png')} style={{width:'100%'}} alt="" />
        </div>

        <div className='mt-4'>
            <p className='mb-4' style={{color:'#666666',fontSize:'15px'}}>Google has been investing in AI for many years and bringing its benefits to individuals, businesses and communities. Whether it’s publishing state-of-the-art research, building helpful products or developing tools and resources that enable others, we’re committed to making AI accessible to everyone.</p>
            <p className='mb-4' style={{color:'#666666',fontSize:'15px'}}>We’re now at a pivotal moment in our AI journey. Breakthroughs in generative AI are fundamentally changing how people interact with technology — and at Google, we’ve been responsibly developing large language models so we can safely bring them to our products. Today, we’re excited to share our early progress. Developers and businesses can now try new APIs and products that make it easy, safe and scalable to start building with Google’s best AI models through Google Cloud and a new prototyping environment called MakerSuite. And in Google Workspace, we’re introducing new features that help people harness the power of generative AI to create, connect and collaborate.</p>

            <p className='mb-4' style={{color:'#666666',fontSize:'15px'}}>We’re now at a pivotal moment in our AI journey. Breakthroughs in generative AI are fundamentally changing how people interact with technology — and at Google, we’ve been responsibly developing large language models so we can safely bring them to our products. Today, we’re excited to share our early progress. Developers and businesses can now try new APIs and products that make it easy, safe and scalable to start building with Google’s best AI models through Google Cloud and a new prototyping environment called MakerSuite. And in Google Workspace, we’re introducing new features that help people harness the power of generative AI to create, connect and collaborate.</p>

            <div className='py-4 mb-4 text-center'>
            <img src={require('../../images/blogs/Image.png')} style={{width:'80%'}} alt="" />
        </div>

        <p className='mb-4' style={{color:'#666666',fontSize:'15px'}}>We’re now at a pivotal moment in our AI journey. Breakthroughs in generative AI are fundamentally changing how people interact with technology — and at Google, we’ve been responsibly developing large language models so we can safely bring them to our products. Today, we’re excited to share our early progress. Developers and businesses can now try new APIs and products that make it easy, safe and scalable to start building with Google’s best AI models through Google Cloud and a new prototyping environment called MakerSuite. And in Google Workspace, we’re introducing new features that help people harness the power of generative AI to create, connect and collaborate.</p>

        </div>

        <div className='mt-5 pt-5'>
            <div className="d-flex align-items-center justify-content-between">
                <p className='fs-1 fw-bolder m-0'>Popular Post</p>
                <button className='btn px-4 py-2 text-white' style={{fontSize:'14px',backgroundColor:'#7C4EE4'}} onClick={()=>{
                    navigate('/blogs')
                }}>View all</button>
            </div>

            <div className="row m-0 container mx-auto py-5">
      
        <div className="col-lg-4 col-md-6 mb-4 pb-3 ps-md-0">
        <div class="card border-0">
  <img src={require('../../images/blogs/Container.png')} class="card-img-top blog-img" alt="..."/>
  <div class="card-body px-0 pt-4">
    <p><span style={{fontSize:'13px'}} className='fw-medium'>Travel</span><span style={{fontSize:'13px'}} className='text-secondary ms-3'>24 August 2024</span></p>
    <h5 class="card-title fw-bold" style={{height:'50px'}}>Train Or Bus Journey?Which one suits?</h5>
    <p class="card-text" style={{fontSize:'14px',height:'63px'}}>The choice between a train or bus journey depends on various factors such as the distance of the journey, the time available, the cost, and person </p>
    <a href="" class="fw-bold" onClick={()=>{
        navigate('/view_blog')
    }} style={{color:'#7C4EE4'}}>Read more...</a>
  </div>
</div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4 pb-3">
        <div class="card border-0">
  <img src={require('../../images/blogs/Container (1).png')} class="card-img-top blog-img" alt="..."/>
  <div class="card-body px-0 pt-4">
    <p><span style={{fontSize:'13px'}} className='fw-medium'>Travel</span><span style={{fontSize:'13px'}} className='text-secondary ms-3'>24 August 2024</span></p>
    <h5 class="card-title fw-bold" style={{height:'50px'}}>Train Or Bus Journey?Which one suits?</h5>
    <p class="card-text" style={{fontSize:'14px',height:'63px'}}>The choice between a train or bus journey depends on various factors such as the distance of the journey, the time available, the cost, and person </p>
    <a href="" class="fw-bold" style={{color:'#7C4EE4'}} onClick={()=>{
        navigate('/view_blog')
    }}>Read more...</a>
  </div>
</div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4 pb-3 pe-md-0">
        <div class="card border-0">
  <img src={require('../../images/blogs/Container (5).png')} class="card-img-top blog-img" alt="..."/>
  <div class="card-body px-0 pt-4">
    <p><span style={{fontSize:'13px'}} className='fw-medium'>Travel</span><span style={{fontSize:'13px'}} className='text-secondary ms-3'>24 August 2024</span></p>
    <h5 class="card-title fw-bold" style={{height:'50px'}}>Train Or Bus Journey?Which one suits?</h5>
    <p class="card-text" style={{fontSize:'14px',height:'63px'}}>The choice between a train or bus journey depends on various factors such as the distance of the journey, the time available, the cost, and person </p>
    <a href="" class="fw-bold" style={{color:'#7C4EE4'}} onClick={()=>{
        navigate('/view_blog')
    }}>Read more...</a>
  </div>
</div>
        </div>
      </div>
        </div>
      </div>

      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
  )
}

export default View_blog
