import React from 'react'

const Work_svg1 = () => {
  return (
    <div>
      <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="72" height="72" rx="36" fill="currentColor"/>
<path d="M42.6663 29.3342L19.333 52.6675M45.1663 41.0009H30.9997M49.733 36.4009C51.6094 34.5245 52.6636 31.9795 52.6636 29.3259C52.6636 26.6722 51.6094 24.1273 49.733 22.2509C47.8566 20.3745 45.3116 19.3203 42.658 19.3203C40.0044 19.3203 37.4594 20.3745 35.583 22.2509L24.333 33.5009V47.6675H38.4997L49.733 36.4009Z" stroke="#F9F9F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_537_2152" x1="15.8327" y1="6.1887" x2="-5.1006" y2="101.791" gradientUnits="userSpaceOnUse">
<stop stop-color="#7C00D9"/>
<stop offset="0.565343" stop-color="#4600B1"/>
<stop offset="1" stop-color="#141778"/>
</linearGradient>
</defs>
</svg>

    </div>
  )
}

export default Work_svg1
