import React from 'react'

const FAQ_svg1 = () => {
  return (
    <div>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.865307" y="1.12996" width="42.441" height="42.441" rx="21.2205" fill="#8236C8"/>
<rect x="0.865307" y="1.12996" width="42.441" height="42.441" rx="21.2205" stroke="#8236C8" stroke-width="0.832176"/>
<path d="M29.8517 22.5451V24.1956C29.8517 27.2539 28.1041 28.5646 25.4827 28.5646H16.7449C14.1235 28.5646 12.376 27.2539 12.376 24.1956V18.9529C12.376 15.8947 14.1235 14.584 16.7449 14.584H19.3663C19.24 14.9529 19.1721 15.3607 19.1721 15.7976V19.584C19.1721 20.5257 19.4828 21.3218 20.0362 21.8752C20.5896 22.4286 21.3857 22.7393 22.3274 22.7393V24.0889C22.3274 24.584 22.8905 24.8849 23.308 24.613L26.1138 22.7393H28.6381C29.075 22.7393 29.4827 22.6713 29.8517 22.5451Z" stroke="#F9F9F9" stroke-width="1.66435" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.7942 15.7969V19.5834C31.7942 21.03 31.0563 22.1173 29.8524 22.5444C29.4835 22.6707 29.0757 22.7387 28.6389 22.7387H26.1146L23.3088 24.6124C22.8913 24.8843 22.3282 24.5834 22.3282 24.0882V22.7387C21.3864 22.7387 20.5903 22.428 20.0369 21.8746C19.4835 21.3212 19.1729 20.5251 19.1729 19.5834V15.7969C19.1729 15.36 19.2408 14.9523 19.367 14.5833C19.7942 13.3795 20.8816 12.6416 22.3282 12.6416H28.6389C30.5321 12.6416 31.7942 13.9037 31.7942 15.7969Z" stroke="#F9F9F9" stroke-width="1.66435" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.6191 32.0596H24.6094" stroke="#F9F9F9" stroke-width="1.66435" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.1143 28.5645V32.0596" stroke="#F9F9F9" stroke-width="1.66435" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.3912 17.7393H28.4" stroke="#F9F9F9" stroke-width="1.66435" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.6725 17.7393H25.6812" stroke="#F9F9F9" stroke-width="1.66435" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.9547 17.7393H22.9634" stroke="#F9F9F9" stroke-width="1.66435" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    </div>
  )
}

export default FAQ_svg1
