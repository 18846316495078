import React from 'react'
import FAQ_svg1 from './Subject_svgs/FAQ_svg1'
import { Accordion } from 'react-bootstrap'

const FAQ = () => {
  return (
    <div>
      <div className='py-5'>
        <div className="row m-0 container mx-auto py-5">
          <div className="col-lg-5">
            <p className='fw-medium' style={{fontSize:'14px',color:'#8236C8'}}>FAQ</p>
            <p className="fw-bold fs-1 m-0" style={{lineHeight:'50px'}}>
            Frequently Asked Questions
            </p>

            <div className='d-flex align-items-center px-2 px-md-3 py-3 mt-5' style={{backgroundColor:'#EEEEFB'}}>
              <FAQ_svg1/>
              <div className='ms-3'>
                <p className='fw-bold m-0'>Contact Live Chat Support</p>
                <p className='text-secondary m-0'>24/7 Available No Chatbots</p>
              </div>
            </div>
            <div className='d-flex align-items-center px-2 px-md-3 py-3 mt-3' style={{backgroundColor:'#EEEEFB'}}>
              <FAQ_svg1/>
              <div className='ms-3'>
                <p className='fw-bold m-0'>Contact Live Chat Support</p>
                <p className='text-secondary m-0'>24/7 Available No Chatbots</p>
              </div>
            </div>
            <div className='d-flex align-items-center px-2 px-md-3 py-3 mt-3' style={{backgroundColor:'#EEEEFB'}}>
              <FAQ_svg1/>
              <div className='ms-3'>
                <p className='fw-bold m-0'>Contact Live Chat Support</p>
                <p className='text-secondary m-0'>24/7 Available No Chatbots</p>
              </div>
            </div>
          </div>

          <div className="col-lg-7 ps-md-4 pt-4">
          <Accordion className='bg-transparent'>
      <Accordion.Item eventKey="0" className='accordion-item'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>How does it work?</p></Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>Do you have any discounts?</p></Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>Do you have any discounts?</p></Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>Do you have any discounts?</p></Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>Do you have any discounts?</p></Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
