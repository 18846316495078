import React, { useEffect, useState } from 'react'
import Language_svg1 from './Svgs/Language_svg1'
import Language_svg2 from './Svgs/Language_svg2'
import Blogs from '../Subjects/Blogs'
import Layout8 from '../Subjects/Layout8'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import FAQ from '../Subjects/FAQ'
import Navbar from '../Navbar'
import What_will_you_be from '../What_will_you_be'
import Assessment from '../Assessment'
import Benefits_to_student from '../Benefits_to_student'
import { languages } from '../../Contents/Languages'
import { useParams } from 'react-router-dom'

const Lnguage_page = () => {

  const {slug}=useParams()

  const[language_data,setlanguage_data]=useState({})

  useEffect(()=>{
    const data=languages.find(x=>x.slug===slug)
    console.log("Language details",data)
    setlanguage_data(data)
  },[])

return (
    <div className='animate__animated animate__fadeIn'>
        <div className='language-div pb-5'>
          <Navbar value={"language"}/>
        
        <div className="row m-0 mx-auto mt-3">
            <div className="col-md-5 ms-auto mt-md-4">
               <div className="ms-5">
                 <p className='language-text1 m-0'>Unleash Your Potential with</p>
                <p className='language-text2 m-0'>Innovative</p>
                <p className='language-text2 m-0'>Solutions</p>
                <p className='mt-5 mb-3 pt-4 m-0 text-white'>Our cutting-edge services empower businesses to achieve remarkable growth and success. Discover how we can transform your vision into reality.</p>
                <button className='mt-3 btn text-dark px-4 fw-bold' style={{backgroundColor:'#ffff',fontSize:'13px',height:'50px',borderRadius:'10px'}}>Explore Services</button>
                <button className='mt-3 btn text-white fw-bold border px-4 ms-3' style={{backgroundColor:'transparent',fontSize:'13px',height:'50px',borderRadius:'10px'}}>Learn More</button>
               </div>
            </div>
            <div className="col-md-6 pe-md-0 text-end ms-auto">
              {/* <img src={require('../../images/online/image-new.png')} style={{width:'88%'}} alt="" /> */}
            </div>
        </div>
      </div>

      {/* Layout for values */}
      <div className="language-content-div">
      <div className="row m-0 container mx-auto align-items-center h-100">
                <div className="col-md-2 ps-md-0">
                    <div>
                        <p className='fw-bolder text-dark fs-3 m-0'>500+</p>
                        <p style={{fontSize:'14px'}}>Projects Delivered </p>
                    </div>
                </div>
                <div className="col-md-2">
                    <div>
                        <p className='fw-bolder text-dark fs-3 m-0'>99%</p>
                        <p style={{fontSize:'14px'}}>Client Satisfaction</p>
                    </div>
                </div>
                <div className="col-md-2 pe-md-0">
                    <div>
                        <p className='fw-bolder text-dark fs-3 m-0'>1000+</p>
                        <p style={{fontSize:'14px'}}>Industry Experts</p>
                    </div>
                </div>
                <div className="col-md-2 pe-md-0">
                    <div>
                        <p className='fw-bolder text-dark fs-3 m-0'>30+</p>
                        <p style={{fontSize:'14px'}}>Industry Awards</p>
                    </div>
                </div>
            </div>
      </div>




      <div className="row m-0 container mx-auto py-5 my-4">
        <p className='fw-bold fs-1 text-center'>{language_data.name} Language</p>
       
       {language_data.para && language_data.para.map((x,index)=>{
        return(
          <>
           <p className='text-center'>{x}</p>
          </>
        )
       })}
        
        <div className="col-lg-6 mt-5">
         {language_data.para1 && language_data.para1.map((x)=>{
          return(
            <>
             <p className='fw-bold fs-3'>{x.head}</p>
             <p>{x.para}</p>
            </>
          )
         })}
         </div>

        <div className="col-lg-6 mt-5 text-center">
          <img src={require('../../images/language/Rectangle 3.png')} style={{width:'60%'}} alt="" />
        </div>
      </div>

      <div className="row container mx-auto m-0 pb-5 mb-4">
        <Benefits_to_student/>
      </div>

      {/* Layout2 */}
      <div className="language-layout2 py-5">
        <div className="row m-0 container mx-auto align-items-center h-100">
            <div className="col-lg-6">
               <div className='d-flex align-items-start'>
               <img src={require('../../images/language/Rectangle 4.png')} style={{width:'40%'}} alt="" />
               <img src={require('../../images/language/Rectangle 3.png')} style={{width:'50%'}} className='ms-4' alt="" />   
               </div>
            </div>
            <div className="col-lg-6 ps-md-5">
                <p className='fw-bold fs-1 m-0'>Simple & fast international payment</p>
                <p className='text-secondary mt-2'>Use receiving accounts in a growing number of currencies and get paid like a local. Pay freelancer and customers anywhere in the world straight.</p>

                <p className='fw-medium mt-5'>“Transferring is very easy and super fast. I find the different way from Coca Pay. Perfect payment app for all”</p>

                <div className='d-flex align-items-center'>
                    <img src={require('../../images/page1/Group 1171276044.png')} width={50} alt="" />
                    <div className='ms-2'>
                        <p className='fw-medium m-0'>Jesica Azalea</p>
                        <p className='text-secondary m-0'>Director of Technology, Sans Bothers</p>
                    </div>
                </div>
            </div>
        </div>
      </div>

      {/* Layout3 */}
      <div className="row m-0 container mx-auto py-5 my-4 align-items-start">
        <div className="col-md-5 pe-md-5">
          <img src={require('../../images/student/fixed-width fixed-height sec-.png')} alt="" />
          {language_data.para2 && language_data.para2.map((x)=>{
            return(
              <>
              <p className='fw-bolder fs-1 m-0 mt-3' style={{lineHeight:'50px'}}>{x.head}</p>
          <p className='text-secondary mt-4 mb-4'>{x.para}</p>
              </>
            )
          })}
        </div>
        <div className="col-md-6 ms-auto ps-md-5">
          <img src={require('../../images/language/Rectangle 2.png')} style={{width:'90%'}}  alt="" />
          <div className='mt-4 d-flex align-items-start'>
          <img src={require('../../images/language/Rectangle 4.png')} style={{width:'80%'}}  alt="" />
          <img src={require('../../images/language/Rectangle 3.png')} style={{width:'60%'}} className='ms-4 d-none d-lg-inline' alt="" />
          </div>
        </div>
      </div>

      {/* Layout4 */}
      <div className="py-5">
        <div className="row m-0 container mx-auto justify-content-between py-5">
            <p className='fs-1 fw-bold text-center mb-5'>Get started spending with your card in minutes.</p>
            <div className="col-lg-3">
                <div className='border p-3 shadow' style={{borderRadius:'15px'}}>
                    <p className='fs-1 fw-bold m-0 mb-2'>909K</p>
                    <p style={{color:'#757B8A'}}>Crowdholding accepts a variety of payments.</p>
                </div>
            </div>
            <div className="col-lg-3">
                <div className='border p-3 shadow' style={{borderRadius:'15px'}}>
                    <p className='fs-1 fw-bold m-0 mb-2'>89%</p>
                    <p style={{color:'#757B8A'}}>Payment with us is easy and straight forward</p>
                </div>
            </div>
            <div className="col-lg-3">
                <div className='border p-3 shadow' style={{borderRadius:'15px'}}>
                    <p className='fs-1 fw-bold m-0 mb-2'>4583+</p>
                    <p style={{color:'#757B8A'}}>Crowdholding accepts a variety of payments.</p>
                </div>
            </div>

        </div>
      </div>

      {/* Lyout5 */}
      <div className="py-5">
        <div className="row m-0 pb-5 container mx-auto">
            <div className="col-lg-6 pe-md-5">
            <img src={require('../../images/student/fixed-width fixed-height sec-.png')} alt="" />
          <p className='fw-bolder fs-1 m-0'>Watch our</p>
          <p className='fw-bolder fs-1' style={{lineHeight:'48px'}}>Courses</p>
          <p className='text-secondary'>Lorem ipsum dolor sit amet consectetur. At euismod non ultrices placerat augue nibh. Nibh sodales adipiscing quam quis. Pretium eget ac vitae neque vulputate accumsan elit cum consequat.
          </p>
            </div>

            <div className="col-lg-6 ps-md-5">
                <div className="row m-0 w-100">
                   
          <div className="col-md-6 mb-4 mb-md-0">
            <div className='px-2 px-md-5 py-4 shadow-sm' style={{borderRadius:'15px'}}>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Language_svg2/></p>
              {/* <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p> */}
              </div>

              <p className='fw-bold m-0 mt-4'>Lifetime access</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

          <div className="col-md-6">
            <div className='px-2 px-md-5 py-4 shadow-sm' style={{borderRadius:'15px'}}>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Language_svg1/></p>
              {/* <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p> */}
              </div>

              <p className='fw-bold m-0 mt-4'>Training Courses</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>
                </div>
            </div>
        </div>
      </div>

      <What_will_you_be/>
      <Assessment/>
      <Blogs/>
      <Layout8/>
      {/* FAQ */}
      <FAQ/>
      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
  )
}

export default Lnguage_page
