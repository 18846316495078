import React from 'react'

const Sunject_svg2 = () => {
  return (
    <div>
      <svg width="66" height="67" viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.75988 13.3467C11.891 7.76325 17.822 4.58019 24.5467 2.8314C32.786 0.688747 41.6047 -1.84784 48.8263 2.66724C57.7922 8.27289 66.4231 17.6471 64.9871 28.1156C63.5861 38.3286 50.948 41.0953 42.7056 47.2866C33.3624 54.3047 26.215 68.8051 14.9394 65.7086C3.62223 62.6008 1.86945 47.2567 0.275929 35.6347C-0.844441 27.4636 2.85585 19.9748 7.75988 13.3467Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Sunject_svg2
