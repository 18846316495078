export const offline={
    para:[
        {
            head:"Experience Learning in Person",
            para:"In-person learning isn’t just about sitting and listening. It allows you to fully engage with your tutor and peers, creating a dynamic and interactive environment. You’ll benefit from real-time feedback and immediate answers to your questions. No need to worry about technical issues or internet connectivity, just a focused and distraction-free learning space is what you get. This personal connection not only enhances understanding but also makes learning more enjoyable. You’ll feel the energy and enthusiasm of the classroom, which keeps you motivated and excited about your studies. Enjoy the benefits of offline learning where every session counts!"
        },

        {
            head:"Personal Interaction for Better Understanding",
            para:"There’s nothing like the personal touch of learning in person. Our offline mode offers direct interaction with your tutor, allowing for a tailored educational experience. Ask questions, discuss ideas, and receive instant explanations. This quick and personal feedback makes it easier to understand complex concepts. Plus, engaging with fellow students face-to-face can lead to meaningful discussions and collaborations. This supportive environment is ideal for those who thrive on personal connections and prefer a hands-on approach to learning. It’s all about making sure you feel confident and supported every step of the way."
        },

        {
            head:"Focused Learning Without Distractions",
            para:"In an offline setting, you’re immersed in a dedicated learning environment free from digital distractions. Say goodbye to the interruptions of online notifications and hello to a space where you can concentrate fully on your studies. The structured class schedule helps maintain a steady rhythm and discipline, ensuring you stay on track with your learning goals. The physical classroom setting encourages a focused mindset and active participation, which enhances your overall learning experience. Enjoy the benefits of a well-organized space designed to foster productivity and keep you engaged."
        },

        {
            head:"Real-Time Support and Feedback",
            para:"Benefit from the instant support and feedback that comes with offline learning. Our dedicated tutors are right there with you, offering guidance and answering questions as they arise. This real-time interaction allows for a more responsive and adaptive learning experience. Whether you need clarification on a tricky topic or extra help with an assignment, our tutors are available to assist you promptly. This direct support helps you stay motivated and ensures you understand each concept thoroughly. With personalized attention, you’re always in good hands, making your learning journey smooth and effective."
        },
    ]
}