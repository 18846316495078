export const online={
    para:[
        {
            head:"Learn Anytime, Anywhere",
            para:"With Merida Tuition Tutor’s online mode, learning has never been more flexible! Study from the comfort of your home or any place you prefer. You have the freedom to choose your own study time, whether it's first thing in the morning or later in the evening. This flexibility lets you learn at your own speed, free from traditional classroom limits. You don’t have to travel or adapt to fixed timings, which makes online learning incredibly convenient for students of all ages. It’s perfect for balancing your academic goals with your personal life, making learning enjoyable and stress-free."
        },

        {
            head:"Personalized Learning for You",
            para:"Our online mode of training is designed to fit your unique learning style. We understand that some topics might need a little more time, while others may just require a quick review. That’s why our online training is customized to suit your individual learning style. Our tutors offer personalized one-on-one sessions to give you the focused support you need to truly understand every concept. If you ever feel stuck, we’re right there to help clear up any doubts instantly. This personalized approach not only makes learning more effective but also more enjoyable, helping you build confidence as you progress."
        },

        {
            head:"Engaging, Interactive Lessons",
            para:"Online learning doesn’t mean boring lectures! That’s why our lessons are packed with interactive tools, live discussions, and engaging activities that make studying a dynamic experience. We keep you involved with quizzes, discussions, and real-time feedback to ensure you're fully engaged in each session. Our goal is to make sure you enjoy every moment of learning while mastering even the most challenging topics. With a perfect blend of creativity and education, our approach keeps you motivated and eager to learn more. We make sure every lesson is lively and fun, so you stay excited about your studies and progress effectively."
        },

        {
            head:"Easy Progress Tracking",
            para:"Staying on top of your progress is key to achieving your academic goals, and we make that easy. With our online mode of learning, you’ll receive regular feedback and progress reports, so you know exactly how you’re doing. These reports help both students and parents stay informed and involved in the learning journey. By providing clear insights into your strengths and areas for improvement, we make it easier to stay organized and on track. Our step-by-step approach ensures that you continue to grow, with every lesson leading you closer to your academic success. "
        },
    ]
}