import React from 'react'

const Svg3 = () => {
  return (
    <div>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_53_545)">
<path d="M15.9979 7.43893C15.874 5.60792 15.151 3.92548 13.9069 2.57351C13.8094 2.46748 13.6443 2.4606 13.5383 2.5582C13.4323 2.65576 13.4254 2.82076 13.523 2.92679C14.6224 4.12164 15.2865 5.59248 15.4535 7.19567H11.402C11.1 5.90177 10.0861 4.89161 8.79122 4.59436V0.544755C10.0881 0.678911 11.323 1.14785 12.3781 1.90935C12.4949 1.99363 12.658 1.96738 12.7423 1.85054C12.8266 1.73366 12.8003 1.57063 12.6835 1.48632C11.4737 0.613161 10.0436 0.0994099 8.54756 0.000565932C8.47559 -0.00418408 8.40481 0.0211285 8.35213 0.0704098C8.29941 0.119722 8.26953 0.188723 8.26953 0.260879V4.80886C8.26953 4.93664 8.36216 5.04567 8.48831 5.0663C9.73991 5.27089 10.7216 6.24877 10.9311 7.49964C10.9521 7.62536 11.0609 7.71743 11.1883 7.71743H15.7376C15.8099 7.71743 15.8789 7.68746 15.9283 7.63464C15.9775 7.58183 16.0028 7.51099 15.9979 7.43893Z" fill="currentColor"/>
<path d="M15.9293 8.32267C15.88 8.27008 15.8111 8.24023 15.7389 8.24023H11.1923C11.064 8.24023 10.9548 8.33339 10.9347 8.46002C10.8632 8.90849 10.685 9.34155 10.4194 9.71246C10.3451 9.81621 10.3567 9.95855 10.447 10.0488L13.6645 13.2663C13.7135 13.3153 13.7799 13.3427 13.849 13.3427C13.8519 13.3427 13.8549 13.3426 13.8578 13.3426C13.93 13.3401 13.9979 13.3078 14.0454 13.2535C15.2097 11.9214 15.8854 10.2838 15.9993 8.51786C16.0039 8.44595 15.9786 8.3753 15.9293 8.32267ZM13.8326 12.6965L10.9673 9.83111C11.1721 9.50211 11.3216 9.13883 11.4074 8.76195H15.4557C15.3089 10.2138 14.752 11.5638 13.8326 12.6965Z" fill="currentColor"/>
<path d="M13.2963 13.6355L10.08 10.4192C9.9894 10.3286 9.8464 10.3172 9.74252 10.3924C9.23493 10.76 8.6353 10.9543 8.00848 10.9543C6.37439 10.9543 5.04498 9.62487 5.04498 7.99081C5.04498 6.53152 6.08951 5.30162 7.52867 5.06637C7.65483 5.04577 7.74745 4.93677 7.74745 4.80893V0.260918C7.74745 0.188699 7.71755 0.119761 7.66486 0.0704488C7.61217 0.0211362 7.54155 -0.00433262 7.46939 0.00060489C5.53848 0.128199 3.71885 0.954763 2.34563 2.32798C0.833033 3.84055 0 5.85165 0 7.99081C0 10.13 0.833033 12.1411 2.34563 13.6537C3.85823 15.1663 5.86932 15.9993 8.00848 15.9993C9.95371 15.9993 11.8272 15.295 13.284 14.0161C13.3382 13.9684 13.3702 13.9006 13.3726 13.8285C13.375 13.7564 13.3473 13.6866 13.2963 13.6355ZM8.00848 15.4775C6.0087 15.4775 4.1286 14.6988 2.71454 13.2847C1.3005 11.8707 0.521751 9.99059 0.521751 7.99081C0.521751 5.99102 1.3005 4.11096 2.71457 2.69692C3.9322 1.47926 5.52498 0.721419 7.22567 0.544825V4.59452C6.50511 4.7609 5.85029 5.15643 5.36339 5.72368C4.8216 6.35487 4.5232 7.15996 4.5232 7.99081C4.5232 9.91259 6.0867 11.4761 8.00845 11.4761C8.67099 11.4761 9.30771 11.2915 9.8633 10.9404L12.7268 13.8039C11.3981 14.8851 9.73352 15.4775 8.00848 15.4775Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_53_545">
<rect width="16" height="16" fill="currentColor"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default Svg3
