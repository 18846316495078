import React from 'react'
import Slider from 'react-slick'

const Blogs = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows:false,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        cssEase: "linear",
        className: "center",
        // centerMode: true,
        // centerPadding: "60px",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

  return (
    <div>
      <div className="layout7 py-5">
        <p className='fs-1 fw-bold text-center pb-5'>Our Blogs</p>
        <div className='container pb-4'>
        <div className="slider-container">
      <Slider {...settings}>
       <div className="col-md-4 px-3">
          <div class="card cards">
  <img src={require('../../images/student/label-outline-left-icon.png')} class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title fw-bold">User Analytics, User AnalyticsUser Analytics</h5>
    <p class="card-text mt-3" style={{fontSize:'13px'}}>Aliquam sed sapien congue dui laoreet hendrerit eget ac dolor.Aliquam....</p>
    <a href="#" class="btn layout7-btn text-white w-100 mt-5">Go somewhere</a>
  </div>
</div>
          </div>
          <div className="col-md-4 px-3">
          <div class="card cards">
  <img src={require('../../images/student/label-outline-left-icon.png')} class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title fw-bold">User Analytics, User AnalyticsUser Analytics</h5>
    <p class="card-text mt-3" style={{fontSize:'13px'}}>Aliquam sed sapien congue dui laoreet hendrerit eget ac dolor.Aliquam....</p>
    <a href="#" class="btn layout7-btn text-white w-100 mt-5">Go somewhere</a>
  </div>
</div>
          </div>
          <div className="col-md-4 px-3">
          <div class="card cards">
  <img src={require('../../images/student/label-outline-left-icon.png')} class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title fw-bold">User Analytics, User AnalyticsUser Analytics</h5>
    <p class="card-text mt-3" style={{fontSize:'13px'}}>Aliquam sed sapien congue dui laoreet hendrerit eget ac dolor.Aliquam....</p>
    <a href="#" class="btn layout7-btn text-white w-100 mt-5">Go somewhere</a>
  </div>
</div>
          </div>

      </Slider>
    </div>
         
        </div>
      </div>
    </div>
  )
}

export default Blogs
