import React from 'react'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import Navbar from '../Navbar'

const Events = () => {
  return (
    <div className='animate__animated animate__fadeIn'>
      <div className="event-div">
        <Navbar value={"events"} color={"#0A0F12"}/>
            <div className="row m-0 container mx-auto mt-4">
                <div className="col-md-6 event-div1">
                    <p className='event-text'>Resume Building</p>
                    <p className='fw-bold bg-white p-2 event-text2'>26TH AUG</p>
                    <p className='event-text3'>2024</p>
                </div>
            </div>
      </div>

{/* Layout2 */}
      <div className="row m-0 container mx-auto py-5 my-4">
        <div className="col-lg-6">
            <img src={require('../../images/event/Rectangle 5.png')} style={{width:'100%'}} alt="" />
            <p className='mt-5'>Meet with brilliant minds from tech startup business all over the world and share
ideas, network and grow together with the community good working in the world
here beautiful agent and very good cool work agency event organizer</p>

<button style={{backgroundColor:'#624DE3'}} className='btn text-white px-4 py-2 mt-3'>Join Now</button>
        </div>

        <div className="col-lg-5 ms-auto">
            <p className='fs-1 text-dark' style={{fontWeight:800}}>Join Jazz Festival
            Conference 2021</p>
            <img className='mt-4' src={require('../../images/event/Rectangle 6.png')} style={{width:'100%'}} alt="" />
        </div>
      </div>

{/* Layout3 */}
<div className="row m-0 container mx-auto py-5 my-4">
        <div className="col-lg-5">
            <p className='fs-1 text-dark' style={{fontWeight:800}}>Discover And Find
            Beautiful Venue</p>
            <img className='mt-4' src={require('../../images/event/Rectangle 6 (1).png')} style={{width:'100%'}} alt="" />
        </div>
        <div className="col-lg-6 ms-auto">
            <img src={require('../../images/event/Rectangle 5 (1).png')} style={{width:'100%'}} alt="" />
            <p className='mt-5'>Meet with brilliant minds from tech startup business all over the world and share
ideas, network and grow together with the community good working in the world
here beautiful agent and very good cool work agency event organizer</p>

<button style={{backgroundColor:'#624DE3'}} className='btn text-white px-4 py-2 mt-1'>Join Now</button>
        </div>
      </div>

{/* Layout4 */}
<div style={{backgroundColor:'#F2F6FB'}} className='py-5'>
<div className="row m-0 container mx-auto">
<p className='fs-1 fw-bolder text-center'>Upcoming Events</p>

<div className="col-lg-4 col-md-6 mb-4">
    <div>
    <div class="card p-4 border-0 shadow-sm">
  <img src={require('../../images/event/Rectangle 13.png')} height={240} class="card-img-top" alt="..."/>
  <div class="card-body d-flex align-items-center px-0 pb-0 mt-3">
  <div className=''>
    <p className='m-0 fs-6 fw-medium'>AUG</p>
    <p className="m-0 fw-bold fs-3" style={{color:'#C4C4C4'}}>26</p>
  </div>
  <div className='ms-4 ps-2'>
    <p className='fw-medium m-0 fs-4'>Konser Musik Jazz</p>
    <p className=' m-0 fs-6 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg><span className='ms-2' style={{color:'#666666'}}>Jakarta, Indonesia</span></p>
  </div>
  </div>
</div>
    </div>
</div>

<div className="col-lg-4 col-md-6 mb-4">
    <div>
    <div class="card p-4 border-0 shadow-sm">
  <img src={require('../../images/event/Rectangle 13.png')} height={240} class="card-img-top" alt="..."/>
  <div class="card-body d-flex align-items-center px-0 pb-0 mt-3">
  <div className=''>
    <p className='m-0 fs-6 fw-medium'>AUG</p>
    <p className="m-0 fw-bold fs-3" style={{color:'#C4C4C4'}}>26</p>
  </div>
  <div className='ms-4 ps-2'>
    <p className='fw-medium m-0 fs-4'>Konser Musik Jazz</p>
    <p className=' m-0 fs-6 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg><span className='ms-2' style={{color:'#666666'}}>Jakarta, Indonesia</span></p>
  </div>
  </div>
</div>
    </div>
</div>

<div className="col-lg-4 col-md-6 mb-4">
    <div>
    <div class="card p-4 border-0 shadow-sm">
  <img src={require('../../images/event/Rectangle 13.png')} height={240} class="card-img-top" alt="..."/>
  <div class="card-body d-flex align-items-center px-0 pb-0 mt-3">
  <div className=''>
    <p className='m-0 fs-6 fw-medium'>AUG</p>
    <p className="m-0 fw-bold fs-3" style={{color:'#C4C4C4'}}>26</p>
  </div>
  <div className='ms-4 ps-2'>
    <p className='fw-medium m-0 fs-4'>Konser Musik Jazz</p>
    <p className=' m-0 fs-6 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg><span className='ms-2' style={{color:'#666666'}}>Jakarta, Indonesia</span></p>
  </div>
  </div>
</div>
    </div>
</div>

<div className="col-lg-4 col-md-6 mb-4">
    <div>
    <div class="card p-4 border-0 shadow-sm">
  <img src={require('../../images/event/Rectangle 13.png')} height={240} class="card-img-top" alt="..."/>
  <div class="card-body d-flex align-items-center px-0 pb-0 mt-3">
  <div className=''>
    <p className='m-0 fs-6 fw-medium'>AUG</p>
    <p className="m-0 fw-bold fs-3" style={{color:'#C4C4C4'}}>26</p>
  </div>
  <div className='ms-4 ps-2'>
    <p className='fw-medium m-0 fs-4'>Konser Musik Jazz</p>
    <p className=' m-0 fs-6 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg><span className='ms-2' style={{color:'#666666'}}>Jakarta, Indonesia</span></p>
  </div>
  </div>
</div>
    </div>
</div>

<div className="col-lg-4 col-md-6 mb-4">
    <div>
    <div class="card p-4 border-0 shadow-sm">
  <img src={require('../../images/event/Rectangle 13.png')} height={240} class="card-img-top" alt="..."/>
  <div class="card-body d-flex align-items-center px-0 pb-0 mt-3">
  <div className=''>
    <p className='m-0 fs-6 fw-medium'>AUG</p>
    <p className="m-0 fw-bold fs-3" style={{color:'#C4C4C4'}}>26</p>
  </div>
  <div className='ms-4 ps-2'>
    <p className='fw-medium m-0 fs-4'>Konser Musik Jazz</p>
    <p className=' m-0 fs-6 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg><span className='ms-2' style={{color:'#666666'}}>Jakarta, Indonesia</span></p>
  </div>
  </div>
</div>
    </div>
</div>

<div className="col-lg-4 col-md-6 mb-4">
    <div>
    <div class="card p-4 border-0 shadow-sm">
  <img src={require('../../images/event/Rectangle 13.png')} height={240} class="card-img-top" alt="..."/>
  <div class="card-body d-flex align-items-center px-0 pb-0 mt-3">
  <div className=''>
    <p className='m-0 fs-6 fw-medium'>AUG</p>
    <p className="m-0 fw-bold fs-3" style={{color:'#C4C4C4'}}>26</p>
  </div>
  <div className='ms-4 ps-2'>
    <p className='fw-medium m-0 fs-4'>Konser Musik Jazz</p>
    <p className=' m-0 fs-6 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg><span className='ms-2' style={{color:'#666666'}}>Jakarta, Indonesia</span></p>
  </div>
  </div>
</div>
    </div>
</div>
</div>
<button className='btn px-5 py-2 fw-bold d-block mx-auto mt-4' style={{color:'#624DE3',border:'1px solid #624DE3'}}>See All</button>
</div>

<Main_footer/>
<Backtotop/>
<Scrolltotop/>
    </div>
  )
}

export default Events
