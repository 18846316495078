import React from 'react'

const Svg2 = () => {
  return (
    <div>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6562 8.37998H12.0901C11.8769 8.37998 11.6705 8.40911 11.4741 8.46267C11.0867 7.70105 10.2957 7.17773 9.38422 7.17773H6.61578C5.70431 7.17773 4.91331 7.70105 4.52588 8.46267C4.32947 8.40911 4.12309 8.37998 3.90994 8.37998H2.34375C1.05141 8.37998 0 9.43139 0 10.7237V13.2274C0 14.0028 0.630844 14.6337 1.40625 14.6337H14.5938C15.3692 14.6337 16 14.0028 16 13.2274V10.7237C16 9.43139 14.9486 8.37998 13.6562 8.37998ZM4.27203 9.52155V13.6962H1.40625C1.14778 13.6962 0.9375 13.4859 0.9375 13.2275V10.7238C0.9375 9.94836 1.56834 9.31752 2.34375 9.31752H3.90994C4.03719 9.31752 4.16041 9.3348 4.27772 9.36664C4.27434 9.41792 4.27203 9.46948 4.27203 9.52155ZM10.7905 13.6962H5.20953V9.52152C5.20953 8.74611 5.84037 8.11527 6.61578 8.11527H9.38422C10.1596 8.11527 10.7905 8.74611 10.7905 9.52152V13.6962ZM15.0625 13.2275C15.0625 13.4859 14.8522 13.6962 14.5938 13.6962H11.728V9.52152C11.728 9.46942 11.7257 9.41786 11.7223 9.36661C11.8396 9.3348 11.9628 9.31748 12.0901 9.31748H13.6562C14.4317 9.31748 15.0625 9.94833 15.0625 10.7237V13.2275Z" fill="currentColor"/>
<path d="M3.12663 3.95312C1.97823 3.95312 1.04395 4.88741 1.04395 6.03581C1.04391 7.18422 1.97823 8.1185 3.12663 8.1185C4.27501 8.1185 5.20932 7.18422 5.20932 6.03581C5.20932 4.88741 4.27504 3.95312 3.12663 3.95312ZM3.1266 7.181C2.49513 7.181 1.98141 6.66728 1.98141 6.03581C1.98141 5.40434 2.49513 4.89062 3.1266 4.89062C3.75807 4.89062 4.27179 5.40434 4.27179 6.03581C4.27179 6.66728 3.75807 7.181 3.1266 7.181Z" fill="currentColor"/>
<path d="M8.00021 1.36719C6.46596 1.36719 5.21777 2.61537 5.21777 4.14962C5.21777 5.68388 6.46596 6.93206 8.00021 6.93206C9.53446 6.93206 10.7826 5.68388 10.7826 4.14962C10.7826 2.61541 9.53446 1.36719 8.00021 1.36719ZM8.00021 5.99456C6.9829 5.99456 6.15527 5.16694 6.15527 4.14962C6.15527 3.13234 6.9829 2.30469 8.00021 2.30469C9.01752 2.30469 9.84515 3.13231 9.84515 4.14962C9.84515 5.16694 9.01752 5.99456 8.00021 5.99456Z" fill="currentColor"/>
<path d="M12.8732 3.95312C11.7248 3.95312 10.7905 4.88741 10.7905 6.03581C10.7906 7.18422 11.7248 8.1185 12.8732 8.1185C14.0216 8.1185 14.9559 7.18422 14.9559 6.03581C14.9559 4.88741 14.0216 3.95312 12.8732 3.95312ZM12.8732 7.181C12.2418 7.181 11.728 6.66728 11.728 6.03581C11.7281 5.40434 12.2418 4.89062 12.8732 4.89062C13.5047 4.89062 14.0184 5.40434 14.0184 6.03581C14.0184 6.66728 13.5047 7.181 12.8732 7.181Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Svg2
