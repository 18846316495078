import React from 'react'
import Subject_svg1 from './Subject_svgs/Subject-svg1'
import Sunject_svg2 from './Subject_svgs/Sunject_svg2'
import Subject_svg3 from './Subject_svgs/Subject_svg3'
import Subject_svg4 from './Subject_svgs/Subject_svg4'
import Subject_svg5 from './Subject_svgs/Subject_svg5'
import FAQ_svg1 from './Subject_svgs/FAQ_svg1'
import { Accordion } from 'react-bootstrap'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Blogs from './Blogs'
import Layout8 from './Layout8'
import Navbar from '../Navbar'
import Scrolltotop from '../../Scrolltotop'
import FAQ from './FAQ'
import What_will_you_be from '../What_will_you_be'
import Assessment from '../Assessment'
import Benefits_to_student from '../Benefits_to_student'

const Subject_page = () => {
  return (
    <div className='animate__animated animate__fadeIn'>
      <div className='subject-div1 pb-5'>
        <Navbar value={"subject"} color={'#F4F2F8'}/>
        <div className="row container mx-auto container1">
            <div className="col-md-6 mt-md-4">
                <p className='main-text m-0'><span style={{color:'#8236C8'}} className='main-text'>ICSE</span> Top Teachers</p>
                <p className='main-text m-0' style={{lineHeight:'48px'}}>Are here </p>
                <p className='fs-5 mt-5 m-0'>I am here ready to help you in making creative digital products</p>
                <button className='mt-3 btn text-white rounded-pill py-2 px-4' style={{backgroundColor:'#8236C8',fontSize:'13px'}}>Let's Discuss</button>
            </div>
            <div className="col-md-6 mt-md-4 text-center" style={{position:'relative'}}>
              <img src={require('../../images/student/image.png')} style={{width:'90%'}} alt="" />

              <div className='subject-small-div1 bg-white d-flex align-items-center p-3 shadow' style={{position:'absolute'}}>
                <img src={require('../../images/student/icon (1).png')} alt="" />
                <div className='ms-2'>
                  <p className='text-start fw-bold m-0'>2K+</p>
                  <p className='m-0'>Projects</p>
                </div>
              </div>

              <div className='subject-small-div2 bg-white d-flex align-items-center p-3 shadow' style={{position:'absolute'}}>
                <img src={require('../../images/page1/star.png')} alt="" />
                <div className='ms-2'>
                  <p className='text-start fw-bold m-0'>2K+</p>
                  <p className='m-0'>Projects</p>
                </div>
              </div>

              <div className='subject-small-div3 bg-white d-flex align-items-center p-3 shadow' style={{position:'absolute'}}>
                <img src={require('../../images/student/icon3.png')} alt="" />
                <div className='ms-2'>
                  <p className='text-start fw-bold m-0'>Product Designer</p>
                  <p className='m-0'>5 Years</p>
                </div>
              </div>
            </div>
        </div>

        <div className="row m-0 mx-auto container">
          <p className='main-text fs-3 mb-4'>OUR EXPERTISE</p>
       <div className="col-md-3">
       <div className="">
              <img src={require('../../images/student/Vector.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Branding</p>
              <p className='text-secondary mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
        </div>
        <div className="col-md-3">
       <div className="">
              <img src={require('../../images/student/Ui Design.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>UI/UX</p>
              <p className='text-secondary mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
        </div>
        <div className="col-md-3">
       <div className="">
              <img src={require('../../images/student/Product.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Product Design</p>
              <p className='text-secondary mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
        </div>
       </div>
      </div>

      <div className='container pt-5'>
      <p className='fw-bolder fs-1 m-0 text-center mb-4'>Mathematics Subject</p>
      <p className='text-secondary text-center'>Mathematics is a crucial part of your education and academic success. It also helps you develop problem-solving skills and think logically. At Merida Tuition Tutor, we understand that math can sometimes seem complicated and  even intimidating. That’s why our expert tutors are here to make math easy and enjoyable. They break down tricky topics so you can understand them better and feel confident solving problems. Plus, if you ever have questions or get stuck, we’re here to help anytime. With our flexible doubt-solving sessions, help is always just a question away. Discover how learning math can be an enjoyable and fulfilling experience.</p>

      </div>

      {/* Layout2 */}
      <div className="row m-0 container mx-auto py-5 my-4 align-items-center">
        <div className="col-md-7 ps-md-5">
          <img src={require('../../images/student/Group 1171276064.png')} style={{width:'7  0%'}}  alt="" />
        </div>
        <div className="col-md-5 pe-md-5">
          <img src={require('../../images/student/fixed-width fixed-height sec-.png')} alt="" />
          <p className='fw-bolder fs-1 m-0'>Expert Tutors Making</p>
          <p className='fw-bolder fs-1' style={{lineHeight:'48px'}}>Math Fun</p>
          <p className='text-secondary'>Our highly skilled tutors make math fun and less intimidating. With years of experience and a passion for teaching, they break down complex concepts into easy-to-understand lessons. Whether you're learning algebra, geometry, or calculus, our tutors make every topic engaging and simple to understand. We make learning math fun with simple methods, so you can easily grasp the concepts and actually enjoy solving problems. Our support helps you build strong math skills and boosts your confidence in tackling any problem. With us, math becomes something you look forward to, not something you avoid!
          </p>
        </div>
      </div>

      <div className="row container mx-auto m-0 pb-5 pt-2">
        <Benefits_to_student/>
      </div>

      {/* Layout3 */}
      <div className="row m-0 container mx-auto py-5 my-4 align-items-center">
        <div className="col-lg-6 pe-md-5 mb-5 mb-md-0">
        <p className='fw-bolder fs-1 m-0'>Best ways to send</p>
        <p className='fw-bolder fs-1' style={{lineHeight:'40px'}}>money internationally</p>
        <p className='text-secondary mt-4 pe-md-5'>Join over 12 million people who choose us for fast and secure online payment method . We are simply & cheaper than banks and non-banks.</p>
        <div className='mt-5'>
            <button className='btn fw-medium text-white px-3 py-2' style={{backgroundColor:'#205CE1',fontSize:'14px'}}>Join Now</button>
            <button className='btn fw-medium px-3 py-2 ms-3' style={{border:'1px solid #205CE1',fontSize:'14px',color:'#205ce1'}}>Learn More</button>
          </div>
        </div>
        <div className="col-lg-6 mt-md-5 mt-lg-0">
         <div className="row m-0 justify-content-center">
         <div className="col-md-6">
       <div className="">
              <img src={require('../../images/student/Icon.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Over 15 million customers</p>
              <p className='text-secondary mt-2'>Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
              </div>
        </div>
        <div className="col-md-6">
       <div className="">
              <img src={require('../../images/student/Icon1.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Licensed by Bank Wakanda</p>
              <p className='text-secondary mt-2'>Amet minim mollit non Exercitation veniam consequat sunt nostrud dummy content.</p>
              </div>
        </div>
         </div>
        </div>
      </div>

      {/* Layout4 */}
      <div className='py-5' style={{backgroundColor:'#f7f9ff'}}>
      <div className="row m-0 container mx-auto align-items-center">
<div className="col-md-7 text-center">
<img src={require('../../images/Rectangle 233.png')} style={{width:'60%'}} alt="" />
</div>
<div className="col-md-5">
  <p className='fs-1 fw-bold'>Personalized Learning for Every Student</p>
  <p className='fw-bold'>We get that each student learns in their own unique way.That’s why we create math programs specifically for you.</p>
  <p className='text-secondary mt-4'>We start by looking at what you’re already good at and where you might need more practice. Next, we create a learning plan tailored to your individual needs. This ensures you get the individual support you need to do well. We break down the concepts into simple steps, helping you apply what you learn effectively. With our customized support, math becomes clearer and more enjoyable, helping you achieve your best.</p>
</div>
      </div>
      </div>

      {/* Layout5 */}
      <div className="row m-0 container mx-auto py-5 align-items-center">
<div className="col-md-6 pe-md-5">
  <p className='fs-1 fw-bold' style={{lineHeight:'48px'}}>Simple and Effective Tuition Programs</p>
  <p className='text-secondary mt-4'>Our math tuition programs are designed to be clear and effective. We aim to make learning easy and enjoyable, so you can quickly understand new concepts and build a solid foundation. Our goal is to help you reach your academic goals with as little stress as possible and as much success as possible. With our straightforward teaching methods and supportive environment, math will become a subject you actually enjoy. </p>

  <img src={require('../../images/page1/Group 114.png')} className='d-block mx-auto' style={{width:'80%'}} alt="" />
</div>

<div className="col-md-6 ps-md-5">
  <div className='d-flex align-items-start'>
    <img src={require('../../images/student/Logo.png')} alt="" />
    <div className='ms-4'>
      <p className='m-0 layout5-text'>Faster ways to make transactions</p>
      <p className='mt-3'>By accepting online payments you are making it dead easy for your customers to buy from you.</p>
    </div>
  </div>
  <div className='d-flex align-items-start mt-5'>
    <img src={require('../../images/student/Logo (1).png')} alt="" />
    <div className='ms-4'>
      <p className='m-0 layout5-text'>Payments can be made securely</p>
      <p className='mt-3'>Online payments are easier to process, give you more information online, and make processing faster.</p>
    </div>
  </div>
  <div className='d-flex align-items-start mt-5'>
    <img src={require('../../images/student/Logo (2).png')} alt="" />
    <div className='ms-4'>
      <p className='m-0 layout5-text'>Alternate sources of currency</p>
      <p className='mt-3'>If you don't have time for checkout lines, online payments make it easy to whip out your credit or debit card.</p>
    </div>
  </div>
</div>
      </div>

      {/* Layout6 */}
      <div style={{backgroundColor:'#EDEAFA'}} className='py-5'>
        <div className="row py-5 m-0 container mx-auto">
          <p className='fw-bold' style={{color:'#8236C8'}}>Quick examination</p>
          <p className='fs-1 fw-bold'>Approdable Packages</p>
          <p className='m-0' style={{color:'#737373'}}>Problems trying to resolve the conflict between</p>
          <p className='mb-5 pb-4' style={{color:'#737373'}}>the two major realms of Classical physics: Newtonian mechanics </p>

          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg1/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Training Courses</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg3/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Training Courses</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg4/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Lifetime access</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg5/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Expert instruction</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

        </div>
      </div>

<What_will_you_be/>

<Assessment/>
      {/* Layout7 */}
      <Blogs/>

      {/* Layout8 */}
      <Layout8/>

      {/* FAQ */}
      <FAQ/>

      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
  )
}

export default Subject_page
