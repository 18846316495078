import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Scrolltotop = () => {
    const {path}=useLocation()

    useEffect(()=>{
        window.scrollTo(0,0)
    },[path])

    return false

  return (
    <div>
      
    </div>
  )
}

export default Scrolltotop
