import React from 'react'

const Navbar = () => {
  return (
    <div>
      <div className='' style={{position:'fixed',zIndex:10,marginLeft:'220px'}}>
        
      </div>
    </div>
  )
}

export default Navbar
