import React from 'react'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import Navbar from '../Navbar'

const Contact = () => {
  return (
    <div className='animate__animated animate__fadeIn'>
      <div className='contact'>
        <Navbar value={"contact"} color={"#E4F5FD"}/>
        <div className="row m-0 container container1 mx-auto justify-content-center align-items-center pb-5">
            <div className="col-lg-5 contact-subdiv p-5 mt-5" style={{borderRadius:'15px'}}>
                <div className="">
                <p className='fs-1 fw-bold text-center'>Contact Us</p>
                <p className='text-center'>Aenean sit amet magna vel magna fringilla fermentum. Donec sit amet nulla sed arcu pulvinar commodo id ligula.</p>

                <div className='mt-5'>
                    <input type="text" className='form-control shadow-none border-secondary mb-4 py-2 bg-white' placeholder='Enter your Name' name="" id="" />
                    <input type="text" className='form-control shadow-none border-secondary mb-4 py-2 bg-white' placeholder='Enter your Email' name="" id="" />
                    <input type="text" className='form-control shadow-none border-secondary mb-4 py-2 bg-white' placeholder='Enter your Phone Number' name="" id="" />
                    <input type="text" className='form-control shadow-none border-secondary mb-4 py-2 bg-white' placeholder='Purpose' name="" id="" />
                    <textarea type="text" className='form-control shadow-none border-secondary mb-4 py-2 bg-white' placeholder='Type your message here...' name="" id="" />

                    <button className='btn text-white rounded-pill w-100 mt-4 fw-medium' style={{backgroundColor:'#651FFF',fontSize:'14px',height:'47px'}}>SEND MESSAGE</button>

                </div>
                </div>
            </div>
            <div className="col-lg-6 ps-md-0 mt-5">
                <img src={require('../../images/Map.png')} style={{width:'100%'}} alt="" />
            </div>
        </div>
      </div>

      <div className="row m-0 container mx-auto py-4 mb-5">
        <div className="col-md-11 mx-auto px-0">
          <div className="row m-0">
          <div className="col-lg-4">
          <div className='contact-cards p-4'>
            <p className='text-center'><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="white" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
</svg></p>
<p className='text-center text-white fw-medium fs-4'>Address</p>
<p className='text-center text-white m-0 contact-subtext'>140 Home Street, Plano</p>
<p className='text-center text-white m-0 contact-subtext'>New York</p>

          </div>
        </div>
        <div className="col-lg-4">
          <div className='contact-cards2 p-4'>
            <p className='text-center'><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="white" class="bi bi-telephone-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg></p>
<p className='text-center text-white fw-medium fs-4'>Phone</p>
<p className='text-center text-white m-0 contact-subtext'>+1 (234) 567 890</p>
<p className='text-center text-white m-0 contact-subtext'>+0 (987) 654 321</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className='contact-cards p-4'>
            <p className='text-center'><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="white" class="bi bi-envelope-fill" viewBox="0 0 16 16">
  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
</svg></p>
<p className='text-center text-white fw-medium fs-4'>Email</p>
<p className='text-center text-white m-0 contact-subtext'>mvbnvbnv@gmail.com</p>
<p className='text-center text-white m-0 contact-subtext'>uiyutyu@gmail.com</p>
          </div>
        </div>
          </div>
        </div>
      </div>

      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
  )
}

export default Contact
