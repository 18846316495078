import React from 'react'

const Layout8 = () => {
  return (
    <div className='d-none d-md-block'>
      <div className='py-5' style={{backgroundColor:'#ECF0FD',minHeight:'140vh'}}>
        <div className="container">
          <p className='fs-1 fw-bold m-0 text-center'>Trusted by Parents</p>
          <p className='fs-1 fw-bold text-center'>& Teachers</p>

          <div className='mt-5 pt-4'>
            <img src={require('../../images/student/Group 1171276068.png')} className='d-block mx-auto' alt="" />

            <div className='mt-4' style={{position:'relative'}}>
              <div className='shuffle-card1 shadow py-4' style={{zIndex:10,position:'absolute',top:0}}>
                <img src={require('../../images/student/”.png')} className='d-block mx-auto' width={100} alt="" />
                <p style={{color:'#303030'}} className='fs-5 text-center mt-4 pt-3'>No other eCommerce platform allows people to start for free and grow their store as their business grows. More importantly, WooCommerce doesn't charge you a portion of your profits as your business grows!</p>
              </div>
              <div className='shuffle-card2 shadow' style={{position:'absolute',top:'20px',zIndex:4,left:'40px'}}>
                
              </div>
              <div className='shuffle-card3 shadow' style={{position:'absolute',top:'40px',zIndex:2,left:'65px'}}>
              </div>
              <img src={require('../../images/student/Group 1171276068.png')} className='d-block mx-auto' style={{top:'360px',position:'absolute',left:'45%'}} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout8
