import React from 'react'

const Work_svg = () => {
  return (
    <div>
      <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="72" height="72" rx="36" fill="currentColor"/>
<path d="M38.2076 33.7907L25.0673 46.9311M26.4854 37.3946L20.0491 35.8742C19.341 35.707 19.0919 34.8278 19.6049 34.313L24.7235 29.1944C25.0673 28.8506 25.5338 28.6536 26.0226 28.6499L31.7936 28.5997M39.0626 21.9961C43.3578 24.9252 47.0731 28.6406 50.0023 32.9358M34.6019 45.5129L36.1223 51.9493C36.2895 52.6574 37.1687 52.9065 37.6835 52.3935L42.8021 47.2749C43.1459 46.9311 43.3429 46.4645 43.3467 45.9757L43.3968 40.2048M51.1045 29.9156L52.6062 22.6893C53.0151 20.7229 51.2754 18.9833 49.309 19.3922L42.0828 20.8939C39.9547 21.3363 38.0032 22.3901 36.468 23.9272L30.539 29.8542C28.2771 32.1161 26.8051 35.049 26.3405 38.2142L26.32 38.3499C26.0264 40.3702 26.7029 42.4091 28.1452 43.8532C29.5874 45.2955 31.6282 45.972 33.6485 45.6765L33.7842 45.6561C36.9494 45.1933 39.8822 43.7194 42.1441 41.4575L48.0712 35.5304C49.6083 33.9952 50.6621 32.0437 51.1045 29.9156Z" stroke="#F9F9F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_537_2142" x1="15.8327" y1="6.1887" x2="-5.1006" y2="101.791" gradientUnits="userSpaceOnUse">
<stop stop-color="#7C00D9"/>
<stop offset="0.565343" stop-color="#4600B1"/>
<stop offset="1" stop-color="#141778"/>
</linearGradient>
</defs>
</svg>

    </div>
  )
}

export default Work_svg
