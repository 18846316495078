import React from 'react'
import Admin_Loginpage from './Loginpage'

const Protect = ({Child}) => {

    const user=JSON.parse(sessionStorage.getItem('user'))

    const verify = ()=>{
        if(user==null || user==undefined){
            return false
        }
        else{
            return true
        }
    }


  return (
    <div>
      {
        verify() ? <Child/> : <Admin_Loginpage/>
      }
    </div>
  )
}

export default Protect
